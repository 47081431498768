import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { submitFeedback } from 'actions/mailActions';
import { addNotification } from 'actions/notificationActions';
import {
  Button,
  FormGroup,
  StepNavBar,
  StepNavItem,
  Wrapper,
} from 'components/common/StyledComponents';
import TextareaField from 'components/common/TextareaField';

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missingStuff: '',
      improvements: '',
      otherStuff: '',
      isLoading: false,
      isInvalid: false,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { addNotification, submitFeedback } = this.props;
    const { missingStuff, improvements, otherStuff } = this.state;

    if (!missingStuff && !improvements && !otherStuff) {
      this.setState({ isInvalid: true });
      return addNotification({
        type: 'error',
        text: 'Mindestens eins der Formularfelder muss ausgefüllt sein',
      });
    }

    this.setState({ isLoading: true });

    submitFeedback({ missingStuff, improvements, otherStuff })
      .then(() => {
        this.props.history.push('/');
      })
      .catch((err) => {
        this.setState({ isLoading: false, isInvalid: true });
      });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (this.state.isInvalid && value.length > 0) {
      this.setState({ isInvalid: false });
    }
  };

  render() {
    const { isLoading, isInvalid, missingStuff, improvements, otherStuff } = this.state;

    return (
      <Wrapper fixed>
        <Helmet>
          <title>Feedback</title>
        </Helmet>
        <StepNavBar>
          <StepNavItem>
            <span>Feedback</span>
          </StepNavItem>
        </StepNavBar>

        <form onSubmit={this.onSubmit}>
          <TextareaField
            label="Welche Funktionen fehlen dir?"
            onChange={this.onChange}
            value={missingStuff}
            name="missingStuff"
            placeholder="Feedback eingeben..."
          />

          <TextareaField
            label="Was muss verbessert werden?"
            onChange={this.onChange}
            value={improvements}
            name="improvements"
            placeholder="Feedback eingeben..."
          />

          <TextareaField
            label="Sonstiges?"
            onChange={this.onChange}
            value={otherStuff}
            name="otherStuff"
            placeholder="Feedback eingeben..."
          />

          <FormGroup>
            <Button wide disabled={isLoading || isInvalid}>
              Senden
            </Button>
          </FormGroup>
        </form>
      </Wrapper>
    );
  }
}

export default connect(null, { submitFeedback, addNotification })(FeedbackForm);
