import cx from 'classnames';
import { omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import vars from 'variables';
import { BreakpointProvider, Column, Row } from '../../external/hedron/src/index';

const {
  colors: { lightGray },
  media,
} = vars;

const TableContainer = styled.div`
  width: 100%;
  border-collapse: collapse;
  margin: 1em auto 2.5em;
  font-size: 0.75rem;

  @media (min-width: ${media.sm}) {
    font-size: 0.8rem;
  }

  &:not(.is-smaller) {
    @media (min-width: ${media.lg}) {
      font-size: 0.85rem;
    }
  }
`;

const TableRow = styled(Row)`
  border-bottom: 1px solid ${lightGray};

  &:last-of-type {
    border-bottom: 0;
  }
`;

const TableColumn = styled((props) => <Column {...omit(props, 'align')} />)`
  border-left: 1px solid ${lightGray};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'center')};
  text-align: ${(props) => (props.align === 'left' ? 'left' : 'center')};
  padding: ${vars.main.columnGutter.xs}px;
  word-break: break-word;

  @media (min-width: ${media.sm}) {
    padding: ${vars.main.columnGutter.sm}px;

    &.condensed {
      padding: ${vars.main.columnGutter.condensed}px;
    }
  }

  &:first-of-type {
    border-left: 0;
  }

  &.is-hidden-xs {
    @media (max-width: ${media.xsMax}) {
      display: none;
    }
  }

  &.is-hidden-sm {
    @media (min-width: ${media.sm}) and (max-width: ${media.smMax}) {
      display: none;
    }
  }

  &.is-hidden-md {
    @media (min-width: ${media.md}) and (max-width: ${media.mdMax}) {
      display: none;
    }
  }

  &.is-hidden-lg {
    @media (min-width: ${media.lg}) and (max-width: ${media.lgMax}) {
      display: none;
    }
  }
`;

const Table = ({ columns, data, sizes, colClassName, rowClassName, className }) => {
  const renderColumn = (text, colSizes, key, align) => {
    const { xs, sm, md, lg } = colSizes;
    const colSizeClassNames = Object.keys(colSizes).map((key) =>
      colSizes[key] === 0 ? `is-hidden-${key}` : null
    );

    return (
      <TableColumn
        fluid
        key={key}
        align={align}
        className={cx(colClassName, colSizeClassNames)}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
      >
        {text}
      </TableColumn>
    );
  };

  const headerKeys = columns.map((c) => c.key);

  const header = (
    <TableRow className={rowClassName}>
      {columns.map((column, i) => renderColumn(column.header, sizes[i], i, columns[i].align))}
    </TableRow>
  );

  const body = data.map((row, k) => (
    <TableRow className={rowClassName} key={k}>
      {headerKeys.map((key, i) => {
        const renderColContent = columns[i].render;

        if (typeof renderColContent === 'function') {
          return renderColumn(renderColContent(row), sizes[i], i, columns[i].align);
        }

        return renderColumn(row[key], sizes[i], i, columns[i].align);
      })}
    </TableRow>
  ));

  // Override default breakpoints of hedron
  const breakpoints = {
    sm: 500,
    md: 768,
    lg: 1200,
  };

  return (
    <BreakpointProvider breakpoints={breakpoints}>
      <TableContainer className={cx('table', className)}>
        {header}
        {body}
      </TableContainer>
    </BreakpointProvider>
  );
};

export default Table;
