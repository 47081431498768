import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import createModal from 'components/Modal/createModal'; 
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import InputField from 'components/common/InputField';
import { addNewFilter } from 'actions/filterActions';
import validateInput from 'modules/validations/addFilter';

class AddProject extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      projectTitle: '',
      projectNumber: '',
      isLoading: false,
      isInvalid: false,
      errors: {},
      warnings: {}
    }
  }
  
  isValid(data = this.state) {
    const { errors, isValid } = validateInput(data);
    if (this.state.errors !== errors) {
      this.setState({ errors });
    }
    return isValid;
  }
  
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === 'projectNumber') this.checkProjectNumber(value)
  }
  
  checkProjectNumber = debounce(num => {
    const isUsed = this.props.options.some(i => num === i.name.split(' - ')[0])
    const projectNumber = isUsed ? 'Die eingegebene Projektnummer wurde schon einem anderen Projekt zugewiesen' : ''
    this.setState({
      warnings: { ...this.state.warnings, projectNumber }
    })
  }, 500)
  
  onSubmit = e => {
    e.preventDefault();
    const {parentId, addNewFilter, onSuccess} = this.props;
    const {projectTitle, projectNumber} = this.state 
        
    if (this.isValid()) {
      const data = { projectTitle, projectNumber, parentId }
      this.setState({ isLoading: true });
      
      addNewFilter(data)
      .then(() => {        
        this.setState({
          isLoading: false,
          projectTitle: '',
          projectNumber: '',
        });
        if (onSuccess) onSuccess();
      })
      .catch(error => {
        if (error.response) {
          this.setState({ errors: error.response.data, isLoading: false });
        } else {
          console.log(error);
        }
      });
    }
  }
  
  render() {
    const { projectNumber, projectTitle, isLoading, isInvalid, errors, warnings } = this.state;
    
    return (
      <form onSubmit={this.onSubmit}>
        <ContentWrapper>
          <InputField
            gray
            autoFocus
            warning={warnings.projectNumber}
            error={errors.projectNumber}
            onChange={this.onChange}
            value={projectNumber}
            name="projectNumber"
            type='number'
            placeholder="neue Projektnummer eingeben"
          />
          <InputField
            gray
            error={errors.projectTitle || errors.filterName}
            onChange={this.onChange}
            value={projectTitle}
            name="projectTitle"
            placeholder="neuer Projekttitel eingeben"
          />
        </ContentWrapper>
        <Button wide disabled={isLoading || isInvalid}>Hinzufügen</Button>
      </form>
    );
  }
}

AddProject.propTypes = {
  parentId: PropTypes.number.isRequired,
  addNewFilter: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  onSuccess: PropTypes.func
}

const AddProjectModal = createModal(AddProject)

export default connect(null, {addNewFilter})(AddProjectModal);
