import React from 'react';
import createSVG from '../createSVG';

const Logo = ({style}) => (
  <g style={style} fill="none" fillRule="evenodd">
    <path fill="#C8C8C8" d="M.5 50.621h50.631V.5H.5"/>
    <path fill="#6ED2A0" d="M.5 110.5h50.631V60.379H.5"/>
    <path fill="#C8C8C8" d="M59.869 50.621H110.5V.5H59.869m0 110H110.5V60.379H59.869"/>
  </g>
);

export default createSVG(Logo);
