import { combineReducers } from 'redux';

import { USER_LOGOUT } from 'actions/authActions';
import { loadingBarReducer as loadingBar } from 'components/LoadingBar'
import notifications from './notifications';
import auth, * as fromAuth from './auth';
import sidebar from './sidebar';
import filters from './filters';
import searchTerm from './search';
import library from './library';
import form from './form';
import preferences from './preferences';
import team from './team';

const appReducer = combineReducers({
  notifications,
  auth,
  sidebar,
  filters,
  searchTerm,
  library,
  form,
  loadingBar,
  preferences,
  team
});

const rootReducer = (state, action) => {
  // Reset state on logout
  if (action.type === USER_LOGOUT) {
    // Keep preferences
    const { preferences } = state
    state = { preferences }
  }

  return appReducer(state, action)
}

// Auth state selectors
export const getAccessToken = state => fromAuth.getAccessToken(state.auth)
export const getRefreshToken = state => fromAuth.getRefreshToken(state.auth)
export const isAccessTokenExpired = state => fromAuth.isAccessTokenExpired(state.auth)
export const isRefreshTokenExpired = state => fromAuth.isRefreshTokenExpired(state.auth)

export default rootReducer;