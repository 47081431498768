import { TOGGLE_SIDEBAR, OPEN_PREVIOUS_SIDEBAR } from 'actions/sidebarActions';
import { LOCATION_CHANGE } from 'actions';

const initialState = {
  active: null,
  previous: 'primary'
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (state.active === 'library') return state
      return {
        ...state,
        active: initialState.active,
        previous: initialState.previous
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        active: action.active,
        previous: state.active !== 'library' && state.active !== null ? state.active : state.previous
      }
    case OPEN_PREVIOUS_SIDEBAR:
      return {
        ...state,
        active: state.previous,
        previous: state.active !== 'library' && state.active !== null ? state.active : state.previous
      }
      
    default: return state;
  }
}
