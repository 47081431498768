import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import { resetFiltersState } from 'actions/filterActions';
import { deselectLibraryItems } from 'actions/libraryActions';
import { setSearchTerm } from 'actions/searchActions';
import { toggleSidebar } from 'actions/sidebarActions';
import { CloseIcon, HamburgerIcon, LogoIcon, SearchIcon } from 'components/icons/';
import { Brand, Nav, NavItem, NavItems } from './navbarStyles';

const Navbar = ({
  toggleSidebar,
  deselectLibraryItems,
  resetFiltersState,
  setSearchTerm,
  sidebar,
  library,
  libraryIsEmpty,
  auth,
  location,
  history,
  searchTerm,
}) => {
  const handleIconClick = (id) => {
    if (sidebar.active === id) {
      toggleSidebar();
    } else {
      toggleSidebar(id);
    }
  };

  const handleCloseClick = (goBack = true) => {
    // Clear global search term
    searchTerm && setSearchTerm('');

    // Library Grid view
    if (location.pathname === '/') {
      // Deselect the library items
      library.selected.length > 0 && deselectLibraryItems();
      // Close the active sidebar if library is empty
      libraryIsEmpty && toggleSidebar();
      // Reset selected filters and open accordions
      resetFiltersState();
    } else {
      goBack && history.goBack();
    }
  };

  const handleBrandClick = () => {
    if (auth.isAuthenticated) {
      handleCloseClick(false);
    } else {
      scroll.scrollToTop({
        smooth: true,
        duration: 500,
      });
    }
  };

  const renderNavbarLinks = () => {
    if (
      sidebar.active === 'library' ||
      location.pathname !== '/' ||
      (sidebar.active && libraryIsEmpty)
    ) {
      return (
        <NavItems>
          <NavItem onClick={handleCloseClick}>
            <CloseIcon stroke="#fff" height={20} width={20} viewBox="0 0 16 16" />
          </NavItem>
        </NavItems>
      );
    }

    if (!auth.isAuthenticated) {
      return null;

      // return (
      //   <NavItems>
      //     <NavItem data-rh="Menü" data-rh-at="bottom" onClick={() => handleIconClick('guest')}>
      //       <HamburgerIcon />
      //     </NavItem>
      //   </NavItems>
      // );
    }

    return (
      <NavItems>
        {sidebar.active !== 'primary' && (
          <NavItem data-rh="Menü" data-rh-at="bottom" onClick={() => handleIconClick('primary')}>
            <HamburgerIcon />
          </NavItem>
        )}
        {!libraryIsEmpty && sidebar.active !== 'filters' && (
          <NavItem data-rh="Suche" data-rh-at="bottom" onClick={() => handleIconClick('filters')}>
            <SearchIcon />
          </NavItem>
        )}
      </NavItems>
    );
  };

  return (
    <Nav>
      <Brand data-rh="Start" data-rh-at="bottom">
        <Link onClick={handleBrandClick} to="/">
          <span>archicloud</span>
          <LogoIcon viewBox="0 0 16 16" />
        </Link>
      </Brand>
      {renderNavbarLinks()}
    </Nav>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sidebar: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  deselectLibraryItems: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  resetFiltersState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, sidebar, library, searchTerm }) => ({
  auth,
  sidebar,
  library,
  searchTerm,
  libraryIsEmpty: library.items.length === 0,
});

export default connect(mapStateToProps, {
  toggleSidebar,
  deselectLibraryItems,
  resetFiltersState,
  setSearchTerm,
})(Navbar);
