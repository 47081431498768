import React from 'react';
import ReactHintFactory from 'react-hint';
import './index.css';

const ReactHint = ReactHintFactory(React);

const TooltipRoot = () => {
  return <ReactHint delay={200} events={{ hover: true }} />;
};

export default TooltipRoot;
