import { SearchButton, SearchInput, SearchWrapper } from './searchStyles';

const SearchField = ({
  type = 'search',
  placeholder = 'Search',
  wrapperClassName,
  onChange,
  value,
  innerRef,
  ...inputProps
}) => {
  const clearSearchTerm = () => {
    const e = {
      target: {
        value: '',
      },
    };
    onChange && onChange(e);
  };

  return (
    <SearchWrapper className={wrapperClassName}>
      <SearchInput
        {...inputProps}
        ref={innerRef}
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeholder}
      />
      <SearchButton
        onClick={clearSearchTerm}
        className={value !== '' ? 'has-search-term' : undefined}
      />
    </SearchWrapper>
  );
};

export default SearchField;
