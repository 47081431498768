import { get, isString } from 'lodash';

export const getErrorMessage = (err, cb) => {
  const message = get(err, 'response.data.message')
  if (message && isString(message)) {
    return cb ? cb(message) : message
  }
  
  return null
}

export const getErrorObject = (err, cb) => {
  const errors = get(err, 'response.data.errors')
  if (errors) {
    return cb(errors)
  }
  
  return null
}