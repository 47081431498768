import styled from 'styled-components';
import vars from 'variables';

export const DropzoneInner = styled.div`
  width: 200px;
  height: 200px;
  border-width: 1px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 4px;
  padding: 10px;
  margin: 0 auto 1.5em;
  position: relative;
  cursor: pointer;

  &[aria-disabled] {
    cursor: pointer;
  }

  &.active {
    background: #fafafa;
    border-style: solid;
  }
`;

export const DropzonePreview = styled.div`
  padding: ${vars.main.gutter}px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;
  word-wrap: break-word;
`;

export const DropzoneWrapper = styled.div`
  p {
    text-align: center;
  }
`;
