import styled from 'styled-components';
import Background from './img/feature.jpg';
import vars from 'variables';
import { Col } from 'components/common/Grid'

const {sm, md, lg} = vars.media

export const Feature = styled.section`
  background-color: #fff;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - ${vars.main.headerHeight}px);
  min-height: 100%;
  margin-bottom: 0;
  background-position: top center;
  position: relative;
  
  @media (min-width: ${sm}) {
    background-position: center; 
  }
`;

export const FeatureHeader = styled.div`
  margin-bottom: 2.5em;
  text-align: center;
  @media (min-width: ${sm}) {
    text-align: left;
  }
  h1 {
    color: ${vars.colors.green};
    display: block;
    font-weight: 200;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 1rem;
  }
`;

export const FeatureContent = styled.div`
  position: absolute;
  top: 15%;
  left: 10%;
  width: 80%;
  @media (min-width: ${sm}) {
    width: 60%;
  }
  @media (min-width: ${md}) {
    width: 45%;
  }
  @media (min-width: ${lg}) {
    width: 35%;
  }
`;

export const Box = styled(Col)`
  text-align: center;
  padding: 0 15px;
  font-size: .8rem;
  @media (min-width: ${md}) {
    font-size: .9rem;
    text-align: left;
  }
  @media (min-width: ${lg}) {
    font-size: 1rem;
  }
  &.no-padding {
    padding: 0;
  }
  &.xs-mb {
    margin-bottom: 70px;
    @media (min-width: ${sm}) {
      margin-bottom: 0;
    }
  }
  &.sm-mb {
    margin-bottom: 70px;
    @media (min-width: ${md}) {
      margin-bottom: 0;
    }
  }
  &.mb-small {
    margin-bottom: 20px;
    @media (min-width: ${md}) {
      margin-bottom: 0;
    }
  }
  svg {
    margin: auto;
    display: block;
  }
  .title {
    margin-top: 15px;
    text-align: center;
  }
  &.text-left {
    text-align: left;
  }
  h1 {
    font-size: 1.5rem;
    margin-top: 0;
    @media (min-width: ${sm}) {
      font-size: 1.75rem;
    }
    @media (min-width: ${lg}) {
      font-size: 2rem;
    }
  }
  &.register-box {
    background: rgba(255,255,255,.9);
    padding: 10px;
    align-self: flex-start;
    margin-top: 20px;
    @media (min-width: ${md}) {
      align-self: center;
      margin-top: 0;
    }
  }
`

export const BackgroundImage = styled.div`
  display: flex;
  width: 100%;
  min-height: 70vh;
  position: relative;
  @media (min-width: ${md}) {
    min-height: 80vh;
  }
  @media (min-width: ${lg}) and (min-height: 1080px) {
    min-height: 60vh;
  }
`


export const DatenschutzContainer = styled.div`
  border: 4px solid ${vars.colors.green};
  width: 100%;
  max-width: 200px;
  
  ul {
    background: #fff;
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: .7rem;
    margin: 0 5px;
    @media (min-width: ${sm}) {
      font-size: .8rem;
      margin: 0 10px;
    }
    li {
      padding: 10px 0;
      border-bottom: 1px solid ${vars.colors.lightGray};
      @media (min-width: ${sm}) {
        padding: 17px 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`

export const DatenschutzIconWrapper = styled.div`
  background: ${vars.colors.green};
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
    width: 60%;
    max-height: 100px;
    max-width: 100px;
  }
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const Credit = styled.div`
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-size: .75rem;
  a {
    color: ${vars.colors.gray};
    text-decoration: none;
  }
`

export const ComparisonBox = styled.div`
  font-size: .65rem;
  @media (min-width: ${md}) {
    font-size: .75rem;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
    height: 5em;
    border-bottom: 1px solid ${vars.colors.lightGray};
    &:last-child {
      border-bottom: 0;
    }
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  &.not-centered {
    padding-bottom: 2.5px;
    @media (min-width: ${lg}) {
      padding-bottom: 5px;
    }
    span {
      @media (min-width: ${md}) {
        text-align: left;
        left: auto;
        transform: translateY(-50%);
      }
    }
  }
  &.gray {
    border: 2.5px solid ${vars.colors.lightGray};
    margin-right: 1.5px;
    @media (min-width: ${lg}) {
      margin-right: 3.5px;
    }
  }
  &.green {
    border: 2.5px solid ${vars.colors.green};
    margin-left: 1.5px;
    @media (min-width: ${lg}) {
      margin-left: 3.5px;
    }
  }
  &.gray, &.green {
    @media (min-width: ${lg}) {
      border-width: 5px;
    }
  }
`

export const PriceBox = styled.div`
  color: #fff;
  position: relative;
  .price-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 5px;
    .price-tier {
      font-size: .75rem;
      @media (min-width: ${sm}) {
        font-size: .9rem;
      }
      @media (min-width: ${lg}) {
        font-size: 1rem;
      }
    }
    .price-amount {
      display: inline-block;
      font-size: 2rem;
      font-weight: 200;
      line-height: 1;
      margin: 2.5px 0;
      position: relative;
      @media (min-width: ${sm}) {
        font-size: 4rem;
      }
      @media (min-width: ${md}) {
        font-size: 4rem;
        font-weight: 100;
      }
      @media (min-width: ${lg}) {
        font-size: 8rem;
        font-weight: 100;
        letter-spacing: -10px;
        margin-right: 10px;
      }
      &:before {
        content: '€';
        position: absolute;
        top: 1.5%;
        left: -9px;
        font-size: .7rem;
        font-weight: 400;
        @media (min-width: ${sm}) {
          left: -12px;
          top: 5%;
          font-size: 1.25rem;
        }
        @media (min-width: ${lg}) {
          top: 6.5%;
          left: -16px;
          font-size: 2rem;
        }
      }
    }
    .price-subtitle {
      font-size: .6rem;
      @media (min-width: ${sm}) {
        font-size: .75rem;
      }
      @media (min-width: ${lg}) {
        font-size: .75rem;
      }
    }
  }
  .br {
    display: block;
    @media (min-width: ${lg}) {
      display: none;
    }
  }
  &.green {
    background-color: ${vars.colors.green};
    margin-left: 1.5px;
    @media (min-width: ${lg}) {
      margin-left: 3.5px;
    }
  }
  &.gray {
    background-color: ${vars.colors.lightGray};
    margin-right: 1.5px;
    @media (min-width: ${lg}) {
      margin-right: 3.5px;
    }
  }
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const ScrollButton = styled.button`
  height: 22px;
  width: 22px;
  display: block;
  position: absolute;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: ${vars.colors.green};
  text-align: center;
  z-index: 6;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0;
  
  &.upside-down svg {
    transform: rotateX(180deg);
  }
`