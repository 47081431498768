import cx from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { scroller } from 'react-scroll';

import SliderControls from './SliderControls';
import {
  ImageSlide,
  SlideTitle,
  SliderArrow,
  SliderContainer,
  SliderFooter,
  SliderInner,
  SliderNavButton,
  SliderWrapper,
  SlidesList,
} from './sliderStyles';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragStart: 0,
      dragStartTime: new Date(),
      index: 0,
      lastIndex: 0,
      transition: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { selected } = this.props;

    this.setState({
      index: selected,
      lastIndex: selected,
    });
  }

  UNSAFE_componentWillReceiveProps({ selected }) {
    if (selected !== this.props.selected) {
      this.goToSlide(selected);
    }
  }

  getDragX = (e, isTouch) => (isTouch ? e.touches[0].pageX : e.pageX);

  handleDragStart(event, isTouch) {
    const x = this.getDragX(event, isTouch);

    this.setState({
      dragStart: x,
      dragStartTime: new Date(),
      transition: false,
      slideWidth: ReactDOM.findDOMNode(this.refs.slider).offsetWidth,
    });
  }

  handleDragMove(e, isTouch) {
    const { dragStart, lastIndex, slideWidth } = this.state;
    const x = this.getDragX(e, isTouch);
    const offset = dragStart - x;
    const percentageOffset = offset / slideWidth;
    const newIndex = lastIndex + percentageOffset;
    const SCROLL_OFFSET_TO_STOP_SCROLL = 30;

    // Stop scrolling if you slide more than 30 pixels
    if (Math.abs(offset) > SCROLL_OFFSET_TO_STOP_SCROLL) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState({ index: newIndex });
  }

  handleDragEnd() {
    const { items } = this.props;
    const { dragStartTime, index, lastIndex } = this.state;

    const timeElapsed = new Date().getTime() - dragStartTime.getTime();
    const offset = lastIndex - index;
    const velocity = Math.round((offset / timeElapsed) * 10000);

    let newIndex = Math.round(index);

    if (Math.abs(velocity) > 5) {
      newIndex = velocity < 0 ? lastIndex + 1 : lastIndex - 1;
    }

    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    this.setState({
      dragStart: 0,
      index: newIndex,
      lastIndex: newIndex,
      transition: true,
    });
  }

  goToSlide(slideIndex, e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { items, loop, isImageSlider } = this.props;

    // Scroll to the feature slider only on desktop if necessary
    if (!isImageSlider && window.innerWidth > 767) {
      scroller.scrollTo('FeatureSlider', {
        smooth: true,
        duration: 500,
      });
    }

    let index = slideIndex;

    if (index < 0) {
      index = loop ? items.length - 1 : 0;
    } else if (index >= items.length) {
      index = loop ? 0 : items.length - 1;
    }

    this.setState({
      index: index,
      lastIndex: index,
      transition: true,
    });
  }

  renderNav() {
    const { items, showNavAsText } = this.props;
    const { lastIndex } = this.state;

    const navButtons = items.map((slide, i) => (
      <SliderNavButton
        className={i === lastIndex ? 'slider-navButton--active' : null}
        key={i}
        onClick={(e) => this.goToSlide(i, e)}
      />
    ));

    const navStatus = `${lastIndex + 1} / ${items.length}`;

    return (
      <SliderFooter mt={showNavAsText ? '10px' : '60px'}>
        {showNavAsText ? navStatus : navButtons}
      </SliderFooter>
    );
  }

  renderArrows() {
    const { items, loop, isImageSlider } = this.props;
    const { lastIndex } = this.state;

    return (
      <div className="slider-arrows">
        {loop || lastIndex > 0 ? (
          <SliderArrow
            className={cx('slider-arrow--left', { 'arrow-bottom-xs': !isImageSlider })}
            onClick={(event) => this.goToSlide(lastIndex - 1, event)}
          />
        ) : null}
        {loop || lastIndex < items.length - 1 ? (
          <SliderArrow
            className={cx('slider-arrow--right', { 'arrow-bottom-xs': !isImageSlider })}
            onClick={(event) => this.goToSlide(lastIndex + 1, event)}
          />
        ) : null}
      </div>
    );
  }

  renderSlides() {
    return this.props.items.map((item, i) => {
      const thumbnail = item.thumbnailUrl ? { backgroundImage: `url(${item.thumbnailUrl})` } : null;

      return (
        <ImageSlide key={i} style={thumbnail} className={thumbnail ? 'has-thumbnail' : null}>
          <SliderControls item={item} />
        </ImageSlide>
      );
    });
  }

  render() {
    const { items, showArrows, showNav, showTitle, isImageSlider } = this.props;
    const { index, lastIndex } = this.state;

    const slidesStyles = {
      width: `${100 * items.length}%`,
      transform: `translateX(${-1 * index * (100 / items.length)}%)`,
    };

    const margin = isImageSlider ? '3em' : '0';

    return (
      <SliderWrapper
        className={cx({ 'is-image-slider': isImageSlider })}
        mt={margin}
        mb={margin}
        ref="slider"
      >
        {showTitle && <SlideTitle>{items[lastIndex].name}</SlideTitle>}
        <SliderContainer maxWidth={isImageSlider ? 'calc(100vh - 150px)' : '100vw'}>
          {showArrows && this.renderArrows()}
          <SliderInner
            className={isImageSlider ? 'is-image-slider' : null}
            onTouchStart={(e) => this.handleDragStart(e, true)}
            onTouchMove={(e) => this.handleDragMove(e, true)}
            onTouchEnd={() => this.handleDragEnd(true)}
          >
            <SlidesList style={slidesStyles}>
              {isImageSlider ? this.renderSlides() : items}
            </SlidesList>
          </SliderInner>
        </SliderContainer>
        {showNav && this.renderNav()}
      </SliderWrapper>
    );
  }
}

Slider.propTypes = {
  items: PropTypes.array,
  loop: PropTypes.bool,
  selected: PropTypes.number,
  showArrows: PropTypes.bool,
  showNav: PropTypes.bool,
  showNavAsText: PropTypes.bool,
  showTitle: PropTypes.bool,
  isImageSlider: PropTypes.bool,
};

Slider.defaultProps = {
  loop: false,
  selected: 0,
  showArrows: true,
  showNav: true,
  showNavAsText: true,
  showTitle: true,
};

export default Slider;
