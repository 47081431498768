import React from 'react';
import createSVG from './createSVG';

const PreviewIcon = ({style}) => (
  <g style={style} fill="none" fillRule="evenodd" stroke="#7D7D7D" strokeWidth="1.5" strokeLinecap="round">
    <path d="M16.27 18.12c-4.58 0-8.292-3.734-8.292-8.34 0-4.608 3.713-8.343 8.292-8.343 4.58 0 8.292 3.735 8.292 8.342 0 4.605-3.712 8.34-8.292 8.34zm-5.905-2.485l-9.302 9.302 9.302-9.302z"/>
    <path d="M20.563 9.683h-8.5m4.25-4.246v8.5" strokeLinejoin="round"/>
  </g>
);

export default createSVG(PreviewIcon);
