const billingIntervals = [
  { name: 'jährlich', value: 'yearly' },
  { name: 'monatlich', value: 'monthly' },
];

const expiryTimeOptions = [
  {
    name: '30 Minuten',
    value: 30,
  },
  {
    name: '1 Stunde',
    value: 60,
  },
  {
    name: '2 Stunden',
    value: 120,
  },
  {
    name: '4 Stunden',
    value: 240,
  },
  {
    name: '8 Stunden',
    value: 480,
  },
  {
    name: '12 Stunden',
    value: 720,
  },
  {
    name: '24 Stunden',
    value: 1440,
  },
];

const accountTypeOptions = [
  {
    name: 'Administrator',
    value: 'admin',
  },
  {
    name: 'Standard',
    value: 'standard',
  },
  {
    name: 'Einfach',
    value: 'simple',
  },
];

const environment = process.env.NODE_ENV || 'development';
const isProduction = environment === 'production';

const GOOGLE_API_KEY = isProduction
  ? 'AIzaSyB01pOAz9w_u8qx7O-gp29FJaoUvEsNs-k' // prod key, HTTP restrictions only
  : process.env.REACT_APP_GOOGLE_API_KEY; // dev key, no restrictions

export { GOOGLE_API_KEY, accountTypeOptions, billingIntervals, environment, expiryTimeOptions };
