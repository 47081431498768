import classnames from 'classnames';
import { Tile } from 'components/common/StyledComponents';
import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

const TileOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
  }

  .lazy-load-image-background {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    &.lazy-load-image-loaded {
      opacity: 1;
    }
  }
`;

class LibraryItem extends React.Component {
  constructor() {
    super();

    this.state = {
      hasLoaded: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.selected !== nextProps.selected ||
      this.props.scrollPosition !== nextProps.scrollPosition
    ) {
      return true;
    }
    return false;
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { id, onClick } = this.props;

    if (onClick instanceof Function) {
      onClick(id, e);
    }
  };

  render() {
    const { id, className, name, thumbnailUrl, selected, scrollPosition, xs, sm, md, lg } =
      this.props;

    return (
      <Tile
        data-id={id}
        onTouchEnd={this.handleClick}
        onClick={this.handleClick}
        className={classnames(
          className,
          'tile',
          { 'has-thumbnail': thumbnailUrl },
          { 'is-selected': selected }
        )}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
      >
        <TileOverlay>
          {thumbnailUrl && (
            <LazyLoadImage
              width="100%"
              height="100%"
              src={thumbnailUrl}
              alt={name}
              effect="opacity"
              scrollPosition={scrollPosition}
            />
          )}
        </TileOverlay>
        <div className="tile-content">
          <div className="tile-title">{name}</div>
        </div>
        <TileOverlay className="tile-overlay" />
      </Tile>
    );
  }
}

LibraryItem.propTypes = {
  selected: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  xs: PropTypes.number.isRequired,
  sm: PropTypes.number.isRequired,
  md: PropTypes.number.isRequired,
  lg: PropTypes.number.isRequired,
};

LibraryItem.defaultProps = {
  selected: false,
  xs: 60,
  sm: 20,
  md: 15,
  lg: 12,
};

export default LibraryItem;
