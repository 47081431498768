import { isEmpty } from 'lodash';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import MultiStep from 'components/MultiStep/';
import { Wrapper } from 'components/common/StyledComponents';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

import { resetFiltersState, restoreFiltersState } from 'actions/filterActions';
import { addPreference, deletePreference } from 'actions/preferenceActions';

export const FORM_KEY = 'import';

const steps = [
  {
    name: 'Filter wählen',
    moreInfo: '/faq/6:e',
    component: <StepOne />,
  },
  {
    name: 'Datei wählen',
    moreInfo: '/faq/6:e',
    component: <StepTwo />,
  },
  {
    name: 'Vorschau wählen',
    moreInfo: '/faq/6:e',
    component: <StepThree />,
  },
];

class ImportView extends Component {
  UNSAFE_componentWillMount() {
    const {
      preferences: { importFilterState },
      restoreFiltersState,
      deletePreference,
      resetFiltersState,
    } = this.props;

    // Check if there're filters from and unfinished import in state
    if (!isEmpty(importFilterState)) {
      // Restore the filters state and clean the preference
      restoreFiltersState(importFilterState);
      deletePreference('importFilterState');
    } else {
      // Clean the filters state as it may contains selected filters from the front page
      resetFiltersState();
    }
  }

  componentWillUnmount() {
    const { selected, primary, secondary } = this.props.filters;

    // If the user hasn't submitted the import form, save the filters as a preference
    // That way the import state won't interfere with the rest of the app
    if (primary) {
      this.props.addPreference('importFilterState', {
        selected,
        primary,
        secondary,
      });
    }

    // Reset selected filters
    this.props.resetFiltersState();
  }

  render() {
    return (
      <Wrapper fixed>
        <Helmet>
          <title>Import</title>
        </Helmet>
        <MultiStep
          navClassName="step-nav-wide"
          formId={FORM_KEY}
          showPrevButton={false}
          nextButton="Weiter"
          steps={steps}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ preferences, filters }) => ({
  preferences,
  filters,
});

export default connect(mapStateToProps, {
  addPreference,
  deletePreference,
  restoreFiltersState,
  resetFiltersState,
})(ImportView);
