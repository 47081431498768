export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR/sidebar';
export const OPEN_PREVIOUS_SIDEBAR = 'OPEN_PREVIOUS_SIDEBAR/sidebar';

export const toggleSidebar = (active = null) => ({
  type: TOGGLE_SIDEBAR,
  active
})

export const openPreviousSidebar = () => ({
  type: OPEN_PREVIOUS_SIDEBAR,
})