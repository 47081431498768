import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { ButtonLink, StepNavBar, StepNavItem, Wrapper } from 'components/common/StyledComponents';
import SpaceUsageBar from './SpaceUsageBar';

const SpacePage = ({ hasProAccount }) => (
  <Wrapper fixed>
    <Helmet>
      <title>Speicher</title>
    </Helmet>
    <StepNavBar>
      <StepNavItem>
        <span>Speicher</span>
      </StepNavItem>
    </StepNavBar>

    <SpaceUsageBar />

    <ButtonLink to={hasProAccount ? '/subscription' : '/upgrade'}>
      Speicher jetzt erhöhen
    </ButtonLink>
  </Wrapper>
);

const mapStateToProps = (state) => ({
  hasProAccount: state.auth.user.hasProAccount,
});

export default connect(mapStateToProps)(SpacePage);
