import { UPDATE_FORM_DATA, UPDATE_FORM_STATUS, RESET_FORM_STATE } from 'actions/formActions';

const initialState = {}

const toggleStep = (state = [], action) => {
  const { stepId, status } = action;
  
  if (isNaN(stepId)) return state;

  if (status) return [...state, stepId]
  
  return state.filter(i => i !== stepId)
}

const form = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FORM_DATA:    
      return {
        ...state,
        data: { ...state.data, ...action.data }
      }
    case UPDATE_FORM_STATUS:
      return {
        ...state,
        validSteps: toggleStep(state.validSteps, action)
      }
    default: return state;      
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_FORM_DATA:
    case UPDATE_FORM_STATUS: {
      const { formId } = action;
      return {
        ...state,
        [formId]: form(state[formId], action)
      }
    }
    case RESET_FORM_STATE: {
      if (action.formId) {
        const { [action.formId]: formId, ...rest } = state;
        return rest
      }
      
      return initialState
    }
      
    default: return state;
  }
}
