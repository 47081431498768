import styled from 'styled-components';
import vars from 'variables';

const { gray, lightestGray, lightGray } = vars.colors;

const SearchWrapper = styled.div`
  margin-bottom: 8px;
  position: relative;

  &.sidebar-search {
    margin: ${vars.main.gutter}px 0 ${vars.main.gutter * 1.5}px;

    div:not(.has-search-term) {
      display: none;
    }
  }

  svg {
    cursor: pointer;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 15px;
  height: 100%;
  margin: auto;
  background-color: transparent;
  background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2026%2026%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cpath%20d%3D%22M16.2%2017.8c-4.57%200-8.28-3.73-8.28-8.33s3.7-8.35%208.3-8.35c4.57%200%208.28%203.74%208.28%208.35%200%204.6-3.7%208.34-8.3%208.34zm-5.9-2.48L1%2024.62l9.3-9.3z%22%20stroke%3D%22%237d7d7d%22%20stroke-width%3D%221.5%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22/%3E%0A%3C/svg%3E);
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: center;
  cursor: default;

  &.has-search-term,
  &.togglable {
    background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cpath%20d%3D%22M12.64%203.1L3%2012.72M3%203.1l9.64%209.63%22%20stroke%3D%22%23979797%22%20fill%3D%22%23D8D8D8%22%20fill-rule%3D%22evenodd%22/%3E%0A%3C/svg%3E);
    cursor: pointer;
  }
`;

const SearchInput = styled.input`
  outline: none;
  background-color: ${props => (props.gray ? lightestGray : '#fff')};
  border: 1px solid ${lightGray};
  width: 100%;
  padding: 3px 1.5rem 3px 0.75rem;
  font-size: 0.85rem;
  font-weight: 200;
  line-height: 1.3;
  position: relative;
  color: ${gray};

  &[aria-controls] {
    cursor: text;
  }

  /* Hide the close icon in IE/Edge */
  &::-ms-clear {
    display: none;
  }

  &.is-gray {
    background-color: ${lightestGray};
  }

  &.is-bigger {
    padding: 5px 1.5rem 5px 0.75rem;
  }
`;

export { SearchWrapper, SearchInput, SearchButton };
