import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import autoRotateImage from 'utils/autoRotate';
import pdfToImage from 'utils/pdfToImage';

import { addNotification } from 'actions/notificationActions';
import Spinner from 'components/common/Spinner';
import { General, Import } from 'translations/de';
import { DropzoneInner, DropzonePreview, DropzoneWrapper } from './importStyles';

class DropFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    const { onChange, addNotification, maxSize } = this.props;

    if (rejectedFiles.length > 0) {
      addNotification({
        modal: true,
        title: General.somethingIsWrong,
        text: rejectedFiles[0]?.file?.size > maxSize ? Import.fileIsTooLarge : Import.fileIsInvalid,
        dismissButton: 'Ok',
      });
    }

    if (acceptedFiles.length > 0 && onChange) {
      this.setState({ isLoading: true });
      let file = acceptedFiles[0];

      file.preview = URL.createObjectURL(file);

      // Auto-Rotate the image based on EXIF data if needed
      if (file.type.match(/^image\/jpeg/)) {
        try {
          const canvas = await autoRotateImage(file);

          if (canvas) {
            file.preview = canvas.toDataURL('image/jpeg');
          }
        } catch (err) {
          /* ignore */
        }
      } else if (file.type.match(/pdf/)) {
        // Set the first page of the pdf as preview
        file.preview = await pdfToImage(file);
      }
      this.setState({ isLoading: false });
      // Notify that valid data has been submitted
      onChange(file);
    }
  };

  renderPreview = () => {
    const { file } = this.props;

    if (this.state.isLoading) {
      return (
        <DropzonePreview>
          <Spinner centered />
        </DropzonePreview>
      );
    }

    if (isEmpty(file) || (!file.name && !file.preview))
      return <DropzonePreview>{Import.fileIsNotSelected}</DropzonePreview>;

    const { preview, name, type } = file;
    const hasImagePreview = preview && (type.match(/^image/) || type.match(/pdf/));

    return (
      <DropzonePreview style={hasImagePreview && { backgroundImage: `url(${preview})` }}>
        {!hasImagePreview && name}
      </DropzonePreview>
    );
  };

  render() {
    const { accept, maxSize } = this.props;

    return (
      <DropzoneWrapper>
        <p>Datei auswählen:</p>
        <Dropzone multiple={false} accept={accept} maxSize={maxSize} onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <DropzoneInner
              {...getRootProps()}
              className={classNames('dropzone', { active: isDragActive })}
            >
              <input {...getInputProps()} />
              {this.renderPreview()}
            </DropzoneInner>
          )}
        </Dropzone>
      </DropzoneWrapper>
    );
  }
}

DropFile.propTypes = {
  file: PropTypes.object,
  accept: PropTypes.object,
  onChange: PropTypes.func,
  maxSize: PropTypes.number,
  addNotification: PropTypes.func.isRequired,
};

DropFile.defaultProps = {
  maxSize: 524288000, // default: 500 MB
};

export default connect(null, { addNotification })(DropFile);
