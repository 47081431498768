import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import createModal from 'components/Modal/createModal';
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import SelectList from 'components/Filter/SelectList';
import { toggle } from 'utils/common';
import { deleteTeamUsers } from 'actions/teamActions';
import { getErrorMessage } from 'modules/errorHandler';
import { addNotification } from 'actions/notificationActions';

class DeleteUser extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: false,
      isInvalid: true,
      selected: []
    }
  }
  
  onChange = id => {
    const selected = toggle(this.state.selected, id)
    
    this.setState({
      selected,
      isInvalid: isEmpty(selected)
    });
  }
  
  onClick = e => {
    e.preventDefault();
    const { selected } = this.state;
    const { deleteTeamUsers, addNotification, onSuccess } = this.props;
    
    if (!isEmpty(selected)) {
      this.setState({
        isLoading: true,
        isInvalid: false
      });
    
      deleteTeamUsers({ ids: selected })
      .then(res => {
        const { message } = res.data
        if (message) {
          addNotification({ type: 'success', text: message })
        }
        
        onSuccess()
      })
      .catch(err => {
        getErrorMessage(err, text => addNotification({ type: 'error', text }))

        this.setState({ isLoading: false, isInvalid: true });
      });
    }
  }
  
  render() {
    const { isLoading, isInvalid, selected } = this.state;
    const { users } = this.props;
    
    const options = users.map(u => ({
      id: u.id,
      name: u.email
    }))
    
    return (
      <div>
        <ContentWrapper>
          <SelectList
            searchable={true}
            placeholder='Nutzer suchen'
            onChange={this.onChange}
            selected={selected}
            useLocalState={true}
            itemClassName='alt'
            options={options}
          />
        </ContentWrapper>
        <Button onClick={this.onClick} wide disabled={isLoading || isInvalid}>Nutzer entfernen</Button>
      </div>
    );
  }
}


const mapStateToProps = state => {
  const id = state.auth.user.id;
  const { users } = state.team;
  
  // Filter out the current user
  return {
    users: id && users ? users.filter(u => u.id !== id) : []
  }
}

const DeleteUserForm = connect(mapStateToProps, {deleteTeamUsers, addNotification})(DeleteUser);

export default createModal(DeleteUserForm);
