import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { locationChange } from 'actions';
import { userLogout } from 'actions/authActions';
import { isRefreshTokenExpired } from 'reducers';
import vars, { roles } from 'variables';

import Contact from 'components/ContactPage/Contact';
import FaqView from 'components/FaqPage/FaqView';
import FeedbackForm from 'components/Feedback/FeedbackForm';
import Footer from 'components/Footer';
import FrontView from 'components/FrontPage/FrontView';
import Header from 'components/Header/Header';
import Navbar from 'components/Header/Navbar';
import NotificationList from 'components/Notification/NotificationList';
import PrivacyView from 'components/PrivacyPage/PrivacyView';
import SpacePage from 'components/SpaceUsage/SpacePage';
import TermsConditionsView from 'components/TermsConditionsPage/TermsConditionsView';
import UserSettings from 'components/UserSettings';
import requireAuth from 'modules/requireAuth';

import CookiePopup from 'components/CookiePopup';
import CustomerManagement from 'components/CustomerManagement';
import FilterManagement from 'components/Filter/FilterManagement';
import ImportView from 'components/Import/ImportView';
import Library from 'components/Library/Library';
import NotFound from 'components/NotFound';
import UserActivation from 'components/UserActivationPage/UserActivation';
import TooltipRoot from 'components/common/TooltipRoot/TooltipRoot';
import { GlobalStyles } from '../../global-styles';

const AppWrapper = styled.div`
  background: rgb(245, 245, 245);
  min-height: 100vh;
  overflow: auto;
  padding-top: ${vars.main.headerHeight}px;
  position: relative;
`;

class App extends Component {
  UNSAFE_componentWillMount() {
    // Log out automatically if auth token has expired
    if (this.props.authExpired) {
      this.props.userLogout();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps.location;
    if (pathname !== this.props.location.pathname) {
      this.props.locationChange(pathname);
    }
  }

  render() {
    const { location, history, agreedToCookies } = this.props;
    return (
      <HelmetProvider>
        <AppWrapper>
          <Helmet titleTemplate="%s - archicloud" defaultTitle="archicloud" />
          <Header>
            <Navbar location={location} history={history} />
          </Header>
          <GlobalStyles />
          <div className="container">
            <NotificationList />
            <Switch>
              <Route exact path="/" component={FrontView} />
              {/* <Route path="/signup" component={Signup} /> */}
              <Route path="/contact" component={Contact} />
              <Route path="/datenschutz/:expanded?" component={PrivacyView} />
              <Route path="/agb/:expanded?" component={TermsConditionsView} />
              <Route path="/faq/:expanded?" component={FaqView} />
              <Route path="/import" component={requireAuth(ImportView, roles.crud)} />
              <Route path="/filters" component={requireAuth(FilterManagement, roles.crud)} />
              <Route path="/feedback" component={FeedbackForm} />
              <Route path="/space" component={requireAuth(SpacePage)} />
              <Route path="/action/:items" component={requireAuth(Library)} />
              <Route
                path="/activate/:token"
                render={(props) => <UserActivation action="activation" {...props} />}
              />
              <Route
                path="/passwordreset/:token"
                render={(props) => <UserActivation action="passwordreset" {...props} />}
              />
              <Route path="/settings" component={requireAuth(UserSettings)} />
              {/* <Route path="/subscription" component={requireAuth(ManageSubscription, roles.admin)} /> */}
              {/* <Route path="/upgrade" component={requireAuth(Upgrade)} /> */}
              <Route path="/customers" component={requireAuth(CustomerManagement, roles.super)} />
              <Route component={NotFound} />
            </Switch>
          </div>
          <Footer />
          {!agreedToCookies && <CookiePopup />}
          <TooltipRoot />
        </AppWrapper>
      </HelmetProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  agreedToCookies: state.preferences.agreedToCookies,
  authExpired: isRefreshTokenExpired(state),
});

export default withRouter(connect(mapStateToProps, { locationChange, userLogout })(App));
