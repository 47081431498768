import React from 'react';
import { connect } from 'react-redux';
import { Button, ControlsRow, ControlCol, SectionTitle, SplitPaneWrapper } from 'components/common/StyledComponents';
import { deleteItems } from 'actions/itemActions';
import { toggleSidebar } from 'actions/sidebarActions';

const ItemsDelete = ({ toggleSidebar, deleteItems, items, history }) => {
  
  const handleCancelClick = () => {
    history.push('/')
    toggleSidebar('library')
  }
  
  const handleDeleteClick = () => {
    deleteItems(items.map(i => i.id))
    .then(() => history.push('/'))
  }    
    
  return (
    <SplitPaneWrapper>
      <SectionTitle>{items.length} {items.length > 1 ? 'Objekte' : 'Objekt'} wirklich löschen?</SectionTitle>
      <ControlsRow>
        <ControlCol>
          <Button wide onClick={handleDeleteClick}>Ja</Button>
        </ControlCol>
        <ControlCol>
          <Button wide onClick={handleCancelClick}>Nein</Button>
        </ControlCol>
      </ControlsRow>
    </SplitPaneWrapper>
  );
}

export default connect(null, { deleteItems, toggleSidebar })(ItemsDelete);
