import React from 'react';
import { connect } from 'react-redux';

import LibraryGrid from 'components/Library/LibraryGrid';
import GuestFrontView from './GuestFrontView';

const FrontView = ({ auth }) => (
  auth.isAuthenticated ? <LibraryGrid /> : <GuestFrontView />
)

const mapStateToProps = ({ auth }) => ({ auth })

export default connect(mapStateToProps)(FrontView);
