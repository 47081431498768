import validator from 'validator';
import { isEmpty } from 'lodash';

export default (data) => {
  let errors = {};
  
  if (validator.isEmpty(data.email)) {
    errors.email = 'Dieses Feld muss ausgefüllt werden';
  }
  
  if (validator.isEmpty(data.password)) {
    errors.password = 'Dieses Feld muss ausgefüllt werden';
  }
  
  return {
    errors, 
    isValid: isEmpty(errors)
  }  
}