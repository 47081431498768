import ButtonLink from 'components/common/ButtonLink';
import InfoField from 'components/common/InfoField';
import { allValuesAreSame, formatDate } from 'utils/common';
import formatBytes from 'utils/formatBytes';

const ItemsInfo = ({ items, canEdit }) => {
  const diffItemsText = 'Objekte besitzen unterschiedliche';
  const item = items[0];

  const renderFileInfos = () => {
    if (!item.hasFile && items.length === 1) return null;

    return (
      <div>
        <InfoField label="Dateiname">
          {allValuesAreSame(items, 'name')
            ? item.name.split('.')[0]
            : `${diffItemsText} Dateinamen`}
        </InfoField>
        <InfoField label="Dateiformat">
          {allValuesAreSame(items, 'type') ? item.type : `${diffItemsText} Dateiformaten`}
        </InfoField>
        <InfoField label="Dateigröße">
          {allValuesAreSame(items, 'size')
            ? formatBytes(item.size)
            : `${diffItemsText} Dateigrößen`}
        </InfoField>
      </div>
    );
  };

  const updatedByFormatted = item.updatedBy ? `; ${item.updatedBy}` : '';

  return (
    <div>
      {renderFileInfos()}
      <InfoField label="letzte Änderung">
        {items.length === 1
          ? formatDate(item.updatedAt) + updatedByFormatted
          : `${diffItemsText} Änderungen`}
      </InfoField>
      {canEdit && (
        <ButtonLink disabled={!item} to={`/action/${item && item.id}/file`}>
          {!item.hasFile && items.length === 1 ? 'Datei hinzufügen' : 'Datei ersetzen'}
        </ButtonLink>
      )}
    </div>
  );
};

export default ItemsInfo;
