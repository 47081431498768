import axios from 'axios';
import { addNotification } from './notificationActions';

export const userSignupRequest = data => (
  dispatch => axios.post('/api/users', data)
)

export const checkExistence = email => (
  dispatch => axios.get(`/api/users/email/${email}`)
)

export const activateUser = (token, fields) => (
  dispatch => axios.post(`/api/users/activate/${token}`, fields)
)

export const deactivateUser = () => (
  dispatch => axios.get('/api/users/deactivate')
)

export const resetUserPassword = email => (
  dispatch => axios.put(`/api/users/password/${email}`)
)

export const updateUserPassword = data => (
  dispatch => axios.put('/api/users/password', data)
)

export const updateUserEmail = (id, data) => (
  dispatch => axios.put(`/api/users/${id}/email`, data)
)

export const getSpaceUsage = () => dispatch =>
  axios.get('/api/users/space').then(res => res.data);

export const getUserSettings = () => (
  dispatch => axios.get('/api/users/settings')
)

export const updateUserSettings = data => (
  dispatch =>
    axios.put(`/api/users/settings`, data)
    .then(res =>  
      dispatch(addNotification({
        type: 'success',
        text: 'Deine Einstellungen wurden erfolgreich aktualisiert'
      }))
    )
)

export const initUserAccountUpgrade = () => (
  dispatch => axios.get('/api/users/upgrade')
)

// Super actions

export const getAllUsers = () => dispatch =>
  axios.get("/api/users").then(res => res.data.users);
  
export const lockUserAccount = id => dispatch =>
  axios.get(`/api/users/lock/${id}`)
  
export const unlockUserAccount = id => dispatch =>
  axios.get(`/api/users/unlock/${id}`)