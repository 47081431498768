import { GET_TEAM_USERS } from 'actions/teamActions';

const initialState = {
  users: []
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TEAM_USERS:
      return {
        ...state,
        users: action.users
      }
      
    default: return state;
  }
}
