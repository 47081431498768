import React from 'react';
import PropTypes from 'prop-types';
import { StyledFlashMessage } from './flashStyles';
import { CloseIcon } from 'components/icons/';

const FlashMessage = ({ deleteNotification, message, style }) => {
  const { type, text, title, modal, button } = message;
  
  if (modal) return null;
  
  return (
    <StyledFlashMessage style={style} className={`alert-${type}`}>
      {text || title}
      <button onClick={() => deleteNotification(message.id)}>
        <CloseIcon viewBox='0 0 16 16' />
      </button>
    </StyledFlashMessage>
  );
}

FlashMessage.propTypes = {
  message: PropTypes.object.isRequired,
  deleteNotification: PropTypes.func.isRequired
}

export default FlashMessage;
