import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';

import { ArrowIcon } from 'components/icons';
import vars from 'variables';
import { FormGroup } from './StyledComponents';
import InfoLink from "components/common/InfoLink";

const StyledSelect = styled.div`
  padding: 0;
  margin: 0;
  border: 1px solid ${vars.colors.lightGray};
  background: ${props => props.gray ? vars.colors.lightestGray : '#fff'};
  font-size: .85rem;
  line-height: 1.3;
  position: relative;
  
  .select-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
  }
  
  &.unselected .select-arrow {
    margin-top: 0;
    transform: rotate(-90deg) translateX(100%);
  }
  
  select {
    width:100%;
    margin: 0;
    border-radius: 0;
    border: 0;
    padding: 3px 20px;
    outline: none;
    appearance: none;
    z-index: 1;
    position: relative;
    
    &:disabled {
      cursor: not-allowed;
    }
  }
`

const SelectField = ({
  name, value, label, onChange, className, formClassName, options, disabled, placeholder, gray, margin, moreInfo
}) => {
  
  const renderedOptions = options.map((option, key) => {
    let { value, name } = option
    return <option key={key} value={value || option}>{name || option}</option>
  })
  
  return (
    <FormGroup margin={margin} className={formClassName}>
      { label && <label>{label} {moreInfo && <InfoLink to={moreInfo} />}</label> }
      <StyledSelect
        gray={gray ? true : undefined}
        className={cx({ unselected: !value })}>
        <select
          disabled={disabled}
          name={name}
          onChange={onChange}
          value={value}>
          {placeholder && <option disabled value=''>{placeholder}</option>}
          {renderedOptions}
        </select>
        <div className='select-arrow'>
          <ArrowIcon height={8} width={8} viewBox='0 0 16 16' />
        </div>
      </StyledSelect>
    </FormGroup>
  )
}

SelectField.propTypes = {
  formClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array
}

SelectField.defaultProps = {
  disabled: false,
  value: ''
}

export default SelectField;