import { check } from './validate';
import { required, mustMatch, mustNotMatch } from './validate/rules';

const generalRules = action => {
  const rules = [
    check("password", "Kennwort", required),
    check("passwordConfirmation", "Kennwort", required),
    check("passwordConfirmation", "Kennwörter", mustMatch("password", "passwordConfirmation"))
  ]
  
  if (action === 'update') {
    return [
      ...rules,
      check("password", "Neues Kennwort", mustNotMatch("oldPassword", "password")),
      check("oldPassword", "Altes Kennwort", required),
    ]
  }
  
  return rules
}

export default generalRules