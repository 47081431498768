import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import vars from 'variables'
import { CloseIcon } from './icons'
import { addPreference } from 'actions/preferenceActions'

const StyledCookie = styled.div`
  background-color: ${vars.colors.lightGray};
  background-color: rgba(200,200,200,.85);
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 5px 15px;
  z-index: 600;
  .cookie-text {
    font-size: .85rem;
    color: #fff;
    display: inline;
    line-height: 25px;
    @media (min-width: ${vars.media.sm}) {
      line-height: 22px;
    }
  }
  span {
    display: none;
    @media (min-width: ${vars.media.md}) {
      display: initial;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  .close-icon {
    float: right;
    padding-left: 5px;
    cursor: pointer;
    height: 25px;
    width: 25px;
    @media (min-width: ${vars.media.sm}) {
      height: 22px;
      width: 22px;
    }
  }
`

const CookiePopup = ({ addPreference, agreedToCookies }) => {
  const handleCloseClick = () => {
    if (!agreedToCookies) {
      addPreference('agreedToCookies', true)
    }
  }

  const detailsLink = <Link to="/datenschutz">Details</Link>
  
  return (
    <StyledCookie>
      <div className='cookie-text'>Wir verwenden Cookies<span>. Durch die Nutzung der Website akzeptierst du die Verwendung von Cookies</span> ({detailsLink}).</div>
      <CloseIcon onClick={handleCloseClick} className='close-icon' stroke='#fff' viewBox='0 0 16 16' />
    </StyledCookie>
  )
}

const mapStateToProps = state => ({
  agreedToCookies: state.preferences.agreedToCookies
})

export default connect(mapStateToProps, {addPreference})(CookiePopup)