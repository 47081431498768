const rootUrl = window.location.protocol + '//' + window.location.host;

const extractRelevantKeys = (data = {}, keys = []) => {
  return Object.assign(
    {},
    ...keys.filter((k) => data[k] !== undefined).map((k) => ({ [k]: data[k] }))
  );
};

// Checks if all values of each element in a given array are the same
const allValuesAreSame = (arr, value) => arr.every((v) => v[value] === arr[0][value]);

const formatDate = (input) => {
  const date = isValidDate(input) ? input : new Date(input);

  return new Date(date).toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

// Get last day of given/current month
const getLastDayOfMonth = (m) => {
  const today = new Date();
  const month = m ? m : today.getMonth() + 1;
  return new Date(today.getFullYear(), month, 0);
};

// Check if the date is less than x minutes ago
const isLessThanMinutesAgo = (dateString, mins = 2) => {
  const date = dateString ? new Date(dateString) : 0;
  const minutesAgo = Date.now() - 1000 * 60 * mins;

  return date > minutesAgo;
};

const isValidDate = (d) => new Date(d) !== 'Invalid Date' && !isNaN(new Date(d));
const isDate = (d) => typeof d.getMonth === 'function';

// Total amount of months between two dates including both incomplete months
const monthDiff = (a, b) => {
  const d1 = isDate(a) ? a : new Date(a);
  const d2 = isDate(b) ? b : new Date(b);

  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth() + 1;
  return months <= 0 ? 0 : months;
};

// Toggle an item
const toggle = (state = [], id) => {
  if (!id) return state;

  const index = state.indexOf(id);

  if (index === -1) {
    return [...state, id];
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
};

const arrayify = (obj) => [].concat(obj);

// Delete keys from an object
const deleteKeys = (state, ids) => {
  state = { ...state };
  ids.forEach((id) => delete state[id]);
  return state;
};

// Get ids of all descendants of given id
const getAllDescendants = (state, id) =>
  state[id].children.reduce(
    (acc, childId) => [...acc, childId, ...getAllDescendants(state, childId)],
    []
  );

// Add an item or an array of items to an array
const addItems = (state = [], items) => [...state, ...arrayify(items)];

const addItem = (state = [], item) => (state.indexOf(item) === -1 ? [...state, item] : state);

// Delete an item or an array of items from an array
const deleteItems = (state = [], items) => {
  const _items = arrayify(items); // arrayify
  return state.filter((i) => _items.indexOf(i) === -1);
};

// Check if an array includes ALL items of a subarray
const arrayIncludes = (arr = [], sub = []) => arr.every((i) => sub.indexOf(i) > -1);

// Check if an array includes at least one item of a subarray
const arrayIncludesSome = (arr = [], sub = []) => arr.filter((i) => sub.indexOf(i) > -1).length > 0;

const sort = (array = []) =>
  // Array contains keys
  // Data is an object with actual values
  array.sort((a, b) => {
    const A = a.toLowerCase();
    const B = b.toLowerCase();

    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  });

const round = (num, precision = 100) => (Math.round(num * precision) / precision).toFixed(2);
const addCurrency = (num) => num.toString().replace('.', ',') + ' Euro';

export {
  addCurrency,
  addItem,
  addItems,
  allValuesAreSame,
  arrayIncludes,
  arrayIncludesSome,
  arrayify,
  deleteItems,
  deleteKeys,
  extractRelevantKeys,
  formatDate,
  getAllDescendants,
  getLastDayOfMonth,
  isLessThanMinutesAgo,
  isValidDate,
  monthDiff,
  rootUrl,
  round,
  sort,
  toggle,
};
