import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

export const UPDATE_TIME = 200;
export const MAX_PROGRESS = 90;
export const PROGRESS_INCREASE = 5;
export const ANIMATION_TIME = UPDATE_TIME * 2;

const darkGreen = 'hsl(150, 52%, 44%)';

const StyledLoadingBar = styled.div`
  background-color: ${darkGreen};
  box-shadow: 0 0 5px ${darkGreen};
  height: 3px;
  position: fixed;
  z-index: 998;
`;

const initialState = {
  percent: 0,
  progressInterval: null,
  animationTimeout: null,
};

export class LoadingBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.boundSimulateProgress = this.simulateProgress.bind(this);
    this.boundResetProgress = this.resetProgress.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.simulated && this.props.loading) {
      this.launch();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Launch simulated progress bar when loading === true && simulated === true
    if (this.props.simulated && nextProps.loading > this.props.loading) {
      this.launch();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.progressInterval);
    clearTimeout(this.state.animationTimeout);
  }

  launch() {
    let { progressInterval, percent } = this.state;
    const { animationTimeout } = this.state;

    if (!progressInterval) {
      progressInterval = setInterval(this.boundSimulateProgress, this.props.updateTime);
      clearTimeout(animationTimeout);
      percent = 0;
    }

    this.setState({ ...this.state, progressInterval, percent });
  }

  simulateProgress = () => {
    let { progressInterval, percent, animationTimeout } = this.state;
    const { loading, maxProgress, progressIncrease } = this.props;

    if (percent === 100) {
      clearInterval(progressInterval);
      animationTimeout = setTimeout(this.boundResetProgress, ANIMATION_TIME);
      progressInterval = null;
    } else if (loading === false) {
      percent = 100;
    } else if (percent + progressIncrease <= maxProgress) {
      percent += progressIncrease;
    }

    this.setState({ percent, progressInterval, animationTimeout });
  };

  resetProgress = () => {
    this.setState(initialState);
  };

  buildStyle() {
    // Use local percentage value if progress is being simulated
    const percent = this.props.simulated ? this.state.percent : this.props.percent;
    // Show progress bar if 0 < percent < 100
    const opacity = percent > 0 && percent < 100 ? '1' : '0';

    const style = {
      width: `${percent}%`,
      transition: `width ${ANIMATION_TIME}ms ease-out,
                   height ${ANIMATION_TIME}ms linear,
                   opacity ${ANIMATION_TIME}ms ease-out`,
      opacity,
    };

    return { ...style, ...this.props.style };
  }

  render() {
    return <StyledLoadingBar style={this.buildStyle()} className={this.props.className} />;
  }
}

LoadingBar.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  simulated: PropTypes.bool,
  percent: PropTypes.number,
  updateTime: PropTypes.number,
  maxProgress: PropTypes.number,
  progressIncrease: PropTypes.number,
};

LoadingBar.defaultProps = {
  style: {},
  className: undefined,
  loading: false,
  simulated: true,
  percent: 0,
  updateTime: UPDATE_TIME,
  maxProgress: MAX_PROGRESS,
  progressIncrease: PROGRESS_INCREASE,
};

const mapStateToProps = ({ loadingBar }) => {
  const { loading, percent, simulated } = loadingBar;

  return { loading, percent, simulated };
};

export default connect(mapStateToProps)(LoadingBar);
