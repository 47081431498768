import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { resetFiltersState } from '../../actions/filterActions';
import { StepNavBar, StepNavItem, Wrapper } from '../common/StyledComponents';
import FilterList from './FilterList';

class FilterManagement extends React.Component {
  componentWillUnmount() {
    // Reset selected filters of any type before unmounting
    if (this.props.isPrimarySelected) {
      this.props.resetFiltersState();
    }
  }

  render() {
    return (
      <Wrapper fixed>
        <Helmet>
          <title>Filterverwaltung</title>
        </Helmet>
        <StepNavBar>
          <StepNavItem>
            <span>Filterverwaltung</span>
          </StepNavItem>
        </StepNavBar>
        <FilterList selectable={false} />
      </Wrapper>
    );
  }
}

const select = (state) => ({
  isPrimarySelected: state.filters.primary !== null,
});

export default connect(select, { resetFiltersState })(FilterManagement);
