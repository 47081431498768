import React from 'react';
import styled from 'styled-components';
import { FormGroup, Field } from './StyledComponents';
import InfoLink from "components/common/InfoLink";

const Info = styled(Field)`
  & + & {
    margin-top: 10px;
  }
`;

const InfoField = ({ children, label, white, labelClassname, margin, moreInfo }) => (
  <FormGroup margin={margin} className='form-group--info'>
    {label && <label className={labelClassname}>{label} {moreInfo && <InfoLink to={moreInfo} />}</label>}
    {React.Children.map(children, (child, k) =>
      <Info white={white} key={k}>{child}</Info>
    )}
  </FormGroup>
);

InfoField.defaultProps = {
  white: true
}

export default InfoField;
