import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash'

import InputField from 'components/common/InputField';
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import Modal from 'components/Modal/Modal';
import { resetUserPassword, checkExistence } from 'actions/userActions'
import { addNotification } from 'actions/notificationActions'
import { validate, getVisibleErrors } from 'modules/validations/validate';
import validationRules from 'modules/validations/emailRules';
import { getErrorMessage } from 'modules/errorHandler';

class ResetPassword extends Component {
  constructor() {
    super()
    
    this.state = {
      errors: {},
      touched: {},
      isInvalid: false,
      isLoading: false
    }
  }
  
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }
  
  onBlur = e => {
    const { name, value } = e.target

    // Mark the field as touched if needed
    if (!this.state.touched[name]) {
      this.setState({
        touched: { ...this.state.touched, [name]: true }
      })
    }
    
    this.validate(name, value)
  }
  
  checkIfEmailExists = () => {
    const { email, errors } = this.state
    
    if (email && email.length && isEmpty(errors)) {
      this.props.checkExistence(email)
      .then(res => {        
        if (!res.data.user) {
          errors.email = 'Zu dieser E-Mail-Adresse haben wir kein archicloud-Konto hinterlegt'
        }
        
        this.setState({ errors, isInvalid: !isEmpty(errors) })
      })
    }
  }
  
  validate = (name, value) => {
    const { errors } = validate(this.state, validationRules)
    const isInvalid = !isEmpty(errors)
    
    this.setState({ errors, isInvalid }, () => {
      if (name === 'email') {
        this.checkIfEmailExists()
      }
    })
    
    return !isInvalid
  }
  
  handleSubmit = e => {
    e.preventDefault()
    const { resetUserPassword, addNotification, onClose } = this.props
    const { email } = this.state

    if (this.validate()) {
      this.setState({ isLoading: true });
      
      resetUserPassword(email)
      .then(res => {
        addNotification({
          type: "success",
          text: `Eine E-Mail wurde an ${email} gesendet. Klicke auf den enthaltenen Link, um dein Kennwort zurückzusetzen!`
        });
        onClose()
      })
      .catch(err => {
        this.setState({ isLoading: false });

        addNotification({
          type: "error",
          text: getErrorMessage(err) || "Beim Zurücksetzen des Kenntworts ist ein Fehler aufgetreten"
        });
      })
    }
  }
  
  render() {
    const { onClose } = this.props
    const { email, isInvalid, isLoading, errors, touched } = this.state
    const err = getVisibleErrors(errors, touched)

    return (
      <Modal title='Kennwort vergessen' isOpen={true} onClose={onClose}>
        <form onSubmit={this.handleSubmit}>
          <ContentWrapper>
            <p className="modal-text">Nach Eingabe deiner E-Mail-Adresse werden wir dir ein neues Kennwort zusenden.</p>
            <InputField
              gray
              error={err.email}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={email}
              name="email"
              type="email"
              placeholder="E-Mail-Adresse eingeben"
            />
          </ContentWrapper>
          <Button disabled={isInvalid || isLoading} wide>Neues Kennwort zusenden</Button>
        </form>
      </Modal>
    );
  }
}

export default connect(null, {checkExistence, resetUserPassword, addNotification})(ResetPassword);
