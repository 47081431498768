import React from 'react'
import { subscriptions } from 'variables'
import nextChar from 'utils/nextChar'
import StandartFilter from 'documents/StandartFilter.pdf'

import BildMitFiltern from './img/Faq_01.png'
import BildFilterErstellen from './img/Faq_02.png'
import BildWerStecktDahinter from './img/Faq_03.png'
import BildMitLupe from './img/Faq_04.png'

const proPrice = subscriptions.archicloudPro.monthly.brutto
const archicloudEmail = <a href='mailto:mail@archicloud.net'>mail@archicloud.net</a>

const sections = [
  {
    title: 'Sicherheit & Datenschutz',
    children: [
      {
        title: 'Wo liegen meine Daten?',
        text: 'Die Server von archicloud stehen ausschließlich in einem deutschen Sicherheits-Rechenzentrum.'
      },
      {
        title: 'Macht ihr eine Datensicherung?',
        text: 'Deine Daten werden von uns automatisch gespiegelt und sind dadurch immer doppelt vorhanden.'
      },
      {
        title: 'Nutzt archicloud eine verschlüsselte Verbindung?',
        text: 'Ja, die Verbindung zwischen dir und unseren Servern erfolgt grundsätzlich verschlüsselt (SSL).'
      },
      {
        title: 'Sammelt ihr Daten? Wenn ja welche?',
        text: <div>Für die Free-Version genügen deine E-Mail-Adresse, sowie das dazugehörige Passwort. In der Pro- Version benötigen wir noch eine Rechnungsanschrift und eine Zahlungsmethode. Wir verwenden Cookies und setzten Google Analytics ein, um das Besucherverhalten zu analysieren. Die Webanalyse erfolgt dabei pseudonym, das heißt wir können aus den Informationen keine Rückschlüsse zu dir ziehen. Für detailliertere Informationen kannst du gerne einen Blick in <a href='/datenschutz'>unsere Datenschutzerklärung</a> werfen.</div>
      },
      {
        title: 'Werden die Daten ausgewertet?',
        text: 'Deine Daten sind und bleiben deine Daten. Sie werden weder für werbliche noch für sonstige Zwecke ausgewertet und nicht an Dritte weitergegeben.'
      },
      {
        title: 'archicloud wird eingestellt oder ist insolvent. Sind dann alle meine Daten weg?',
        text: 'archicloud würde euch umgehend per Mail informieren und die Server für einen bestimmten Zeitraum online lassen. Über die Downloadfunktion könntet ihr alle Daten auf eigenen Laufwerken sichern. Somit kann ein Verlust der Daten nicht eintreten.'
      },
      {
        title: 'Ich habe mein Passwort vergessen!',
        text: 'Dein Passwort liegt verschlüsselt in der Datenbank und kann nicht einmal von uns ausgelesen werden. Beim Anmeldevorgang kannst du aber ein neues Passwort anfordern. Nach Angabe deiner E-Mail-Adresse wird es generiert und dir kurz darauf per E-Mail zugeschickt.'
      },
      {
        title: 'Wo kann ich meine E-Mail-Adresse und mein Passwort ändern?',
        text: 'Unter Menü/Einstellungen/Nutzerverwaltung kannst du deine E-Mail-Adresse und dein Passwort ändern.'
      },
      {
        title: 'Wie werden Passwörter vergeben, wenn Administratoren neue Nutzer hinzufügen?',
        text: 'Nach dem Einrichten neuer Nutzer versendet archicloud automatisch einen Link mit den Zugangsdaten, an die neue E-Mail-Adresse. Jeder Nutzer kann anschließen sein Passwort individuell vergeben.'
      },
      {
        title: 'Unsere Hinweise zum Datenschutz',
        text: <div>Unter folgendem Link findest du unsere <a href='/datenschutz'>Hinweise zum Datenschutz</a>.</div>
      },
      {
        title: 'Unsere Allgemeinen Geschäftsbedingungen (AGB) sowie Hinweise zum Widerruf',
        text: <div>Unter folgendem Link findest du die vollständige <a href='/agb'>AGB</a>, sowie Hinweise zu deinen Widerrufsmöglichkeiten.</div>
      }
    ]
  },
  {
    title: 'Zahlung & Rechnung',
    children: [
      {
        title: 'Was kostet archicloud?',
        text: (
          <ul>
            <li>archicloud-Free ist kostenlos. Beschränkungen entstehen durch den Speicherplatz, sowie der Mehrbenutzerfähigkeit.</li>
            <li>archicloud-Pro kostet pro Lizenz und 25 GB Speicherplatz monatlich {proPrice},00 Euro (inkl. MwSt.). Ein Account mit zwei Lizenzen und 50 GB Speicherplatz kostet {proPrice*2}€, ein Account mit drei Lizenzen und 75 GB Speicherplatz {proPrice*3}€, usw.</li>
          </ul>
        )
      },
      {
        title: 'Was sind die Vorteile der kostenpflichtigen Version?',
        text: (
          <div>
            archicloud-Pro bietet gegenüber der kostenfreien Version zwei Vorteile:
            <ul>
              <li>Der Speicherplatz ist mit 25 GB pro Lizenz deutlich höher und kann erweitert werden.</li>
              <li>Die Nutzer- und Rechteverwaltung bietet Möglichkeiten archicloud im Team zu nutzen.</li>
            </ul>
          </div>
        )
      },
      {
        title: 'Was bedeutet „pro Lizenz und Monat“?',
        text: 'archicloud bietet „Netzwerklizenzen“ an. Das bedeutet, dass eine Lizenz eine Online-Anmeldung erlaubt. Allerdings kannst du mit nur einer Lizenz eine unbeschränkte Anzahl an Personen hinterlegen. Hat ein Büro bspw. zehn Lizenzen, können zehn Mitarbeiter gleichzeitig mit ihrem persönlichen Account in archicloud arbeiten. Will sich „Mitarbeiter Nr. 11“ anmelden, erhält er eine Meldung, dass derzeit keine Lizenz verfügbar ist. Der Preis pro Lizenz wird monatlich fällig.'
      },
      {
        title: 'Kann ich die Bestellung widerrufen?',
        text: <div>Selbstverständlich besteht die Möglichkeit zum Wiederruf. In unseren <a href='/agb'>AGB</a> findest du genaue Angaben.</div>
      },
      {
        title: 'Wie werden Rechnungen zur Verfügung gestellt und wo finde ich diese?',
        text: 'Wir stellen Rechnungen im PDF-Format zur Verfügung. Diese fassen bei mehreren Nutzern die Gebühren der einzelnen Lizenzen zusammen. Die Rechnung wird immer an die E-Mail-Adresse des Administrators versandt. Zusätzlich kann eine weitere E-Mail-Adresse für den Rechnungsversand angegeben werden.'
      },
      {
        title: 'Wie kann ich bezahlen und wo ändere ich die Zahlungsmethode?',
        text: 'archicloud bietet die Möglichkeit per Kreditkarte, Lastschrift, PayPal und Rechnung zu bezahlen. Bei monatlichem Zahlungsintervall steht die Zahlungsmethode Rechnung nicht zur Verfügung. Unter Menü/Einstellungen/Aboverwaltung kannst du die Zahlungsmethode nachträglich ändern.'
      },
      {
        title: 'Wann muss ich bezahlen?',
        text: 'Der Rechnungsversand und die Abbuchung erfolgen am Tage der Registrierung. Je nach ausgewähltem Zahlungsintervall erfolgen weitere Zahlungen immer am gleichen Tag des jeweiligen Monats (Jahr). Werden neue Lizenzen abonniert, erfolgt eine Teilrechnung bis zum nächsten regulären Zahlungsintervall. Unter Menü/Einstellungen/Aboverwaltung/Lizenzen kannst du unter „Rechnungssumme pro Monat (Jahr)“ die anstehenden Rechnungsbeträge einsehen.'
      },
      {
        title: 'Was bedeutet „Rechnungsanschrift optional“?',
        text: 'Hier kannst du zusätzlich zur Rechnungsanschrift euren Firmen- bzw. Büronamen hinterlegen. Die Angabe ist optional und wird bei Eingabe auf der Rechnung ausgewiesen.'
      },
      {
        title: 'Gibt es eine Mindestvertragslaufzeit und Kündigungsfristen?',
        text: 'Die Mindestvertragslaufzeit beträgt einen (1) Monat. archicloud ist monatlich kündbar.'
      },
      {
        title: 'Wann endet mein Abonnement?',
        text: 'Das Abonnement wird monatlich verlängert und endet mit deiner Kündigung.'
      },
      {
        title: 'Wie kann ich kündigen und was passiert mit meinen Daten?',
        text: 'Unter Menü/Einstellungen/Aboverwaltung kannst du kündigen. Nachdem die Kündigung wirksam geworden ist, kannst du nicht mehr auf die Daten zugreifen. Wir werden allen Nutzern eine Bestätigungsmail zukommen lassen. Bevor du kündigst, kannst du über die Funktion „Download“ alle Daten herunterladen. Nach einer Frist von 14 Tagen werden die Daten und der Account gelöscht.'
      },
      {
        title: 'Was bedeutet „aktuell nutzbare Lizenzen"?',
        text: 'Unter Menü/Einstellungen/Aboverwaltung/Lizenzen kannst du die derzeit zur Verfügung stehenden Lizenzen einsehen. Fügst du deinem archicloud-Konto neue Lizenzen hinzu, stehen diese sofort zur Verfügung. Entfernst du Lizenzen, stehen diese noch einen Monat zur Verfügung.'
      }
    ]
  },
  {
    title: 'Voraussetzung & Technisches',
    children: [
      {
        title: 'Was brauche ich, um archicloud zu nutzen?',
        text: 'Ein internetfähiges Gerät, einen aktuellen Browser und eine E-Mail-Adresse. archicloud benutzt keine eigenen Dateiformate und funktioniert mit allen Betriebssystemen und Geräten.'
      },
      {
        title: 'Kann ich archicloud testen?',
        text: 'Wir bieten archicloud in einer Free- & Pro-Version an. archicloud-Free bietet die gleichen Funktionen wie archicloud-Pro und ist lediglich in der Speicherplatzgröße, sowie der Benutzung durch mehrere Anwender beschränkt. Mit archicloud-Free kannst du unser Produkt ausgiebig und ohne zeitliche Beschränkung testen.'
      },
      {
        title: 'Kann ich archicloud offline nutzen?',
        text: 'Nein, das ist momentan leider nicht möglich.'
      },
      {
        title: 'Wieviel Speicherplatz steht mir zur Verfügung?',
        text: (
          <ul>
            <li>archicloud-Free stellt 1 GB zur Verfügung.</li>
            <li>archicloud-Pro stellt 25 GB zur Verfügung. Bei zwei Lizenzen 50 GB, bei drei Lizenzen 75 GB, usw.</li>
          </ul>
        )
      },
      {
        title: 'Wo kann ich sehen wieviel Speicherplatz ich verbraucht habe.',
        text: 'Unter Menü/Info/Speicher kannst du den aktuellen Speicherstand abrufen.'
      },
      {
        title: 'Kann ich archicloud auf meinem eigenen Server installieren?',
        text: <div>Bitte kontaktiere uns bei Interesse unter {archicloudEmail}.</div>
      },
      {
        title: 'Wie werden die Vorschaubilder für alle Dateiformate erstellt?',
        text: 'Für Bild- & pdf-Formate erstellt archicloud, während des Imports, automatisch Vorschaubilder. Für alle anderen Dateiformate fordert dich archicloud auf, ein Vorschaubild auszuwählen. Über einen Screenshot der Originaldatei erstellst du Vorschaubilder dabei am schnellsten.'
      },
      {
        title: 'Kann ich den Dateinamen nachträglich ändern?',
        text: 'Der Dateiname wird beim Import übernommen. Willst du den Namen nachträglich ändern, kannst du unter Eigenschaften „Datei ersetzen“ wählen und die Datei mit einem anderem Dateinamen erneut hochladen.'
      },
      {
        title: 'Wie funktioniert der Download?',
        text: 'Objekte auswählen, Download anwählen und Speicherort angeben. Die Dateien werden in einem Zip-Ordner auf deinem Rechner abgelegt.'
      },
      {
        title: 'Kann ich von archicloud-Free zu archicloud-Pro wechseln?',
        text: 'Ja, du kannst mit allen Daten von archicloud-Free zu archicloud-Pro wechseln.'
      },
      {
        title: 'Kann ich von archicloud-Pro zu archicloud-Free wechseln?',
        text: 'Nein, dieser Wechsel ist nicht möglich. Du kannst deinen archicloud-Pro-Account jedoch kündigen und einen neuen archicloud-Free-Account einrichten. Deine Daten können dabei jedoch nicht übernommen werden.'
      },
    ]
  },
  {
    title: 'Bibliotheken & Objekte',
    children: [
      {
        title: 'Was sind Objekte?',
        text: 'Unter Objekte verstehen wir alle Dateien, die bei der täglichen Arbeit in Architekturbüros zum Einsatz kommen. Objekte bestehen aus einer Datei, deren Metadaten, sowie einem Vorschaubild und den dazugehörigen Filter. Ein Objekt kann zum Beispiel ein 3D Modell, ein BIM-Bauteil, eine 2D Detailzeichnung, ein Staffage-Baum, ein Wettbewerbsplan oder ein E-Book sein.'
      },
      {
        title: 'Welche Bibliotheken gibt es und für welche Objekte sind sie gedacht?',
        text: (
          <div>
            Zu Beginn von archicloud stehen vier Bibliotheken zur Verfügung. 
            <ul>
              <li>Grundlagen<br/>Objekte die sich wiederholen oder durch Bürostandards definiert sind. Grundlagenobjekte können zum Beispiel Aufzüge, Erschließungskerne oder Layoutvorlagen sein. Aber auch alles was für die tägliche Planerstellung notwendig ist. Zum Beispiel Möbel-, Menschen- oder Pflanzenobjekte.</li>
              <li>Referenzen<br/>Unter Referenzen sind alle Bilder, Pläne, Visualisierungen oder Skizzen gemeint, die archiviert werden sollen und in Zukunft als Inspiration dienen können.</li>
              <li>Detail<br/>Detailzeichnungen werden mit Filtern versehen und über das ganze Büroleben für jeden Mitarbeiter zugänglich gemacht. Bevor neue Details erstellt werden, hilft archicloud bereits Vorhandenes zum gleichen Thema anzuzeigen.</li>
              <li>Literatur<br/>Leitfäden, Baudetails, Rechtliches, Computeranwendungen oder einfach nur Inspiration. archicloud hilft einen schnellen Überblick über die vorhandene Literatur zu erhalten. Neben klassischen Büchern können auch E-Books, Zeitschriften, Artikel oder Normen abgelegt werden.</li>
            </ul>
          </div>
        )
      },
      {
        title: 'Ich will nicht alle Bibliotheken nutzen!',
        text: 'Die Bibliotheken, die du nicht benötigst, werden dir nicht auffallen! Dank der flexiblen Filter erscheinen ausschließlich die Bibliotheken, Filtergruppen und Filter, zu denen Objekte importiert wurden. Nutze archicloud zum Beispiel nur als dein persönliches Architekturbildarchiv oder als Detailsammlung.'
      },
      {
        title: 'Wieso erscheinen bei mir die Vorschaubilder in schwarz-weiß?',
        text: 'Um eine einheitliche Übersicht zu erhalten haben wir uns entschieden alle Objekte in schwarz-weiß anzuzeigen. Sobald du ein Objekt mit dem Cursor berührst, es auswählst oder die Eigenschaften des Objekts aufrufst, erscheint das Vorschaubild in Originalfarbe.'
      },
      {
        title: 'Wie kann ich Objekte auswählen, bearbeiten oder herunterladen?',
        text: 'Du kannst beliebig viele Objekte per Klick markieren. Sobald ein Objekt ausgewählt ist, erscheint ein Kontextmenü mit allen Befehlen, die auf Objekte anwendbar sind.'
      },
      {
        title: 'Was bedeutet „Objekte besitzen unterschiedliche Dateinamen, -größen oder -formate?',
        text: 'Wenn du mehrere Objekte gleichzeitig auswählst und anschließend die Eigenschaften aufrufst, werden sich voraussichtlich nicht alle Eigenschaften decken.'
      },
      {
        title: 'Was bedeutet „die Lupe mit Pluszeichen“ in der Übersicht?',
        text: <div>Die Lupe mit Pluszeichen ist ein Platzhaltersymbol für Objekte, bei welchen kein Vorschaubild hinterlegt wurde. Wenn du dieses Vorschaubild markierst, kannst du im Kontextmenü unter Vorschau ein neues Bild hinterlegen.<img src={BildMitLupe} alt='die Lupe mit Pluszeichen' /></div>
      }
    ]
  },
  {
    title: 'Suchen & Filtern',
    children: [
      {
        title: 'Bibliotheken, Filtergruppen, Filter... Was ist der Unterschied?',
        text: <img src={BildMitFiltern} alt='Bibliotheken, Filtergruppen, Filter' />
      },
      {
        title: 'Wie funktionieren Bibliotheken, Filtergruppen und Filter?',
        text: 'Beim Import von Objekten weist du jeder Datei eine Bibliothek und die passenden Filter zu. Durch die Zuweisung von Filter werden die Objekte automatisch den passenden Filtergruppen zugeordnet.'
      },
      {
        title: 'Bei mir sind keine oder nicht alle Bibliotheken, Filtergruppen und Filter vorhanden?',
        text: 'Es werden immer nur die Bibliotheken, Filtergruppen und Filter angezeigt, zu welchen auch Objekte importiert wurden.'
      },
      {
        title: 'Wie erstelle ich eigene Filter?',
        text: (
          <div>
            Alle Filter können beliebig erweitert und gelöscht werden. Die Bibliotheken und Filtergruppen hingegen sind nicht änderbar. Du hast zwei Möglichkeiten Filter zu bearbeiten.
            <ul>
              <li>Bei der Zuordnung von Filtern im Import-Menü.</li>
              <li>Unter Menü\Einstellungen\Filterverwaltung.</li>
            </ul>
            Bei beiden Möglichkeiten findest du einen Befehl, der je nach Filter z.B. wie folgt heißt: „Bauteile hinzufügen“ oder „Bauteile löschen“.
            <img src={BildFilterErstellen} alt='Das Erstellen von Filtern' />
          </div>
        )
      },
      {
        title: 'Gibt es eine Übersicht aller Filter?',
        text: <div>Unter Menü/Einstellungen/Filterverwaltung kannst du alle Filter einsehen. Unter folgendem Link kannst du die von archicloud <a href={StandartFilter}>als Standard eingerichteten</a> Filter im pdf-Format einsehen.</div>
      },
      {
        title: 'Bei der Auswahl von mehreren Objekten, werden nicht alle Filter angezeigt?',
        text: 'Wenn du mehrere Objekte gleichzeitig auswählst, werden unter Eigenschaften/Gemeinsame Filter nur die Filter angezeigt, welche die ausgewählten Objekte gemeinsam haben.'
      },
      {
        title: 'Wie kann ich eine Suche wieder aufheben?',
        text: 'In dem du auf „archicloud“ in der Menüzeile klickst werden alle Suchanfragen und aktivierte Filter aufgehoben.'
      },
      {
        title: 'Projekte und Favoriten anlegen – Warum?',
        text: (
          <div>
            Unter Projekte und Favoriten kannst du Objekte unabhängig der eigentlichen Filter zusammenfassen. Lass dir zum Bespiel Folgendes anzeigen:
            <ul className='bulletList'>
              <li>was in einem bestimmten Projekt verwendet wurde.</li>
              <li>was dir für ein einzelnes Projekt als Inspiration dient.</li>
              <li>was du für jeden Wettbewerb wieder verwendest.</li>
              <li>was dir persönlich gut gefällt.</li>
              <li>welche Normen du bei einem bestimmten Projekt verwendet hast.</li>
              <li>und viele andere Möglichkeiten.</li>
            </ul>
          </div>
        )
      },
      {
        title: 'Kann jeder Nutzer Projekte & Favoriten bearbeiten? Wie mache ich das?',
        text: 'Projekte und Favoriten haben die gleichen Funktionen wie Filter und können von Administrator- und Standardnutzern verändert werden. Unter Menü/Einstellungen/Filterverwaltung kannst du beliebig viele Projekte und Favoriten anlegen und löschen.'
      },
    ]
  },
  {
    title: 'Bibliotheken importieren',
    children: [
      {
        title: 'Eure Idee ist gut, aber wir haben keine Zeit alle Bibliotheken in archicloud einzupflegen!',
        text: 'Lass uns das machen, ihr übergebt uns eure Daten und wir pflegen sie in archicloud ein.'
      },
      {
        title: 'Was kostet der Service?',
        text: <div>Das Importieren von Objekten übernehmen wir gegen einen Unkostenbeitrag, der uns entsteht. Je nach Anzahl der Lizenzen, einer Bindung über einen gewissen Zeitraum und den Datenmengen würden wir dir ein passendes Angebot unterbreiten. Bitte kontaktier uns bei Interesse unter {archicloudEmail}</div>
      },
      {
        title: 'Wie geht ihr vor?',
        text: 'Durch die langjährige Erfahrung in Architekturbüros, können wir gut zuordnen, welche Dateien, welche Filter benötigen. Vorschaubilder erstellen wir aus dem Inhalt der Dateien. Sollten wir trotzdem Rückfragen haben, nehmen wir mit Euch Kontakt auf.'
      },
      {
        title: 'Pflegt ihr unsere Bibliotheken auch in Zukunft?',
        text: <div>Darüber sollten wir im Detail sprechen. Bitte kontaktier uns unter {archicloudEmail}</div>
      },
      {
        title: 'Wie funktioniert der Import?',
        text: (
          <div>
            Der Import gliedert sich in drei Schritte.
            <ul>
              <li>Filter wählen<br />Du wählst zuerst die Bibliothek, dann die Filtergruppe in welcher das Objekt später gefunden werden soll. Daraufhin zeigt dir archicloud die zur Verfügung stehenden Filter an. Markiere die passenden Filter mit einem „+“ oder erstelle bei Bedarf neue Filter.</li>
              <li>Datei wählen<br />Wähle die für das Objekt zu hinterlegende Datei aus. Beim Dateiformat gibt es dabei keine Beschränkung. Bei der Dateigröße ist der Upload auf 500 MB pro Datei beschränkt.</li>
              <li>Vorschaubild wählen<br />Wähle ein aussagekräftiges Vorschaubild. Den anzuzeigenden Bildausschnitt kannst du in archicloud bestimmen. Für Bild- und pdf-Formate erstellt archicloud automatische Vorschaubilder.</li>
            </ul>
            Mit „Import abschließen“ pflegt archicloud das neue Objekt in die Datenbank ein.
          </div>
        )
      },
      {
        title: 'Kann ich mehrere Dateien auf einmal importieren?',
        text: 'Nein, das ist momentan leider nicht möglich. Allerdings steht dir beim Import die Funktion „Letzte Filterauswahl verwenden“ zur Verfügung. Dadurch kannst du sehr schnell, viele Objekte importieren.'
      },
      {
        title: 'Kann ich Objekte auch nachträglich anpassen?',
        text: 'Ja, unter Eigenschaften kannst du nach dem Import alle Objekteinstellungen anpassen.'
      },
      {
        title: 'Wie kann ich gedruckte Bücher digital ablegen?',
        text: (
          <div>
            Die Literaturbibliothek unterscheidet sich insofern von den anderen Bibliotheken, das sie nicht nur digitale E-Books oder Artikel archiviert, sondern auch deine gedruckten Bücher aufnimmt und dir so einen schnellen Überblick über deine gesamte Literatur gibt. Wenn du möchtest kannst du deine digitale und gedruckte Literatur an einem Ort strukturieren.
            <ul className='bulletList'>
              <li><strong>Format</strong><br />Beim Import kannst du entscheiden ob das Objekt digital oder analog vorliegt. Wählst du analog weist archicloud automatisch einen Bibliotheksstandort zu.</li>
              <li><strong>Datei hochladen</strong><br />Wählst du digital, bietet dir archicloud unter Datei hochladen eine Option die „digitale Literatur“, egal ob E-Book, pdf-Dokument oder ein Scan, abzulegen.</li>
            </ul>
          </div>
        )
      },
      {
        title: 'Wieso kann ich bei Literatur einen Standort auswählen?',
        text: (
          <div>
            Wählst du beim Literatur-Import das Format „analog“ aus, weist archicloud automatisch einen Bibliotheksstandort zu. Dadurch kannst du eine eigene Literaturverwaltung aufbauen. archicloud geht bei der Standortvergabe wie folgt vor:
            <ul className='bulletList'>
              <li>Alle Literatur-Filtergruppen sind alphabetisch sortiert.</li>
              <li>Weist du einem Buch einen Filter zu, weiß archicloud in welcher Filtergruppe das Buch abgelegt wird und vergibt dafür einen Buchstaben.</li>
              <li>Werden weitere Bücher mit dem gleichen Filter abgelegt, weißt archicloud eine fortlaufende Nummer zu. Hast du zum Beispiel 62 Bücher über Architekten und Architekturbüros würde archicloud dem neusten Buch den Standort D.62 vergeben.</li>
              <li>Werden einem Buch mehrere Filter vergeben, zeigt dir archicloud eine Auswahlliste der möglichen Standorte an.</li>
            </ul>
          </div>
        )
      },
      {
        title: 'Was bedeutet „Datenbankabfrage über ISBN?',
        text: 'archicloud kann mit Hilfe der ISBN-Nummer das Einpflegen von Literatur-Objekten beschleunigen. Durch die Eingabe der ISBN (egal ob mit oder ohne Bindestrich) wird nach Buchinformation wie z.B. Titelbild oder Autor gesucht. Bei erfolgreicher Suche werden die in archicloud relevanten Daten direkt in die Felder eingepflegt. Die manuelle Eingabe oder Anpassung der Daten ist dabei jederzeit möglich. Werden zu der eingegebenen ISBN-Nummer keine Infos gefunden erhältst du eine Statusmeldung.'
      },
      {
        title: 'Was soll ich bei „Ort“ eingeben?',
        text: 'archicloud hilft dir mit „Autovervollständigen“ beim Eingeben von Ortsdaten. Dabei entscheidest du selbst ob einem Referenzobjekt nur das Land, die Stadt oder die genaue Straße zugewiesen wird.'
      }
    ]
  },
  {
    title: 'Nutzerverwaltung & Kontotypen',
    children: [
      {
        title: 'Welche Kontotypen gibt es?',
        text: (
          <div>
            <ul>
              <li>
                Administrator (Vollzugriff)<br />
                <ul className='bulletList'>
                  <li>Import, Download und Löschen von Objekten</li>
                  <li>Einstellungen ändern (Abo-, Nutzer,-Filterverwaltung)</li>
                </ul>
              </li>
              <li>
                Standard<br />
                <ul className='bulletList'>
                  <li>Import, Download und Löschen von Objekten</li>
                  <li>Einstellungen ändern (Eigenes Kennwort, Filterverwaltung)</li>
                </ul>
              </li>
              <li>
                Einfach<br />
                <ul className='bulletList'>
                  <li>Download von Objekten</li>
                  <li>Einstellungen ändern (Eigenes Kennwort)</li>
                </ul>
              </li>
            </ul>
          </div>
        )
      },
      {
        title: 'Was bedeutet Administrator? Lässt sich das ändern?',
        text: 'Nur Administratoren können den Account kündigen, Aktionen rund um Zahlungen vornehmen oder andere Nutzer verwalten. Kontotypen können unter Menü/Einstellungen/Nutzerverwaltung nur von Administratoren geändert werden. Es muss min. einen Administrator geben.'
      },
      {
        title: 'Wie viele Nutzer kann ich mit archicloud anlegen?',
        text: 'Die Anzahl der Benutzer ist nicht begrenzt. Du kannst, unabhängig von den abonnierten Lizenzen, eine beliebige Anzahl an Nutzer hinterlegen. Eine gleichzeitige Anmeldung mehrerer Nutzer wird durch die Anzahl der Lizenzen beschränkt.'
      },
      {
        title: 'Kann ich die Anmeldung bei archicloud auf Bürogeräte beschränken?',
        text: <div>Zu Beginn von archicloud steht diese Funktion noch nicht zur Verfügung. Bitte kontaktiere uns bei Interesse unter {archicloudEmail}.</div>
      },
      {
        title: 'Was bedeutet „Automatisches Abmelden nach“?',
        text: 'Um zu verhindern, dass sich Kollegen, zum Beispiel vor einem Urlaub, nicht abmelden und diese Lizenzen anderen Nutzern nicht zur Verfügung stehen, bietet archicloud eine Möglichkeit nach welcher Zeitspanne Nutzer automatisch abgemeldet werden. Willst du immer eingeloggt bleiben, wähle hier die Funktion „Nie“ aus.'
      }
    ]
  },
  {
    title: 'Warum archicloud',
    children: [
      {
        title: 'Warum reduziert archicloud die Komplexität in Architekturbüros?',
        text: 'Es fällt immer wieder auf, dass viele Ablagesysteme unsystematisch aufgebaut sind. Üblicherweise werden in deutschen Architekturbüros Bibliotheken mit Ordnern und jeweiligen Unterordnern gepflegt. Durch eingeschränkte Such- und Filtermöglichkeiten, sowie begrenzte Vorschaubilder, wird ein schneller Überblick nahezu unmöglich. Aufwendige Recherchearbeit führt dazu, dass jeder Mitarbeiter eigene Unterbibliotheken führt, auf ihm bekannte Projekte zurückgreift und jegliche Bürostandards schnell verloren gehen. Es entsteht ein unterschiedliches Ablageverhalten, Doppelführungen und damit Verwirrung. Durch ein einheitliches Layout und gleiche Funktionen für alle Bibliotheken erhältst du in archicloud einen schnellen Überblick über alle Informationen. Recherche wird minimiert, auf Vorhandenes wird zurückgegriffen und die Suche in alten Projekten und Dateibeständen entfällt.'
      },
      {
        title: 'Warum kann archicloud helfen Geld zu sparen?',
        text: `Die Integration von archicloud ermöglicht die Konzentration auf das Kerngeschäft. Es sind weder Backups, noch Updates notwendig. archicloud kann bereits vorhandene Bibliotheken nutzen und ist webbasiert. Das bedeutet, alle für den Betrieb notwendigen Ressourcen, wie Rechenleistungen, oder Speicherplatz wird durch das Rechenzentrum zur Verfügung gestellt. Mit ${proPrice} Euro pro Monat und Lizenz ist archicloud ein geringes Investitionsrisiko.`
      },
      {
        title: 'Warum ich mich mit archicloud nicht an Software, Dateiformate oder Betriebssysteme binde?',
        text: 'archicloud benutzt keine eigenen Dateiformate, ist softwareunabhängig und funktioniert mit allen Betriebssystemen und Geräten.'
      },
      {
        title: 'Warum ist archicloud so einfach zu benutzen?',
        text: 'Du musst weder Einstellungen an deinem Computer vornehmen, Software installieren noch andere Rechner einbinden. Einstellungen sind auf ein Minimum beschränkt und in einem Menü zu finden.'
      },
      {
        title: 'Warum hat archicloud Vorteile gegenüber bekannten Cloud-Laufwerken?',
        text: 'Im Unterschied zu herkömmlichen Cloud-Laufwerken wurde archicloud speziell für die Architekturbranche entwickelt. Nur in archicloud kannst du dir Vorschaubilder für alle Dateiformate einrichten. Nur in archicloud findest du flexible Architekturfilter und nur archicloud arbeitet mit angepassten Bibliotheken.'
      },
      {
        title: 'Warum ist archicloud perfekt für Teamarbeit?',
        text: 'In archicloud kann jeder neue Mitarbeiter einen schnellen Überblick über die Bibliotheksbestände des Büros erhalten. Partner und Projektleiter können die Bibliotheken zur Recherche nutzen oder ihr Wissen über vergangene Projekte und vorhandene Grundlagen einfach an neue Mitarbeiter weitergeben. Mit der Nutzer- und Rechteverwaltung kann man regeln, wer auf Objekte zugreift, wer sie verändern oder neue hinzufügen darf.'
      },
      {
        title: 'Warum ist archicloud besser als herkömmliche Ordnerstrukturen?',
        text: 'Im Unterschied zur klassischen Ordnerstruktur kannst du jedem Objekt mehrere Merkmale zuweisen. Du kannst es dir so vorstellen, dass alle Dateien in einem Ordner liegen, aber immer nur die Dateien angezeigt werden, nach denen du suchst. Zusätzlich kann für jedes Objekt ein Bild zugewiesen werden, was einen schnellen Überblick ermöglicht. Des Weiteren verhindert archicloud ein versehentliches Überschreiben einmal erstellter Strukturen.'
      },
      {
        title: 'Für wen ist archicloud gedacht?',
        text: 'Für Architekten. Als Student erstellst du eigene Grundlagen oder teilst gemeinsame Bibliotheken mit Kommilitonen. Nach dem Studium nimmst du deine Sammlung mit ins Berufsleben oder ordnest als Angestellter deine eigenen Grundlagen. Als Büro führt man endlich alle Bibliotheken, grafisch aufbereitet, an einem Ort zusammen. Mehrere Standorte nutzen die gleichen Standards.'
      },
      {
        title: 'Bietet archicloud Einführungen und Schulungen an?',
        text: <div>Wir glauben das Schulungen für archicloud nicht notwendig sind, da die Software auch für ungeübte Webnutzer sehr einfach zu bedienen ist. Um die Vorteile und mögliche Nutzungsszenarien vorzustellen, kommen wir gerne bei euch vorbei. Bitte kontaktiere uns bei Interesse unter {archicloudEmail}.</div>
      },
    ]
  },
  {
    title: 'Über archicloud',
    children: [
      {
        title: 'In einem Satz',
        text: 'archicloud ist eine Webanwendung und bietet Architekten die Möglichkeit, Planungsgrundlagen grafisch aufbereitet zu strukturieren.'
      },
      {
        title: 'Welches Problem löst archicloud?',
        text: 'archicloud löst das Problem, dass Planungsgrundlagen im Internet, in der Fachliteratur und in Büros zwar vorhanden sind, ein schneller Überblick jedoch nahezu unmöglich ist. archicloud behebt diese Ineffizienzen indem es hilft, Planungsgrundlagen grafisch aufbereitet zu strukturieren und dauerhaft zugänglich zu machen.'
      },
      {
        title: 'Warum sind wir hier?',
        text: 'Aufgrund der Unzufriedenheit über einen hohen zeitlichen Aufwand für Grundlagenrecherche. Wir denken, dass im Alltag einfache aber wichtige Büropotenziale oft nicht genutzt werden.'
      },
      {
        title: 'Was machen wir?',
        text: 'Jeden Tag ein bisschen einfacher! Wir glauben nicht daran, dass gute Architektur im kreativen Chaos entsteht und sind überzeugt, mit archicloud eine neue Einfachheit in die Grundlagen von Architekturbüros zu bringen. Unsere Aufgabe besteht darin, vorhandene und täglich verwendete Planungsgrundlagen schnell und einfach zugänglich zu machen.'
      },
      {
        title: 'Wohin wollen wir?',
        text: 'Bei allen Entscheidungen steht die einfache Handhabung, sowie der Kundennutzen im Vordergrund. Durch kontinuierliche Verbesserung soll archicloud helfen, notwendige Bürogrundlagen langfristig zu strukturieren und den Architektenalltag zu vereinfachen.'
      },
      {
        title: 'Wie verdienen wir Geld?',
        text: 'archicloud generiert ausschließlich über den Verkauf von archicloud-Pro Lizenzen Einnahmen.'
      },
      {
        title: 'Machen wir Fehler?',
        text: 'Mit Sicherheit - Wenn etwas schiefgeht, erfährst du es per E-Mail.'
      },
      {
        title: 'Wer steckt hinter archicloud?',
        text: <div>Eileen Glaschick und Achim Wangler, ein Duo mit Sitz in Stuttgart. Studium der Architektur, seit 2011 Diplom-Ingenieure, inzwischen eingetragene Architekten<img src={BildWerStecktDahinter} alt='Wer steckt hinter archicloud?' /></div>
      },
      {
        title: 'Wie kann ich mit archicloud Kontakt aufnehmen?',
        text: <div><a href='/contact'>Bitte hier entlang</a>.</div>
      }
    ]
  }
]

// Enumerate sections

sections.map((s, i) => {
  let lastChar = null
  s.children = s.children.map((c, k) => {
    lastChar = lastChar ? nextChar(lastChar) : 'a'
    c.key = lastChar
    return c
  })
  s.key = i+1
  return s
})

export default sections