import React from 'react'
import DatenschutzPdf from 'documents/Datenschutz.pdf'

const archicloudEmail = <a href='mailto:mail@archicloud.net'>mail@archicloud.net</a>

const browserLinks = (
  <span>
    <a href='https://support.google.com/chrome/answer/95647?hl=de' rel="noopener noreferrer" target='_blank'>Chrome</a> |&nbsp;
    <a href='https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen' rel="noopener noreferrer" target='_blank'>Firefox</a> |&nbsp;
    <a href='http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies' rel="noopener noreferrer" target='_blank'>Internet Explorer</a> |&nbsp;
    <a href='https://support.apple.com/kb/PH21411?viewlocale=de_DE&locale=de_DE' rel="noopener noreferrer" target='_blank'>Safari</a>
  </span>
)

const sections = [
  {
    title: 'Allgemeines',
    children: [
      <div>Mit den folgenden Hinweisen zum Datenschutz erläutern wir, archicloud <em>(Silberburgstr. 66a, 70176 Stuttgart vertreten durch Achim Wangler)</em>, welche personenbezogenen Daten erhoben werden, wenn Sie unsere Website archicloud.net besuchen und dort den webbasierten Service archicloud nutzten. Die nachfolgende Erklärung gibt Ihnen einen Überblick darüber, wie archicloud diesen Schutz gewährleistet und welche Art von Daten zu welchem Zweck erhoben werden.</div>,
      'archicloud verwendet in diesen Hinweisen zum Datenschutz bestimmte feststehende Begriffe: „Personenbezogene Daten“ sind alle Einzelangaben über persönliche oder sachliche Verhältnisse.'
    ]
  },
  {
    title: 'Sammlung persönlicher Informationen',
    children: [
      'Personenbezogene Daten werden durch archicloud nur im erforderlichen Umfang erhoben, verarbeitet und genutzt.',
      'Einige persönliche Informationen werden gesammelt, wenn Sie einen Account erstellen. Bei archicloud- Free ist dies Ihre E-Mail-Adresse. In archicloud-Pro benötigen wir zusätzlich die vollständige Rechnungsanschrift [Nachnamen, Vornamen (ggf. Firmenname) sowie Straße, Hausnummer, Postleitzahl, Ort] und eine Zahlungsmethode.',
      'Wenn Sie archicloud per E-Mail kontaktieren, speichern wir den Schriftverkehr.'
    ]
  },
  {
    title: 'Datenverarbeitung',
    children: [
      (
        <div>Server-Logfile<br />archicloud erhebt und speichert in seinen Server-Logfiles Informationen, die Ihr Browser an uns übermittelt. Dies sind:
        <ul>
          <li>verwendetes Betriebssystem</li>
          <li>Browsertyp/ -version</li>
          <li>Referrer-URL (die zuvor besuchte Seite)</li>
          <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
          <li>Uhrzeit der Serveranfrage.</li>
        </ul>
        archicloud kann diese Daten nicht bestimmten Personen zuordnen. Eine Zusammenführung dieser Daten mit anderen Daten wird nicht vorgenommen.</div>
      ),
      <div>Cookies<br />archicloud verwendet an mehreren Stellen Cookies. Sie dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Die meisten der von uns verwendeten Cookies sind so genannte Session-Cookies. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. archicloud verwendet außerdem Cookies, um einen Nutzer während des gesamten Nutzungsvorgangs zu identifizieren. Dadurch muss sich der Nutzer nicht auf jeder aufgerufenen personalisierten Seite neu einloggen. Das Setzen von Cookies kann durch eine entsprechende Einstellung im Browser verhindert werden. Allerdings ist archicloud dann möglicherweise nicht mehr voll funktionsfähig. Die folgenden Links zeigen, wie die Einstellungen der üblicherweise verwendeten Browser angepasst werden können:<br /> {browserLinks}</div>,
      <div>Webanalyse<br />Um das Besucherverhalten zu analysieren setzten wir Google Analytics ein. Die Webanalyse erfolgt dabei pseudonym, das heißt wir können aus den Informationen keine unmittelbaren Rückschlüsse zu Ihnen herbeiführen. Wenn Sie nicht möchten, dass Daten von Google Analytics erfasst werden, können Sie im Webbrowser ein Abmeldemodul installieren (<a href='https://tools.google.com/dlpage/gaoptout?hl=None' rel="noopener noreferrer" target='_blank'>Deaktivierung Google Analytics</a>).</div>
    ]
  },
  {
    title: 'Weitergabe von Informationen',
    children: [
      'archicloud veröffentlicht keine persönlichen Informationen der Nutzer, wenn wir nicht in Textform die ausdrückliche Genehmigung dazu erhalten haben oder gesetzliche Auskunftspflichten dazu bestehen.',
      'Selbstverständlich werden persönlichen Daten oder die in Ihrem archicloud-Account gespeicherten Daten weder für werbliche noch sonstige Zwecke ausgewertet. Wir werden Daten nie verkaufen oder Dritten mitteilen. Eine Übermittlung von Daten an Dritte erfolgt lediglich zur Abwicklung der notwendigen Bezahlvorgänge und auch nur im jeweils erforderlichen Umfang.',
      'archicloud nimmt für die Programmierung, dem Hosting der Website und den Zahlungsvorgang Dienstleistungen Dritter in Anspruch. Die von archicloud verwendeten Server stehen ausschließlich in einem Sicherheits-Rechenzentrum in Deutschland.'
    ]
  },
  {
    title: 'Löschung von personenbezogenen Daten',
    children: [
      'Der Nutzer kann den Vertrag für die Nutzung von archicloud jederzeit unter dem Menüpunkt „Einstellungen/Kündigen“ kündigen. Nach Beendigung des Vertragsverhältnisses erfolgt, mit einer Frist von einem Monat, nach Kündigung eine Löschung der personenbezogenen Daten, soweit keine gesetzlichen Aufbewahrungspflichten bestehen.'
    ]
  },
  {
    title: 'Sicherheitsmaßnahmen',
    children: [
      'Wir empfehlen allen Nutzern, ihr Passwort in regelmäßigen Abständen zu wechseln.',
      'Wir empfehlen allen Nutzern, sich nach der Arbeit von Ihrem archicloud-Account abzumelden.'
    ]
  },
  {
    title: 'Auskunftsrecht',
    children: [
      'Sie können auf bestimmte Daten, die wir über Sie gespeichert haben, über die Account-Einstellungen zugreifen und sie verändern. Des Weiteren hat jeder Nutzer die Möglichkeit, einmal pro Jahr, Auskunft über personenbezogene Daten zu erhalten. Nach Anfrage per E-Mail oder Post antworten wir innerhalb angemessener Frist nach Identitätsnachweis.'
    ]
  },
  {
    title: 'Änderungen',
    children: [
      <div>
        Ändern sich die Datenschutzbestimmungen grundlegend, verständigen wir alle Anwender von archicloud per E-Mail. Eine aktuelle Version der Datenschutzhinweise finden Sie stets unter dem <a href={DatenschutzPdf}>Link Datenschutz</a>.
      </div>
    ]
  },
  {
    title: 'Weitere Informationen',
    children: [
      <div>Wenn Sie Fragen haben, die Ihnen diese Datenschutzerklärung nicht beantworten kann oder wenn Sie zu einem Punkt ausführlichere Informationen wünschen, können Sie sich jederzeit per E-Mail ({archicloudEmail}) an uns wenden. archicloud ist berechtigt, die Datenschutzrichtlinie zu ändern, soweit dies erforderlich ist. Werden an den Hinweisen zum Datenschutz wesentliche Änderungen vorgenommen, werden Sie per E-Mail informiert.</div>
    ]
  }
]

// Enumerate sections

sections.map((s, i) => s.key = i+1)

export default sections