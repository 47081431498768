import cx from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Button, ContentWrapper } from 'components/common/StyledComponents';
import Modal from './Modal';

class ActiveModal extends Component {
  componentDidMount() {
    const { actionButton, dismissButton } = this;
    if (actionButton) {
      actionButton.focus();
    } else if (dismissButton) {
      dismissButton.focus();
    }
  }

  handleActionClick = () => {
    this.props.onClose();
    this.props.data.onAction();
  };

  renderControls = () => {
    const { dismissButton, actionButton, onAction, inlineControls } = this.props.data;
    const showActionBtn = actionButton && onAction instanceof Function;

    return (
      <div className={cx('modal-controls', { 'modal-controls-inline': inlineControls })}>
        {dismissButton && (
          <Button ref={(btn) => (this.dismissButton = btn)} wide onClick={this.props.onClose}>
            {dismissButton}
          </Button>
        )}
        {showActionBtn && (
          <Button ref={(btn) => (this.actionButton = btn)} wide onClick={this.handleActionClick}>
            {actionButton}
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { data, onClose } = this.props;

    return (
      <Modal title={data.title} isOpen={true} onClose={onClose}>
        <ContentWrapper>{data.text}</ContentWrapper>
        {this.renderControls()}
      </Modal>
    );
  }
}

ActiveModal.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActiveModal;
