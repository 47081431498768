import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addNotification } from 'actions/notificationActions';
import { updateUserPassword } from 'actions/userActions';
import { createLocalForm } from 'components/Form';
import InputField from 'components/common/InputField';
import { Button } from 'components/common/StyledComponents';
import { getErrorMessage, getErrorObject } from 'modules/errorHandler';
import getPasswordRules from 'modules/validations/passwordRules';

class UpdatePasswordTab extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      errors: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props.validate();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { fields, updateUserPassword, addNotification, resetForm } = this.props;

    if (this.props.validate()) {
      this.setState({ isLoading: true });

      updateUserPassword(fields)
        .then((res) => {
          this.setState({
            isLoading: false,
            errors: {},
          });

          resetForm();

          addNotification({
            type: 'success',
            text: 'Dein Kennwort wurde erfolgreich aktualisiert',
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });

          getErrorMessage(err, (text) => addNotification({ type: 'error', text }));
          getErrorObject(err, (errors) => this.setState({ errors }));
        });
    }
  };

  render() {
    const { fields, onBlur, onChange, isInvalid } = this.props;
    const { password, passwordConfirmation, oldPassword } = fields;

    // Merge frontend and backend validation errors
    const errors = { ...this.props.errors, ...this.state.errors };

    return (
      <form onSubmit={this.onSubmit}>
        <InputField
          margin={4}
          error={errors.oldPassword}
          label="Altes Kennwort"
          onBlur={onBlur}
          onChange={onChange}
          value={oldPassword}
          name="oldPassword"
          type="password"
          placeholder="Altes Kennwort eingeben"
        />

        <InputField
          error={errors.password}
          label="Neues Kennwort"
          onBlur={onBlur}
          onChange={onChange}
          value={password}
          name="password"
          type="password"
          placeholder="Neues Kennwort eingeben"
        />

        <InputField
          margin={2}
          error={errors.passwordConfirmation}
          onBlur={onBlur}
          onChange={onChange}
          value={passwordConfirmation}
          name="passwordConfirmation"
          type="password"
          placeholder="Neues Kennwort bestätigen"
        />

        <Button disabled={isInvalid || this.state.isLoading} wide>
          Kennwort ändern
        </Button>
      </form>
    );
  }
}

const UpdatePasswordForm = createLocalForm({
  fields: ['oldPassword', 'password', 'passwordConfirmation'],
  validationRules: getPasswordRules('update'),
})(UpdatePasswordTab);

export default connect(null, { updateUserPassword, addNotification })(UpdatePasswordForm);
