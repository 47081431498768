import styled, { keyframes } from 'styled-components';
import vars from 'variables';

const pulse = keyframes`
  from {
    transform: scale(0.0);
  }

  to {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const Spinner = styled.div`
  width: ${props => props.size ? `${props.size}` : 25}px;
  height: ${props => props.size ? `${props.size}` : 25}px;
  background-color: #333;
  border-radius: 100%;
  animation: ${pulse} 1.0s infinite ease-in-out;
  margin: ${props => props.centered ? '10px auto' : 0};
  position: ${props => props.absoluteCentered ? 'absolute' : 'relative'};
  top: ${props => props.absoluteCentered ? `calc(50% - ${vars.main.headerHeight/2}px)` : 'auto'};
  left: ${props => props.absoluteCentered ? '50%' : 'auto'};
`;

export default Spinner;
