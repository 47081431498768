import React from 'react';
import createSVG from './createSVG';

const InfoIcon = ({ style, color = '#7D7D7D' }) =>
  <g fill="none" fillRule="evenodd">
    <path
      style={style}
      d="M12.8 24.94c6.5 0 11.76-5.26 11.76-11.75 0-6.5-5.26-11.77-11.75-11.77C6.34 1.43 1.08 6.7 1.08 13.2c0 6.48 5.26 11.74 11.75 11.74zm0-2.88V9.56v12.5z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      style={style}
      d="M12.8 7.3c.84 0 1.5-.66 1.5-1.5 0-.82-.66-1.5-1.5-1.5-.82 0-1.5.68-1.5 1.5 0 .84.68 1.5 1.5 1.5"
      fill={color}
    />
  </g>;

export default createSVG(InfoIcon);
