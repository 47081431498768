import React from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { DeleteIcon, DownloadIcon, PropertiesIcon, ReplaceIcon, FavoritesIcon } from 'components/icons/';
import { SliderControlsRow } from './sliderStyles';
import downloadItems from 'modules/downloadItems';
import { addNotification } from 'actions/notificationActions';
import { getFavoritesGroup } from '../../selectors'
import vars from 'variables';

const SliderControls = ({ item, addNotification, canEdit, refreshToken, favoritesGroup }) => {
  
  const handleDownload = () => {
    downloadItems([item.id], refreshToken);
  }
  
  const addToFavorites = () => {
    addNotification({
      type: 'ADD_TO_FAVORITES',
      modal: true,
      itemId: item.id
    });
  }
  
  if (!canEdit) {
    return (
      <SliderControlsRow className='slider-controls'>
        <button data-rh="Download" data-rh-at="bottom" onClick={item.hasFile ? handleDownload : undefined}>
          <DownloadIcon stroke={item.hasFile ? '#fff' : vars.colors.lightestGray} />
        </button>
        <Link data-rh="Eigenschaften" data-rh-at="bottom" to={`/action/${item.id}/properties`}>
          <PropertiesIcon stroke='#fff' />
        </Link>
      </SliderControlsRow>
    )
  }

  const addedToFavorites = item.secondaryFilters.indexOf(String(favoritesGroup.id)) > -1;

  return (
    <SliderControlsRow className='slider-controls'>
      <Link data-rh="Vorschaubild ersetzen" data-rh-at="bottom" to={`/action/${item.id}/thumbnail`}>
        <ReplaceIcon stroke='#fff' />
      </Link>
      <Link data-rh="Löschen" data-rh-at="bottom" to={`/action/${item.id}/delete`}>
        <DeleteIcon stroke='#fff' />
      </Link>
      <button data-rh="Download" data-rh-at="bottom" onClick={item.hasFile ? handleDownload : undefined}>
        <DownloadIcon stroke={item.hasFile ? '#fff' : vars.colors.lightestGray} />
      </button>
      <Link data-rh="Eigenschaften" data-rh-at="bottom" to={`/action/${item.id}/properties`}>
        <PropertiesIcon stroke='#fff' />
      </Link>
      <button data-rh="Zu Favoriten hinzufügen" data-rh-at="bottom" onClick={addToFavorites}>
        <FavoritesIcon fill={addedToFavorites ? '#fff' : 'none'} stroke='#fff' />
      </button>
    </SliderControlsRow>
  );
}

const mapStateToProps = (state) => ({
  canEdit: state.auth.user.canEdit,
  refreshToken: state.auth.refresh.token,
  favoritesGroup: getFavoritesGroup(state)
})

export default withRouter(connect(mapStateToProps, {addNotification})(SliderControls));