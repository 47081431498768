const STATE_KEY = 'state'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STATE_KEY);
    return (serializedState === null) ? undefined : JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STATE_KEY, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};