import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addNotification } from 'actions/notificationActions';
import { getTeamUsers } from 'actions/teamActions';
import { getUserSettings, updateUserSettings } from 'actions/userActions';
import { Col, Container } from 'components/common/Grid';
import InfoField from 'components/common/InfoField';
import SelectField from 'components/common/SelectField';
import { expiryTimeOptions } from 'config';
import UserTable from './UserTable';

import CreateUser from './popups/CreateUser';
import DeleteUser from './popups/DeleteUser';
import EditSingleUser from './popups/EditSingleUser';
import EditTeamUser from './popups/EditTeamUser';

class UserTab extends Component {
  constructor() {
    super();

    this.state = {
      expiryTime: 240,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getTeamUsers();
  }

  componentDidMount() {
    this.props.getUserSettings().then((res) => {
      const { settings } = res.data;
      if (!isEmpty(settings) && settings.expiryTime) {
        this.setState({ expiryTime: settings.expiryTime });
      }
    });
  }

  onExpiryTimeChange = (e) => {
    const data = { [e.target.name]: e.target.value };
    this.setState(data);

    this.props.updateUserSettings(data);
  };

  renderExpiryTimeField = () => {
    // Only pro users get to change token expiry time
    if (!this.props.user.hasProAccount) return null;

    return (
      <SelectField
        formClassName="form-group--mt"
        label="Automatisches Abmelden nach:"
        name="expiryTime"
        onChange={this.onExpiryTimeChange}
        options={expiryTimeOptions}
        value={this.state.expiryTime}
      />
    );
  };

  render() {
    const { user } = this.props;

    // archicloud Free users only get to change their own data
    if (!user.hasProAccount) {
      return (
        <div>
          <InfoField margin={2} label="E-Mail-Adresse">
            {user.email}
          </InfoField>
          <EditSingleUser modalTitle="Nutzer ändern" showTrigger={true} />
          {this.renderExpiryTimeField()}
        </div>
      );
    }
    // Non-admin archicloud Pro users can only view the list of users
    if (user.accountType.indexOf('admin') === -1) {
      return <UserTable />;
    }

    return (
      <div>
        <UserTable />
        <Container className="mb-mobile padded">
          <Col sm={4}>
            <EditTeamUser modalTitle="Nutzer ändern" showTrigger={true} />
          </Col>
          <Col sm={4}>
            <CreateUser modalTitle="Nutzer hinzufügen" showTrigger={true} />
          </Col>
          <Col sm={4}>
            <DeleteUser modalTitle="Nutzer entfernen" showTrigger={true} />
          </Col>
        </Container>
        {this.renderExpiryTimeField()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  addNotification,
  updateUserSettings,
  getUserSettings,
  getTeamUsers,
})(UserTab);
