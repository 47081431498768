import axios from 'axios';
import { addNotification } from './notificationActions';

export const SAVE_FILTERS = 'SAVE_FILTERS/filters';
export const ADD_FILTER = 'ADD_FILTER/filters';
export const DELETE_FILTERS = 'DELETE_FILTERS/filters';
export const SET_PRIMARY_FILTER = 'SET_PRIMARY_FILTER/filters';
export const SET_SECONDARY_FILTERS = 'SET_SECONDARY_FILTERS/filters';
export const SELECT_FILTERS = 'SELECT_FILTERS/filters';
export const DESELECT_FILTERS = 'DESELECT_FILTERS/filters';
export const SELECT_FILTER_RECURSIVELY = 'SELECT_FILTER_RECURSIVELY/filters';
export const TOGGLE_FILTER = 'TOGGLE_FILTER/filters';
export const RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS/filters';
export const RESET_FILTERS_STATE = 'RESET_FILTERS_STATE/filters';
export const RESTORE_FILTERS_STATE = 'RESTORE_FILTERS_STATE/filters';

export const saveFiltersToState = filters => ({
  type: SAVE_FILTERS,
  filters
})

export const addFilterToState = ({ filter, parentId }) => ({
  type: ADD_FILTER,
  filter,
  parentId
})

export const deleteFiltersFromState = (ids, parentId) => ({
  type: DELETE_FILTERS,
  ids,
  parentId
})

export const setPrimaryFilter = (id = null) => ({
  type: SET_PRIMARY_FILTER,
  id
})

export const setSecondaryFilters = (ids = []) => ({
  type: SET_SECONDARY_FILTERS,
  ids
})

export const selectFilters = ids => ({
  type: SELECT_FILTERS,
  ids
})

export const deselectFilters = ids => ({
  type: DESELECT_FILTERS,
  ids
})

export const selectFilterRecursively = ({ filterId, secondaryFilterId, primaryFilterId }) => ({
  type: SELECT_FILTER_RECURSIVELY,
  filterId,
  secondaryFilterId,
  primaryFilterId
})

export const toggleFilter = id => ({
  type: TOGGLE_FILTER,
  id
})

export const resetSelectedFilters = () => ({
  type: RESET_SELECTED_FILTERS
})

export const restoreFiltersState = ({ selected, primary, secondary, halfSelected }) => ({
  type: RESTORE_FILTERS_STATE,
  selected,
  halfSelected,
  primary,
  secondary
})

export const resetFiltersState = () => ({
  type: RESET_FILTERS_STATE
})

export const fetchFilters = () => (
  dispatch => {
    return axios.get('/api/filters')
    .then(res => {
      dispatch(saveFiltersToState(res.data.filters))
    })
    .catch(err => {
      const message = err.response ? err.response.data.message : err.message;
      
      dispatch(addNotification({
        type: 'error',
        text: message || 'Ein unbekannter Fehler ist aufgetreten'
      }))
    });
  }
)

export const addNewFilter = data => (
  dispatch => {
    return axios.post('/api/filters', data)
    .then(res =>
      dispatch(addFilterToState({
        filter: res.data.newFilter,
        parentId: res.data.newFilter.parent_id
      }))
    )
  }
)

export const deleteFilters = (ids, parentId) => (
  dispatch => {
    return axios.all(ids.map(id => axios.delete(`/api/filters/${id}`)))
    .then(res => {
      // Remove successfully deleted filters from state
      dispatch(deleteFiltersFromState(ids, parentId))
      
      // Deselect deleted filters (if any were selected)
      dispatch(deselectFilters(ids))
      
      // Forward the result
      return res;
    })
    .catch(err => {
      const message = err.response ? err.response.data.message : err.message;
      
      dispatch(addNotification({
        type: 'error',
        text: message || 'Ein unbekannter Fehler ist beim Löschen aufgetreten'
      }))
      
      // Forward the reject
      return Promise.reject();
    }) 
  }
)
