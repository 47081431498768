import base64ToBlob from './base64ToBlob'
import isDataUrl from './isDataUrl'

const loadImage = (src, cb) => {
	let image = new Image();
	if (!isDataUrl(src)) {
		image.setAttribute('crossOrigin', 'anonymous')
	}
  image.onload = () => {
		cb(image);
		image = null;
	};
	image.src = src;
}

const cropImage = (imgSrc, crop) => {
  return new Promise((resolve, reject) => {
    loadImage(imgSrc, loadedImg => {
      if (!loadedImg) return reject(new Error('Could not load image.'))
      
      const imageWidth = loadedImg.naturalWidth;
  		const imageHeight = loadedImg.naturalHeight;

  		const cropX = (crop.x / 100) * imageWidth;
  		const cropY = (crop.y / 100) * imageHeight;

  		const cropWidth = (crop.width / 100) * imageWidth;
  		const cropHeight = (crop.height / 100) * imageHeight;

			// Create a canvas with desired params
  		const canvas = document.createElement('canvas');
  		canvas.width = cropWidth;
  		canvas.height = cropHeight;
  		
      const ctx = canvas.getContext('2d');
			
			// Create a rectangle a white background 
			// This fixes black background of transparent PNGs
			ctx.fillStyle = '#fff';
			ctx.fillRect(0, 0, cropWidth, cropHeight);

			// Draw the image itself
  		ctx.drawImage(loadedImg, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
      
      return resolve(canvas.toDataURL('image/jpeg'))
    });
  })
}

export const cropToFile = async (image, crop, name = 'image') => {
	const base64 = await cropImage(image, crop)
	return base64ToBlob(base64)
}

export default cropImage