import React from 'react'

import { SectionChildren, SearchableContainer, Button } from 'components/common/StyledComponents'
import { SimpleAccordion as Accordion, Panel } from 'components/common/Accordion'
import createSearchable from 'components/common/createSearchable'
import termsConditionsData from './termsConditionsData'
import AgbPdf from 'documents/Agb.pdf'

const TermsConditionsView = ({ activeKey, content, onAccordionChange }) =>  {  
  return (
    <div>
      <SearchableContainer className='searchableContainer'>
        <Accordion activeKey={activeKey} onChange={onAccordionChange}>
          {content.map(({ key, title, children }) => (
            <Panel key={key} header={`${key}.  ${title}`} className='section-header'>
              <SectionChildren className='with-border'>
                {children.map((item, i) => <li key={i}>{item}</li>)}
              </SectionChildren>
            </Panel>
          ))}
        </Accordion>
      </SearchableContainer>
      <a href={AgbPdf} rel="noopener noreferrer" target='_blank'><Button wide>AGB als PDF öffnen</Button></a>
    </div>
  );
}

export default createSearchable({
  title: 'AGB',
  data: termsConditionsData
})(TermsConditionsView);
