import { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { StepNavBar, StepNavItem, Wrapper } from 'components/common/StyledComponents';
import CustomerTable from './CustomerTable';

class CustomerManagement extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Kundenverwaltung</title>
        </Helmet>
        <Wrapper fixed fixedWidth="1200px">
          <StepNavBar>
            <StepNavItem>
              <span>Kundenverwaltung</span>
            </StepNavItem>
          </StepNavBar>
          <CustomerTable />
        </Wrapper>
      </div>
    );
  }
}

export default CustomerManagement;
