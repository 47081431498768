import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, FormGroup } from 'components/common/StyledComponents';
import InputField from 'components/common/InputField';
import { searchByISBN } from 'actions/formActions';
import { FORM_KEY } from './ImportView';
import { addNotification } from 'actions/notificationActions';
import { createForm } from 'components/Form'

class AddLiteratureDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }
  
  onChange = e => {
    this.props.onChange({ [e.target.name]: e.target.value });
  }
  
  searchBooks = e => {
    e.preventDefault();
    const { searchByISBN, onChange, addNotification } = this.props;
    const { isbn } = this.props.fields
    
    this.setState({ isLoading: true });
  
    searchByISBN(isbn)
    .then(res => {
      this.setState({ isLoading: false })
      onChange(res);
    })
    .catch(err => {
      addNotification({
        modal: true,
        type: 'error',
        title: 'Keine Infos vorhanden',
        text: 'Zur abgefragten ISBN-Nummer sind keine Informationen vorhanden.',
        dismissButton: 'Ok'
      })
      this.setState({ isLoading: false })
    });
  }
  
  render() {
    const { isLoading } = this.state;
    const { isbn, title, author, publisher, year } = this.props.fields;
        
    return (
      <form onSubmit={this.searchBooks}>
        <InputField
          onChange={this.onChange}
          value={isbn}
          name="isbn"
          placeholder="ISBN eingeben"
        />
        
        <FormGroup>
          <Button onClick={this.searchBooks} wide disabled={isLoading || !this.props.fields.isbn}>
            Infos über ISBN jetzt suchen
          </Button>
        </FormGroup>
        
        <InputField
          formClassName='form-group--stack'
          onChange={this.onChange}
          value={title}
          name="title"
          placeholder="Titel"
        />
        <InputField
          formClassName='form-group--stack'
          onChange={this.onChange}
          value={author}
          name="author"
          placeholder="Autor"
        />
        <InputField
          formClassName='form-group--stack'
          onChange={this.onChange}
          value={publisher}
          name="publisher"
          placeholder="Verlag"
        />
        <InputField
          formClassName='form-group--stack'
          onChange={this.onChange}
          value={year}
          name="year"
          type="number"
          placeholder="Erscheinungsjahr"
        />
      </form>
    );
  }
}

const connectedComponent = connect(null, {searchByISBN, addNotification})(AddLiteratureDetails);

export default createForm({
  formKey: FORM_KEY,
  fields: ['isbn', 'title', 'author', 'publisher', 'year']
})(connectedComponent)