import { showLoading, hideLoading } from 'components/LoadingBar';
import axios from 'axios';

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA/form';
export const UPDATE_FORM_STATUS = 'UPDATE_FORM_STATUS/form';
export const RESET_FORM_STATE = 'RESET_FORM_STATE/form';

export const updateFormData = (formId, data) => ({
  type: UPDATE_FORM_DATA,
  formId,
  data
})

export const updateFormStatus = (formId, stepId, status) => ({
  type: UPDATE_FORM_STATUS,
  formId,
  stepId,
  status
})

export const resetFormState = formId => ({
  type: RESET_FORM_STATE,
  formId
})

export const searchByISBN = (input = '') => (
  dispatch => {    
    // Remove everything except numbers from the input ISBN
    const isbn = input.replace(/[^0-9]/g, '');

    dispatch(showLoading());
    
    return axios.get(`/api/books/${isbn}`)
    .then(res => {
      dispatch(hideLoading());
      return Promise.resolve(res.data.bookDetails)
    })
    .catch(err => {
      dispatch(hideLoading());
      return Promise.reject(err) 
    })
  }
)