import React from "react";
import FilterList from "components/Filter/FilterList";
import SearchDropdown from "components/SearchDropdown";

const SidebarFilters = props => (
  <div>
    <SearchDropdown />
    <FilterList
      reverseSelectors={true}
      selectable={true}
      editable={false}
      usedFiltersOnly={true}
    />
  </div>
);

export default SidebarFilters;
