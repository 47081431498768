import { Helmet } from 'react-helmet-async';

import MultiStep from 'components/MultiStep';
import { SplitPaneWrapper, Wrapper } from 'components/common/StyledComponents';
import PasswordTab from './PasswordTab';
import UserTab from './UserTab';

const UserSettings = (props) => {
  const steps = [
    {
      name: 'Nutzer',
      component: <UserTab />,
    },
    {
      name: 'Kennwort',
      component: <PasswordTab />,
    },
  ];

  return (
    <SplitPaneWrapper>
      <Helmet>
        <title>Nutzerverwaltung</title>
      </Helmet>
      <Wrapper fixed fixedWidth="800px">
        <MultiStep isAlwaysValid={true} steps={steps} />
      </Wrapper>
    </SplitPaneWrapper>
  );
};

export default UserSettings;
