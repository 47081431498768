import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import createModal from 'components/Modal/createModal';
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import { deleteFilters } from 'actions/filterActions';
import SelectList from '../SelectList';
import { toggle } from 'utils/common';

class DeleteFilter extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: false,
      isInvalid: true,
      selected: []
    }
  }
  
  onChange = id => {
    const selected = toggle(this.state.selected, id)
    
    this.setState({
      selected,
      isInvalid: isEmpty(selected)
    });
  }
  
  onClick(e) {
    e.preventDefault();
    const { selected } = this.state;
    const { options, deleteFilters, parentId, onSuccess } = this.props;
    
    if (!isEmpty(selected)) {
      this.setState({
        isLoading: true,
        isInvalid: false,
        selected: []
      });

      deleteFilters(selected, parentId)
      .then(() => {
        // If not all options have been deleted close the modal
        // -> otherwise we might end up changing state of an unmounted component
        if (options.length > selected.length) {
          this.setState({ isLoading: false });
          if (onSuccess) onSuccess()
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, isInvalid: true });
      });
    }
  }
  
  render() {
    const { isLoading, isInvalid, selected } = this.state;
    const { options, searchable } = this.props;
    
    return (
      <div>
        <ContentWrapper>
          <SelectList
            searchable={searchable}
            onChange={this.onChange}
            selected={selected}
            useLocalState={true}
            itemClassName='alt'
            options={options}
          />
        </ContentWrapper>
        <Button onClick={e => this.onClick(e)} wide disabled={isLoading || isInvalid}>Löschen</Button>
      </div>
    );
  }
}

DeleteFilter.propTypes = {
  options: PropTypes.array.isRequired,
  deleteFilters: PropTypes.func.isRequired,
  parentId: PropTypes.number.isRequired,
  searchable: PropTypes.bool,
  onSuccess: PropTypes.func
}

const DeleteFilterModal = createModal(DeleteFilter)

export default connect(null, {deleteFilters})(DeleteFilterModal);
