import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { updateFormData, updateFormStatus } from 'actions/formActions';
import SelectField from 'components/common/SelectField';
import { ControlsRow } from 'components/common/StyledComponents';
import { getPossibleLocations } from 'selectors';
import DropFile from './DropFile';

class StepTwo extends Component {
  UNSAFE_componentWillMount() {
    const { locations, updateFormData, itemType } = this.props;

    if (!itemType) {
      updateFormData({ itemType: 'digital' });
    }

    updateFormData({
      location: locations.length > 0 ? locations[0] : '',
    });
  }

  UNSAFE_componentWillReceiveProps({ itemType }) {
    const { updateFormStatus, id, file } = this.props;

    if (itemType !== this.props.itemType) {
      if (itemType === 'analog') {
        updateFormStatus(id, true);
      } else {
        updateFormStatus(id, !isEmpty(file));
      }
    }
  }

  onSelectChange = (e) => {
    const data = { [e.target.name]: e.target.value };
    this.props.updateFormData(data);
  };

  onDrop = (file) => {
    const { updateFormStatus, updateFormData, id, thumbnail } = this.props;
    updateFormStatus(id, true);
    updateFormData({ file });

    // If current it's an image or a pdf file, use the file as a thumbnail as well
    // ... if no thumbnail has been uploaded/selected/fetched yet
    if (!thumbnail && (file.type.match(/^image/) || file.type.match(/pdf/))) {
      updateFormStatus(id + 1, true);
      updateFormData({ thumbnail: file });
    }
  };

  renderItemSelector() {
    const { locations, itemType, location } = this.props;

    if (itemType === 'digital') {
      return <DropFile file={this.props.file} onChange={this.onDrop} />;
    }
    return (
      <SelectField
        formClassName="form-group--select"
        onChange={this.onSelectChange}
        label="Standort"
        moreInfo="/faq/6:i"
        name="location"
        options={locations}
        value={location}
        disabled={locations.length === 0}
        placeholder={
          locations.length === 0 ? 'Dem Objekt wurde kein passender Filter zugewiesen.' : undefined
        }
      />
    );
  }

  render() {
    const { controls, primaryFilter, itemType } = this.props;

    return (
      <div>
        {primaryFilter.name === 'Literatur' && (
          <SelectField
            formClassName="form-group--select"
            onChange={this.onSelectChange}
            label="Format"
            moreInfo="/faq/6:h"
            name="itemType"
            options={['analog', 'digital']}
            value={itemType}
          />
        )}
        {this.renderItemSelector()}
        <ControlsRow>{controls}</ControlsRow>
      </div>
    );
  }
}

StepTwo.propTypes = {
  updateFormStatus: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  file: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  controls: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  const { primary, items } = state.filters;
  const { data = {} } = state.form[ownProps.formId];
  const { file, thumbnail, location, itemType } = data;

  return {
    primaryFilter: primary ? items[primary] : null,
    locations: getPossibleLocations(state),
    file,
    thumbnail,
    location,
    itemType,
  };
};

const mapDispatchToProps = (dispatch, { formId }) => ({
  updateFormData: (data) => {
    dispatch(updateFormData(formId, data));
  },
  updateFormStatus: (stepId, status) => {
    dispatch(updateFormStatus(formId, stepId, status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
