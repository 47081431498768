import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';

import { toggleSidebar } from 'actions/sidebarActions';
import { PreviewIcon, DeleteIcon, DownloadIcon, PropertiesIcon } from 'components/icons/';
import downloadItems from 'modules/downloadItems';

const SidebarLibrary = ({ toggleSidebar, selected, items, canEdit, refreshToken }) => {

  const onClick = () => {    
    toggleSidebar();
  }
  
  const handleDownload = () => {
    downloadItems(selected, refreshToken)
  }
  
  const ids = selected.join()
  const downloadable = items.some(i => i.hasFile)
  
  return (
    <div>
      <div className='ListItem'>
        <Link onClick={onClick} to={`/action/${ids}/preview`}>
          <PreviewIcon />
          <span>Vorschau</span>
        </Link>
      </div>
      {canEdit &&
        <div className='ListItem'>
          <Link onClick={onClick} to={`/action/${ids}/delete`}>
            <DeleteIcon />
            <span>Löschen</span>
          </Link>
        </div>}
      <div
        className={cx('ListItem', {'unavailable': !downloadable})}
        onClick={downloadable ? handleDownload : undefined}>
        <DownloadIcon />
        <span>Download</span>  
      </div>
      <div className='ListItem'>
        <Link onClick={onClick} to={`/action/${ids}/properties`}>
          <PropertiesIcon />
          <span>Eigenschaften</span>
        </Link>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth, library }) => ({
  items: library.items.filter(i => library.selected.indexOf(i.id) > -1),
  selected: library.selected,
  canEdit: auth.user.canEdit,
  refreshToken: auth.refresh.token
})

export default withRouter(connect(mapStateToProps, {toggleSidebar})(SidebarLibrary));
