import React from 'react';
import { scroller } from 'react-scroll';

import LoginForm from 'components/LoginForm/LoginForm';
import { ArrowIcon } from 'components/icons/';
import { Feature, FeatureContent, FeatureHeader, ScrollButton } from './frontStyles';

const GuestFrontView = () => {
  const handleScrollTo = (e) => {
    e.preventDefault();
    scroller.scrollTo('FindWhatYouLookFoor', {
      smooth: true,
      duration: 500,
    });
  };

  const Arrow = <ArrowIcon stroke="#fff" height={12} width={12} viewBox="0 0 16 16" />;

  return (
    <div className="front-page-wrapper">
      <Feature>
        <FeatureContent>
          <FeatureHeader>
            <h1>Ein Ort, alle Bibliotheken.</h1>
            <p>Fantastische Arbeiten beginnen mit hervorragenden Grundlagen.</p>
          </FeatureHeader>
          <LoginForm />
        </FeatureContent>
        <ScrollButton onClick={handleScrollTo}>{Arrow}</ScrollButton>
      </Feature>
    </div>
  );
};

export default GuestFrontView;
