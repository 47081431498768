import { toggle, addItems } from 'utils/common';
import { isPlainObject } from 'lodash';

import {
  ADD_ITEM_TO_LIBRARY,
  ADD_ITEMS_TO_LIBRARY,
  DELETE_ITEMS_FROM_LIBRARY,
  TOGGLE_LIBRARY_ITEM,
  SELECT_LIBRARY_ITEMS,
  APPEND_SELECTED_LIBRARY_ITEMS,
  DESELECT_LIBRARY_ITEMS,
  UPDATE_ITEM_STATE
} from 'actions/libraryActions';

const initialState = {
  items: [],
  selected: []
};

const updateItem = (state = {}, action) => {
  const { details, filters, ...rest } = action.data;
  let newState = state;

  // If item's details have been updated, merge old and new contents
  if (isPlainObject(details)) {
    newState = {
      ...newState,
      details: { ...newState.details, ...details }
    };
  }
  // Update filters whenever needed
  if (filters) {
    newState = { ...newState, filters };
  }
  return {
    ...newState,
    ...rest
  };
};

const items = (state = [], action) => {
  switch (action.type) {
    case ADD_ITEM_TO_LIBRARY:
      return [action.item, ...state];

    case ADD_ITEMS_TO_LIBRARY:
      // Remove duplicates with the same id
      // Duplicates may occur if recently imported items are fetched from the server
      return addItems(state, action.items).filter(
        (item, index, self) => self.findIndex(i => i.id === item.id) === index
      );

    case DELETE_ITEMS_FROM_LIBRARY:
      if (action.itemIds) {
        return state.filter(i => action.itemIds.indexOf(i.id) === -1);
      }

      return state;

    case UPDATE_ITEM_STATE:
      return state.map(
        item => (item.id === action.id ? updateItem(item, action) : item)
      );

    default:
      return state;
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_ITEM_TO_LIBRARY:
      return {
        ...state,
        items: items(state.items, action)
      };
    case ADD_ITEMS_TO_LIBRARY:
      return {
        ...state,
        items: items(state.items, action)
      };
    case DELETE_ITEMS_FROM_LIBRARY:
      return {
        ...state,
        items: items(state.items, action)
      };
    case TOGGLE_LIBRARY_ITEM:
      return {
        ...state,
        selected: toggle(state.selected, action.itemId)
      };
    case SELECT_LIBRARY_ITEMS:
      if (!action.itemIds || action.itemIds.length === 0) return state;

      return {
        ...state,
        selected: action.itemIds.filter(id => id)
      };
    case APPEND_SELECTED_LIBRARY_ITEMS:
      if (!action.itemIds || action.itemIds.length === 0) return state;

      // Filter out undefined or null values, dedupe
      const selectedIds = action.itemIds.filter(
        id => id && state.selected.indexOf(id) === -1
      );

      return {
        ...state,
        selected: [...state.selected, ...selectedIds]
      };
    case DESELECT_LIBRARY_ITEMS:
      return {
        ...state,
        selected: initialState.selected
      };
    case UPDATE_ITEM_STATE:
      return {
        ...state,
        items: items(state.items, action)
      };
    default:
      return state;
  }
};
