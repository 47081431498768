import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Geosuggest from 'react-geosuggest';
import styled from 'styled-components';

import { GOOGLE_API_KEY } from 'config';
import vars from 'variables';
import Spinner from './Spinner';
import { FormGroup } from './StyledComponents';

const { lightGray, lightestGray } = vars.colors;

const StyledGeoSuggest = styled(Geosuggest)`
  font-size: 0.85rem;
  position: relative;
  text-align: left;

  .geosuggest__input {
    width: 100%;
    border: 1px solid ${lightGray};
    background: ${lightestGray};
    padding: 3px 20px;
    line-height: 1.3;
    transition: border 0.2s;
    outline: none;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -2px;
    background: #fff;
    border: 1px solid ${lightGray};
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    transition: max-height 0.2s, border 0.2s;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    cursor: pointer;
    padding: 5px 20px;
    line-height: 1.3;
    &:hover,
    &:focus,
    &.geosuggest__item--active {
      background: ${lightestGray};
    }
  }
`;

const GEO_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`;

const GeoInputField = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const geoSuggest = useRef(null);

  useEffect(() => {
    const onLoad = () => setIsLoaded(true);
    if (window.google) {
      onLoad();
    } else {
      const script = document.createElement('script');
      script.src = GEO_API_URL;
      script.async = true;
      script.defer = true;
      script.onload = onLoad;
      document.head.append(script);

      return () => script.removeEventListener('load', onLoad);
    }
  }, []);

  const onSuggestSelect = (selected) => {
    const nextText = selected ? selected.label : '';
    onChange(nextText);

    // Blur the field as a workaround to github.com/ubilabs/react-geosuggest/issues/268
    if (geoSuggest.current) geoSuggest.current.blur();
  };

  if (!isLoaded) return <Spinner centered size={15} />;

  const { error, warning, formClassName, className, label, placeholder, onChange } = props;

  return (
    <FormGroup className={cx({ 'has-danger': error }, formClassName, 'form-group--input')}>
      {label && <label>{label}</label>}
      <StyledGeoSuggest
        autoFocus
        className={className}
        innerRef={geoSuggest}
        onChange={onChange}
        placeholder={placeholder}
        onSuggestSelect={onSuggestSelect}
      />
      {error && <span className="form-feedback">{error}</span>}
      {warning && <span className="form-feedback">{warning}</span>}
    </FormGroup>
  );
};

GeoInputField.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  warning: PropTypes.string,
  formClassName: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default GeoInputField;
