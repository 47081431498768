import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';

import vars from 'variables';
import SelectListItem from './SelectListItem';
import Scrollbars from 'components/common/Scrollbars';
import { Search, createFilter } from 'components/Search';

const maxHeight = vars.main.checkboxHeight*8 + vars.main.gutter*7;

const StyledSelectList = styled.div`
  max-height: ${maxHeight*8}px;
  overflow-y: auto;
  overflow-x: hidden;
  
  &.has-selected .SelectListItem:not(.is-selected):not(.is-halfSelected) {
    color: ${vars.colors.lightGray};
  }
  &.reversed:not(.has-selected) .SelectListItem.selectable:after {
    content: "+";
    right: 7.5px;
  }
`;

class SelectList extends Component {
  constructor(props) {
    super(props);
    
    this.state = { searchTerm: '' }
  }
  
  handleClick = id => {
    const { onChange } = this.props;
    if (onChange) onChange(id);
  }
  
  handleInputChange = searchTerm => {
    this.setState({ searchTerm })
  }
  
  renderOptions() {
    const { itemClassName, options, selectable, editable, selected, halfSelected } = this.props;
    const filteredOptions = options.filter(createFilter(this.state.searchTerm, 'name'));
        
    const renderedOptions = filteredOptions.map(option => (
      <SelectListItem
        className={itemClassName}
        key={option.id}
        id={option.id}
        selectable={selectable}
        editable={editable}
        onChange={this.handleClick}
        halfSelected={halfSelected && halfSelected.indexOf(option.id) !== -1}
        selected={selected && selected.indexOf(option.id) !== -1}
      >
        {option.name}
      </SelectListItem>
    ));
    
    if (options.length <= 8) return renderedOptions;
    
    const wrapperStyle = {
      marginLeft: editable ? 23 : 8
    }
    
    return (
      <Scrollbars
        borderSize={editable ? 1 : 0}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={maxHeight}>
        <div style={wrapperStyle}>{renderedOptions}</div>
      </Scrollbars>
    );    
  }

  render() {
    const { selected, halfSelected, searchable, reverseSelectors, placeholder } = this.props;
    return (
      <div>
        { searchable &&
          <Search
            gray
            placeholder={placeholder || 'Suche'}
            onChange={this.handleInputChange}
          /> }
        <StyledSelectList
          className={cx({
            'has-selected': (selected && selected.length > 0) || (halfSelected && halfSelected.length > 0),
            'reversed': reverseSelectors
          })}>
          {this.renderOptions()}
        </StyledSelectList>
      </div>
    );
  }
}

SelectList.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  selectable: PropTypes.bool,
  itemClassName: PropTypes.string,
  editable: PropTypes.bool,
  selected: PropTypes.array,
  halfSelected: PropTypes.array,
  reverseSelectors: PropTypes.bool,
  placeholder: PropTypes.string
}

SelectList.defaultProps = {
  selectable: true,
  searchable: false,
  editable: true  
}

export default SelectList;
