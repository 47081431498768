import React from 'react';
import createSVG from '../createSVG';

const Info = ({style}) => (
  <g style={style} fill="none" fillRule="evenodd">
    <path stroke="#6ED2A0" strokeWidth="3" d="M55.5 109c29.547 0 53.5-23.952 53.5-53.5C109 25.953 85.047 2 55.5 2S2 25.953 2 55.5C2 85.048 25.953 109 55.5 109zm0-18.833V41.341"/>
    <path fill="#6ED2A0" d="M55.5 29.4922c1.546 0 2.799-1.253 2.799-2.799s-1.253-2.799-2.799-2.799-2.799 1.253-2.799 2.799 1.253 2.799 2.799 2.799"/>
    <path stroke="#6ED2A0" strokeWidth="3" d="M55.5 29.4922c1.546 0 2.799-1.253 2.799-2.799s-1.253-2.799-2.799-2.799-2.799 1.253-2.799 2.799 1.253 2.799 2.799 2.799z"/>
  </g>
);

export default createSVG(Info);
