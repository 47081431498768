import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { fetchItemsById } from 'actions/itemActions';
import Spinner from 'components/common/Spinner';
import { SplitPane, SplitView, Wrapper } from 'components/common/StyledComponents';
import { getSortedItems } from 'selectors';
import {
  ItemsDelete,
  ItemsGrid,
  ItemsPreview,
  ItemsProperties,
  ManageFile,
  ReassignFilters,
  ReplaceThumbnail,
} from './SplitPanes';

const parseParams = (params) => params.split(',').map(Number);

const pageTitles = {
  preview: 'Vorschau',
  delete: 'Objekte löschen',
  properties: 'Eigenschaften',
  filters: 'Filter anpassen',
  thumbnail: 'Vorschaubild ersetzen',
  file: 'Datei ersetzen',
};

class Library extends Component {
  componentDidMount() {
    const { history, items, match, fetchItemsById } = this.props;
    const ids = parseParams(match.params.items);

    if (items.length < ids.length) {
      const idsToRequest = ids.filter((i) => items.indexOf(i) === -1);
      fetchItemsById(idsToRequest).catch((err) => history.push('/'));
    }
  }

  render() {
    const { match, location, items } = this.props;
    const { path } = match;

    if (items.length === 0) return <Spinner absoluteCentered size={30} />;

    const pageTitle = location && pageTitles[location.pathname.split('/').slice(-1)[0]];

    // Props for each of the splitpanes
    const props = { items };

    return (
      <Wrapper fluid>
        {pageTitle && (
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
        )}
        <SplitView>
          <SplitPane className="library-grid-wrapper">
            <ItemsGrid location={location} items={items} />
          </SplitPane>
          <SplitPane className="library-properties-wrapper">
            <Switch>
              <Route
                path={`${path}/preview`}
                render={(routeProps) => <ItemsPreview {...props} {...routeProps} />}
              />
              <Route
                path={`${path}/delete`}
                render={(routeProps) => <ItemsDelete {...props} {...routeProps} />}
              />
              <Route
                path={`${path}/properties`}
                render={(routeProps) => <ItemsProperties {...props} {...routeProps} />}
              />
              <Route
                path={`${path}/filters`}
                render={(routeProps) => <ReassignFilters {...props} {...routeProps} />}
              />
              <Route
                path={`${path}/thumbnail`}
                render={(routeProps) => <ReplaceThumbnail {...props} {...routeProps} />}
              />
              <Route
                path={`${path}/file`}
                render={(routeProps) => <ManageFile {...props} {...routeProps} />}
              />
            </Switch>
          </SplitPane>
        </SplitView>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const ids = parseParams(ownProps.match.params.items);
  return {
    items: getSortedItems(state).filter((i) => ids.indexOf(i.id) > -1),
  };
};

export default connect(mapStateToProps, { fetchItemsById })(Library);
