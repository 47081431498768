import LoadingBarContainer, { LoadingBar } from './loading_bar'
import loadingBarMiddleware from './loading_bar_middleware'
import {
  hideLoading,
  showLoading,
  updateLoading,
  loadingBarReducer,
} from './loading_bar_ducks'
import ImmutableLoadingBar from './immutable'

export {
  hideLoading,
  showLoading,
  updateLoading,
  ImmutableLoadingBar,
  LoadingBar,
  loadingBarMiddleware,
  loadingBarReducer
}
export default LoadingBarContainer
