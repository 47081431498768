import { difference, intersection, isEmpty, isEqual, union } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  resetFiltersState,
  resetSelectedFilters,
  restoreFiltersState,
} from 'actions/filterActions';
import { updateItemsFilters } from 'actions/itemActions';
import { addNotification } from 'actions/notificationActions';
import FilterList from 'components/Filter/FilterList';
import {
  Button,
  ControlsRow,
  HalfColumn,
  SectionTitle,
  SplitPaneWrapper,
} from 'components/common/StyledComponents';

class ReassignFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialFilters: null,
      isLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { items, restoreFiltersState, history, addNotification } = this.props;
    let primary = null,
      secondary = [],
      selected = [];

    const primaryFilterIsSame = items.every((i) => {
      if (!primary) primary = i.primaryFilterId;

      secondary.push(i.secondaryFilters);
      selected.push(i.filters);

      return primary === i.primaryFilterId;
    });

    if (!primaryFilterIsSame) {
      addNotification({
        type: 'error',
        text: 'Objekte müssen in derselben Bibliothek sein',
      });
      return history.push('/');
    }

    const init = {
      primary,
      secondary: union(...secondary),
      selected: intersection(...selected),
    };

    init.halfSelected = difference(union(...selected), init.selected);

    restoreFiltersState(init);
    this.setState({ initialFilters: init });
  }

  componentWillUnmount() {
    // Reset selected filters before unmounting
    this.props.resetFiltersState();
  }

  saveChanges = () => {
    const { selected, primary, halfSelected } = this.props.filters;
    const { items, updateItemsFilters, history } = this.props;

    if (primary) {
      this.setState({ isLoading: true });
      const ids = items.map((i) => i.id);

      updateItemsFilters(ids, {
        selected,
        halfSelected,
        primaryFilterId: primary,
      })
        .then((res) => {
          this.setState({ isLoading: false });
          addNotification({
            type: 'success',
            text: 'Die zugehörigen Filter wurden erfolgrech aktualisiert ',
          });
          history.push('/');
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleSubmit = () => {
    const { selected } = this.props.filters;
    if (!selected || selected.length === 0) {
      this.props.addNotification({
        type: 'warning',
        modal: true,
        title: 'Keine Filter zugewiesen',
        text: 'Du hast dem Objekt keine Filter zugewiesen. Ohne die Zuweisung von Filtern wird die spätere Suche nach Objekten erschwert.',
        actionButton: 'Trotzdem weiter',
        onAction: this.saveChanges,
      });
    } else {
      this.saveChanges();
    }
  };

  render() {
    const { filters, resetSelectedFilters } = this.props;
    const { initialFilters, isLoading } = this.state;
    const canSaveChanges = isEqual(initialFilters, filters) || isEmpty(filters) || isLoading;

    return (
      <SplitPaneWrapper>
        <SectionTitle>Filter anpassen</SectionTitle>
        <FilterList showLiteratureInfos={false} showHalfSelected={true} selectable={true} />
        <ControlsRow>
          <HalfColumn>
            <Button onClick={this.handleSubmit} disabled={canSaveChanges} wide>
              Änderungen speichern
            </Button>
          </HalfColumn>
          <HalfColumn>
            <Button onClick={resetSelectedFilters} wide>
              Alle Filter löschen
            </Button>
          </HalfColumn>
        </ControlsRow>
      </SplitPaneWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { primary, secondary, selected, halfSelected } = state.filters;
  return {
    filters: primary ? { primary, secondary, selected, halfSelected } : {},
  };
};

export default connect(mapStateToProps, {
  restoreFiltersState,
  resetSelectedFilters,
  resetFiltersState,
  updateItemsFilters,
  addNotification,
})(ReassignFilters);
