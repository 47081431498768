import { findIndex } from 'lodash';
import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from 'actions/notificationActions';

export default (state = [], action = {}) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const { type, text, title, modal, ...rest } = action.message;
      const notification = { type, modal, text, title, ...rest }

      // If the same notification is already in state, move on
      if (findIndex(state, notification) > -1) return state

      return [ ...state, { id: action.id, ...notification }]
      
    case DELETE_NOTIFICATION:
      return state.filter(item => item.id !== action.id)
      
    default: return state;
  }
}