import { ADD_PREFERENCE, DELETE_PREFERENCE } from 'actions/preferenceActions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_PREFERENCE:
      return {
        ...state,
        [action.key]: action.value
      };
    case DELETE_PREFERENCE:
      const { [action.key]: deleted, ...rest } = state
      return rest
      
    default: return state;
  }
}
