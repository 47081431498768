import styled from 'styled-components';
import vars from 'variables';

const ImageSlide = styled.div`
  background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20200%20200%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cg%20stroke%3D%22%237D7D7D%22%20stroke-width%3D%222.5%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M128.5%20142.04c-38.4%200-69.53-31.13-69.53-69.52C58.97%2034.12%2090.1%203%20128.5%203%20166.87%203%20198%2034.12%20198%2072.52c0%2038.4-31.13%2069.52-69.5%2069.52zM79%20121.32L1%20198.85l78-77.53z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M173.54%2072.93H82.6m45.47-45.47v90.95%22%20stroke-linejoin%3D%22round%22/%3E%0A%20%20%3C/g%3E%0A%3C/svg%3E);
  background-color: #eaeaea;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  margin: 0 auto;
  position: relative;

  &.has-thumbnail {
    background-size: cover;
  }
  
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const SlideTitle = styled.div`
  font-size: 1.125rem;
  text-align: center;
  margin: 0;
  word-wrap: break-word;
  padding: 0 1em;
`;

const SliderWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.mt ? props.mt : 0};
  margin-bottom: ${props => props.mb ? props.mb : 0};
  padding-bottom: 30px;
  width: 100%;
  
  @media (min-width: ${vars.media.md}) {
    padding-bottom: 0;
  }
  &.is-image-slider {
    padding-bottom: 0;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : 'none'};
  margin: 0 auto;
`;

const SliderInner = styled.div`
  overflow: hidden;
  margin: 0;
  
  @media (min-width: ${vars.media.sm}) {
    margin: 0 100px;
  }
  
  &.is-image-slider {
    margin: 50px 0 20px;
    @media (min-width: ${vars.media.sm}) {
      margin: 50px 80px;
    }
  }
  
  &:hover .slider-controls {
    max-height: 100%;
    visibility: visible;
  }
`;

const SliderControlsRow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all .5s ease-in-out;
  color: #fff;
  background-color: rgba(110, 210, 160, .75);
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: ${vars.media.md}) {
    visibility: hidden;
    max-height: 0;
  }

  button, a {
    padding: 8px 10px;
    font-size: 11px;
    cursor: pointer;
    outline: none;
    border: 0;
    
    @media (min-width: ${vars.media.md}) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const SlidesList = styled.div`
  display: flex;
  transition: transform 0.25s;

  > * {
    flex-basis: 0;
    flex-grow: 1;
  }
`;

const SliderArrow = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  height: 60px;
  width: 21px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  padding: 100px 25px;

  &::after, &::before {
    content: '';
    display: block;
    position: absolute;
    width: 35px;
    height: 1px; 
    background: #fff;
    @media (min-width: ${vars.media.sm}) {
      background: ${vars.colors.lightGray};
    }
  }
  
  &.arrow-bottom-xs {
    top: 100%;
    margin-top: 34.5px;
    transform: none;
    
    @media (min-width: ${vars.media.md}) {
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
    }
    
    &::after, &::before {
      background: ${vars.colors.lightGray};
    }
    &.slider-arrow--left {
      left: 50px;
    }
    &.slider-arrow--right {
      right: 50px;
    }
  }
  
  &.slider-arrow--left {
    left: 15px;
    &::before, &::after {
      left: 15px;
      transform-origin: 0% 50%;
    }
    &::before {
      transform: translate(0, -1px) rotate(55deg);
    }
    &::after {
      transform: translate(0, -1px) rotate(-55deg);
    }
  }

  &.slider-arrow--right {
    right: 15px;
    &::before, &::after {
      right: 15px;
      transform-origin: 100% 50%;
    }
    &::before {
      transform: translate(0, -1px) rotate(-55deg);
    }
    &::after {
      transform: translate(0, -1px) rotate(55deg);
    }
  }
`;

const SliderFooter = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: ${props => props.mt ? props.mt : 0};
  order: 2;
  font-size: .85rem;
`;

const SliderNavButton = styled.div`
  display: inline-block;
  cursor: pointer;
  height: 9px;
  width: 9px;
  background-color: #C8C8C8;
  border-radius: 10px;
  margin: 0 4px;
  
  &.slider-navButton--active {
    border: 1px solid rgb(110, 210, 160);
    background-color: #fff;
  }
`;

export {
  ImageSlide, SlideTitle, SliderInner, SliderContainer, SliderWrapper, SlidesList, SliderArrow, SliderFooter, SliderNavButton, SliderControlsRow
}