import axios from 'axios';
import { fetchFilters } from './filterActions';

import { addNotification } from './notificationActions';
import {
  addItemToLibrary,
  addItemsToLibrary,
  deleteItemsFromLibrary,
  deselectLibraryItems,
  updateItemInLibrary
} from './libraryActions';
import { showLoading, updateLoading, hideLoading } from 'components/LoadingBar';
import { getErrorMessage } from 'modules/errorHandler';

const addItemSuccess = (message, item) => {
  return dispatch => {
    // Hide progress bar
    dispatch(hideLoading());
    
    // Add the new item to the library
    dispatch(addItemToLibrary(item));
    
    // Update used filters
    dispatch(fetchFilters());
        
    // Dispatch flash message
    dispatch(addNotification({
      type: 'success',
      text: message || `${item.name} wurde erfolgreich hinzugefügt`
    }));
  }
}

const addItemFailure = message => {
  return dispatch => {
    // Hide progress bar
    dispatch(hideLoading());
    
    // Dispatch flash message with an error
    dispatch(addNotification({
      type: 'error',
      text: message || 'Ein unbekannter Fehler ist beim Import aufgetreten'
    }))
  }
}

const addItemSpaceFailure = () => {
  return dispatch => {
    dispatch(hideLoading());
    
    dispatch(addNotification({
      type: 'NOT_ENOUGH_SPACE',
      modal: true
    }));
  }
}

// New item request
export const createNewItem = f => {
  return dispatch => {
    dispatch(showLoading({ simulated: false }));
    
    // Track upload progress
    let prevPercent = 0;
    const config = {
      onUploadProgress: progress => {
        const percent = Math.round((progress.loaded * 100)/progress.total);
        // Update progress bar once for each 5% up until 95%
        if ((percent - prevPercent) >= 5 && percent <= 95) {
          dispatch(updateLoading(percent));
          prevPercent = percent;
        }
      }
    };
    
    return axios.post('/api/items', f, config)
    .then(res => {
      if (res.data.newItem) {
        dispatch(addItemSuccess(res.message, res.data.newItem))
      } else {
        dispatch(addItemFailure())
      }
    })
    .catch(err => {
      let message = getErrorMessage(err);
      
      if (err.response && err.response.data.notEnoughSpace) {
        return dispatch(addItemSpaceFailure());
      }
      
      if (err.response && err.response.status === 413) {
        message = "Fehler: die Datei, die du hochladen möchtest, ist zu groß"
      }
      
      dispatch(addItemFailure(message))
    });
  }
}

export const deleteItems = ids => {
  return dispatch => {
    // Show progress bar
    dispatch(showLoading());

    return axios.all(ids.map(id =>
      axios.delete(`/api/items/${id}`)
    ))
    .then(res => {
      // Hide progress bar
      dispatch(hideLoading());
      
      // Remove deleted items from global library state 
      dispatch(deleteItemsFromLibrary(ids))
      
      dispatch(deselectLibraryItems(ids))
      
      // Update used filters
      dispatch(fetchFilters());
    })
    .catch(err => {
      // Hide progress bar
      dispatch(hideLoading());
      
      // Dispatch flash message
      const message = err.response && err.response.data.message;
      dispatch(addNotification({
        type: 'error',
        text: message || 'Ein unbekannter Fehler ist beim Löschen aufgetreten'
      }))
    });
  }
}

export const fetchItems = ({ page = 1, limit }) => {
  if (!limit) {
    throw Error("Limit should be specified");
  }

  return (
    dispatch => {
      return axios.get(`/api/items?page=${page}&limit=${limit}`)
      .then(res => {
        const { items, lastPage } = res.data;
  
        if (items && items.length > 0) {
          dispatch(addItemsToLibrary({ items }));
        }
        if (!isNaN(lastPage)) return Promise.resolve(lastPage)
      })
    }
  )
}

export const fetchItemsById = ids => (
  dispatch => {
    return axios.get(`/api/items/${ids}`)
    .then(res => {
      const { items } = res.data;

      if (items && items.length > 0) {
        dispatch(addItemsToLibrary({ items }));
      } else {
        return Promise.reject(new Error('No data'))
      }
    })
    .catch(err => Promise.reject(new Error('No data')))
  }
)

export const updateItem = (id, data, resource = '') => (
  dispatch => {
    dispatch(showLoading());

    return axios.post(`/api/items/${id}${resource ? '/' : ''}${resource}`, data)
    .then(res => {
      dispatch(hideLoading());
      
      const { message } = res.data
      if (message) {
        dispatch(addNotification({ type: 'success', text: message }))
      }

      return dispatch(updateItemInLibrary(id, res.data.updated))
    })
    .catch(err => {
      dispatch(hideLoading());
      
      getErrorMessage(err, text => {
        dispatch(addNotification({ type: 'error', text }))
      })
      
      return Promise.reject(err)
    })
  }
)

export const updateItems = (ids, data, resource = '') => (
  dispatch => {
    dispatch(showLoading());

    return axios.all(ids.map(id =>
      axios.post(`/api/items/${id}${resource ? '/' : ''}${resource}`, data)
    ))
    .then(res => {
      dispatch(hideLoading());
      
      ids.forEach((id, k) => {
        dispatch(updateItemInLibrary(id, res[k].data.updated))
      })
    })
    .catch(err => {
      dispatch(hideLoading());

      getErrorMessage(err, text => {
        dispatch(addNotification({ type: 'error', text }))
      })
      return Promise.reject(err)
    })
  }
)

export const updateItemsFilters = (ids, filters) => (
  dispatch => {
    return axios.all(ids.map(id =>
      axios.post(`/api/items/${id}/filters`, filters)
    ))
    .then(res => {
      ids.forEach((id, k) => {
        dispatch(updateItemInLibrary(id, res[k].data.updated))
      })
      
      // Update used filters
      dispatch(fetchFilters());
    })
    .catch(err => {
      const message = err.response && err.response.data.message;
      dispatch(addNotification({
        type: 'error',
        text: message || 'Ein unbekannter Fehler ist aufgetreten'
      }))
      return Promise.reject()
    })
  }
)