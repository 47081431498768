import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';

import { Button, ContentWrapper } from 'components/common/StyledComponents';
import InputField from 'components/common/InputField';
import SelectField from 'components/common/SelectField';
import createModal from 'components/Modal/createModal'; 
import { updateTeamUser } from 'actions/teamActions';
import { addNotification } from 'actions/notificationActions';
import { getErrorMessage, getErrorObject } from 'modules/errorHandler';
import { accountTypeOptions } from 'config';

class EditTeamUser extends Component {
  constructor(props) {
    super();
    
    this.state = {
      isLoading: false,
      errors: {},
      email: '',
      userId: '',
      accountType: ''
    }
  }
  
  onChange = e => {
    let { name, value } = e.target
    
    // Convert user id to integer for stricter comparisons
    if (name === 'userId') value = parseInt(value, 10)
    
    this.setState({ [name]: value });
    
    if (name === 'userId') {
      const user = find(this.props.users, u => u.id === value);
      this.setState({
        email: user.email,
        accountType: user.accountType
      })
    }
  }
  
  updateEmail = e => {
    e.preventDefault()
    const { updateTeamUser, addNotification, onSuccess } = this.props
    const { email, accountType, userId } = this.state
    
    this.setState({ isLoading: true, errors: {} })
    
    updateTeamUser(userId, { email, accountType })
    .then(res => {
      addNotification({
        type: 'success',
        text: `Der Nutzer ${email} wurde erfolgreich geändert`
      })
      
      onSuccess()
    })
    .catch(err => {
      this.setState({ isLoading: false })
      getErrorMessage(err, text => addNotification({ type: 'error', text }))
      getErrorObject(err, errors => this.setState({ errors }))
    })
  }

  render() {
    const { email, userId, accountType, isLoading, errors } = this.state
    const { users, currentUser } = this.props
    
    const userOptions = users.map(u => ({
      name: u.email,
      value: u.id
    }))
    
    return (
      <form onSubmit={this.updateEmail}>
        <ContentWrapper>
          <SelectField
            gray
            onChange={this.onChange}
            label='Welcher Nutzer soll geändert werden?'
            placeholder='Nutzer wählen'
            name='userId'
            options={userOptions}
            value={userId}
            margin={3}
          />
          <InputField
            gray={!!userId}
            disabled={!userId}
            error={errors.email}
            onChange={this.onChange}
            value={email}
            label="E-Mail-Adresse ändern"
            name="email"
            margin={3}
          />
          
          <SelectField
            gray={!!userId}
            disabled={!userId || currentUser.id === userId}
            onChange={this.onChange}
            label='Kontotyp ändern'
            moreInfo='/faq/7:a'
            name='accountType'
            options={userId ? accountTypeOptions : []}
            value={accountType}
            margin={3}
          />
        </ContentWrapper>
        <Button wide disabled={isLoading || !(email && userId && accountType)}>
          Änderungen speichern
        </Button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
    users: state.team.users
  }
}

const EditTeamUserForm = connect(mapStateToProps, {updateTeamUser, addNotification})(EditTeamUser);

export default createModal(EditTeamUserForm);
