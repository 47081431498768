import axios from 'axios'
import { getAccessToken, getRefreshToken } from 'reducers';
import jwtDecode from 'jwt-decode';
import { getErrorMessage } from 'modules/errorHandler';

import { userLogin, userLogout } from 'actions/authActions';
import { addNotification } from 'actions/notificationActions';

export default ({ dispatch, getState }) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    config => {
      const state = getState()
      config.headers['x-access-token'] = getAccessToken(state)
      config.headers['x-refresh-token'] = getRefreshToken(state)
      
      return config;
    },
    error => Promise.reject(error)
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    res => {
      const state = getState();
      const newAccessToken = res.headers['x-access-token'];
      const newRefreshToken = res.headers['x-refresh-token'];
      
      // Bail if there are no new tokens
      if (!newAccessToken || !newRefreshToken) return res;
            
      const accessToken = getAccessToken(state);
            
      // Update the tokens if the new access token is different and fresher 
      if (!accessToken || (accessToken !== newAccessToken && jwtDecode(newAccessToken).exp > state.auth.access.exp)) {
        dispatch(userLogin(newAccessToken, newRefreshToken));
      }
      
      return res;
    },
    err => {
      // Auto-Logout on 401 error
      if (err.response.status === 401 && getState().auth.isAuthenticated) {
        dispatch(userLogout())
        
        // Optionally show an error message
        getErrorMessage(err, message =>
          dispatch(addNotification({
            error: true,
            text: message
          }))
        );
      }
      
      return Promise.reject(err);
    }
  );
}