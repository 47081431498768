import { utils } from './external/hedron/src/index';
import { round } from './utils/common';

/* Variables */

const variables = {
  colors: {
    green: 'rgb(108, 208, 158)',
    lightestGray: 'rgb(245, 245, 245)',
    lightGray: 'rgb(200, 200, 200)',
    gray: 'rgb(125, 125, 125)',
    danger: 'rgb(251, 112, 102)',
  },
  main: {
    headerHeight: 34,
    gutter: 10,
    checkboxHeight: 25,
    columnGutter: {
      xs: 5,
      sm: 10,
      condensed: 6,
    },
    divisions: 60,
  },
  media: {
    sm: '500px',
    md: '768px',
    lg: '1200px',
    xl: '1440px',
    xsMax: '499px',
    smMax: '767px',
    mdMax: '1199px',
    lgMax: '1439px',
  },
};

export default variables;

// Mixins
const { columnGutter, divisions } = variables.main;

export const calcTileWidth = (col, gutter = columnGutter.sm) =>
  col ? `width: calc(${utils.divvy(divisions, col)}% - ${gutter}px);` : null;

export const calcSidebarWidth = (col, gutter = columnGutter.sm) =>
  `calc((100% - ${col + 1}*${gutter}px)*1/${col} + ${gutter}px)`;

// Prices

const archicloudProBruttoMonthly = 10;
const archicloudProBruttoYearly = 100;

export const subscriptions = {
  archicloudFree: {
    space: 1,
  },
  archicloudPro: {
    space: 25,
    monthly: {
      brutto: archicloudProBruttoMonthly,
      netto: round(archicloudProBruttoMonthly / 1.19),
    },
    yearly: {
      brutto: archicloudProBruttoYearly,
      netto: round(archicloudProBruttoYearly / 1.19),
    },
  },
};

// Roles

export const roles = {
  super: ['superadmin'],
  admin: ['admin', 'superadmin'],
  crud: ['standard', 'admin', 'superadmin'],
};
