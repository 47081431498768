import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';

import { loadState, saveState } from 'localStorage';
import reducers from 'reducers';
import jwtMiddleware from './middleware/jwt';
import { loadingBarMiddleware } from 'components/LoadingBar';
import { environment } from './config';
import { initialState as filtersInitialState } from './reducers/filters';

const configureStore = () => {
  const middleware = [
    jwtMiddleware,
    thunk,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'PROGRESS', 'SUCCESS', 'FAILURE']
    })
  ];

  // Load state from localStorage
  const persistedState = loadState();

  // Support Redux DevTools in development
  const composeEnhancers =
    (environment !== 'production' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  // Sync Redux state with localStorage
  store.subscribe(
    throttle(() => {
      const { preferences, auth, filters: filtersState } = store.getState();

      // Cache the filters state excl. the unrelevant data (selected filters, etc.)
      const filters = {
        ...filtersInitialState,
        items: filtersState.items,
        shared: filtersState.shared
      };

      saveState({
        preferences,
        auth,
        filters
      });
    }, 1000)
  );

  return store;
};

export default configureStore;
