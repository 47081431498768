import { updateItems } from 'actions/itemActions';
import { Button, FormGroup } from 'components/common/StyledComponents';
import TextareaField from 'components/common/TextareaField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allValuesAreSame } from 'utils/common';

class ItemsDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      isLoading: false,
      isDisabled: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { items } = this.props;

    if (items.length === 1 || allValuesAreSame(items, 'description')) {
      const description = items[0].description || '';
      this.setState({ description });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { description, isDisabled } = this.state;
    const { items, updateItems } = this.props;

    if (isDisabled) return;

    this.setState({ isLoading: true });
    const ids = items.map((i) => i.id);

    updateItems(ids, { description })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { canEdit } = this.props;
    const { isLoading, description, isDisabled } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <TextareaField
          className="gray"
          onChange={this.onChange}
          value={description}
          name="description"
          placeholder="Beschreibung eingeben..."
          disabled={isDisabled || !canEdit}
        />

        {canEdit && (
          <FormGroup>
            <Button wide disabled={isDisabled || isLoading}>
              Änderungen speichern
            </Button>
          </FormGroup>
        )}
      </form>
    );
  }
}

ItemsDescription.propTypes = {
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

export default connect(null, { updateItems })(ItemsDescription);
