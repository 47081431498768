import { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { Search, createFilter } from 'components/Search';
import { StepNavBar, StepNavItem, Wrapper } from 'components/common/StyledComponents';
import reactToString from 'modules/reactToString';

const getFilteredData = (searchTerm, data) => {
  if (!searchTerm) {
    return { relevantSections: data, activeKey: null };
  }

  const relevantSectionsByTitle = data.filter(createFilter(searchTerm, 'title')).map((i) => i.key);

  const activeSections = data
    .filter((section) => {
      const children = section.children.map((c) => reactToString(c));
      const results = children.filter(createFilter(searchTerm));
      return results.length > 0;
    })
    .map((i) => i.key);

  const relevantSectionKeys = [...relevantSectionsByTitle, ...activeSections];
  const relevantSections = data.filter((s) => relevantSectionKeys.indexOf(s.key) > -1);

  return { relevantSections, activeKey: activeSections.map((c) => c.toString()) };
};

const createSearchable =
  ({
    title,
    data,
    containerName = 'searchableContainer',
    filterData = getFilteredData,
    activeKeyIsObject,
  }) =>
  (WrappedComponent) => {
    class Searchable extends Component {
      constructor(props) {
        super(props);

        this.mark = null;
        this.state = {
          searchTerm: '',
          activeKey: null,
          relevantSections: [],
        };
      }

      UNSAFE_componentWillMount() {
        this.setState({
          relevantSections: data,
        });

        const { expanded } = this.props.match.params;
        if (expanded) {
          let activeSections = expanded.split(',');

          if (activeKeyIsObject) {
            activeSections = Object.assign(
              {},
              ...activeSections.map((section) => {
                // Each ection may contain a single expanded subsection delimited by ":"
                const parts = section.split(':');

                return {
                  [parts[0]]: parts.length === 2 ? [parts[1]] : [],
                };
              })
            );
          }

          this.setState({
            activeKey: activeSections,
          });
        }
      }

      // componentDidMount() {
      //   this.markInstance = new Mark(document.querySelector('.' + containerName));
      // }

      // componentDidUpdate(prevProps, prevState) {
      //   const { searchTerm, activeKey } = this.state;
      //   if (prevState.activeKey !== activeKey || prevState.searchTerm !== searchTerm) {
      //     this.markInstance.unmark();
      //     if (searchTerm) this.markInstance.mark(searchTerm);
      //   }
      // }

      handleSearchChange = (searchTerm) => {
        this.setState({ searchTerm });
        const { relevantSections, activeKey } = filterData(searchTerm, data);

        this.setState({ relevantSections, activeKey });
      };

      handleAccordionChange = (activeKey) => {
        this.setState({ activeKey });
      };

      handleAccordionChange = (activeKey, parentKey) => {
        if (activeKeyIsObject) {
          if (parentKey) {
            this.setState({
              activeKey: { ...this.state.activeKey, [parentKey]: activeKey },
            });
          } else {
            const oldActiveKey = this.state.activeKey || {};
            const newActiveKey = {};
            activeKey.forEach((key) => {
              newActiveKey[key] = oldActiveKey[key] || [];
            });
            this.setState({ activeKey: newActiveKey });
          }
        } else {
          this.setState({ activeKey });
        }
      };
      render() {
        const { activeKey, relevantSections } = this.state;

        return (
          <Wrapper fixed>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <StepNavBar>
              <StepNavItem>
                <span>{title}</span>
              </StepNavItem>
            </StepNavBar>
            <Search placeholder="Suche" onChange={this.handleSearchChange} />
            <WrappedComponent
              activeKey={activeKey}
              content={relevantSections}
              onAccordionChange={this.handleAccordionChange}
              {...this.props}
            />
          </Wrapper>
        );
      }
    }

    return Searchable;
  };

export default createSearchable;
