import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findLast } from 'lodash';
import { spring, TransitionMotion } from 'react-motion';

import { deleteNotification } from 'actions/notificationActions';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import { StyledFlashMessagesList } from 'components/FlashMessage/flashStyles';
import ActiveModal from 'components/Modal/ActiveModal';
import { AddToFavorites } from 'components/Filter/popups';
import RequestPasswordReset from 'components/LoginForm/RequestPasswordReset';
import SpaceModal from 'components/SpaceUsage/SpaceModal';

const springConfig = {stiffness: 200};

const Modals = {
  ADD_TO_FAVORITES: AddToFavorites,
  RESET_PASSWORD: RequestPasswordReset,
  NOT_ENOUGH_SPACE: SpaceModal
}

const NotificationList = ({ notifications, deleteNotification }) => {
  
  const renderModal = () => {
    const modal = findLast(notifications, { modal: true })
    if (!modal) return null;
    
    const ModalComponent = Modals[modal.type] || ActiveModal;
    
    return (
      <ModalComponent
        key={modal.id}
        data={modal}
        onClose={() => deleteNotification(modal.id)}
      />
    )
  }

  const willEnter = () => ({ opacity: 0, scale: .98 })
  const willLeave = () => ({ opacity: spring(0, springConfig), scale: spring(.98, springConfig) })
  const getFlashStyles = () => notifications.filter(m => !m.modal).map(m => ({
    key: String(m.id),
    data: m,
    style: { opacity: spring(1), scale: spring(1) }
  }))
    
  return (
    <div className='notifications'>
      <TransitionMotion
        willEnter={willEnter}
        willLeave={willLeave}
        styles={getFlashStyles()}>
        {styles => (
          <StyledFlashMessagesList className="flash-messages">
            {styles.map(({ key, style: {opacity, scale}, data }) => (
              <FlashMessage
                key={key}
                message={data}
                style={{ opacity, transform: `scale(${scale})` }}
                deleteNotification={deleteNotification}
              />
            ))}
          </StyledFlashMessagesList>
        )}
      </TransitionMotion>
      {renderModal()}
    </div>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  deleteNotification: PropTypes.func.isRequired
}

const mapsStateToProps = ({ notifications }) => ({ notifications });

export default connect(mapsStateToProps, { deleteNotification })(NotificationList);
