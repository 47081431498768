import React, { Component } from "react";
import { connect } from "react-redux";

import Table from "components/common/Table";
import InfoLink from "components/common/InfoLink";
import { Lock, Unlock } from "components/icons";
import { UnstyledButton } from "components/common/StyledComponents";
import {
  lockUserAccount,
  unlockUserAccount,
  getAllUsers
} from "actions/userActions";
import { addNotification } from "actions/notificationActions";
import { getPrettyUserList } from "utils/users";

// Set default column sizes
const columnSizes = [
  { xs: 5, sm: 3 }, // email
  { xs: 4, sm: 2, md: 2 }, // account type
  { xs: 2, sm: 2, md: 1 }, // subscription type
  { xs: 0, sm: 2, md: 1 }, // online status
  { xs: 0, sm: 0, md: 2 }, // last active
  { xs: 0, sm: 0, md: 1 }, // space usage,
  { xs: 0, sm: 2, md: 1 }, // account initiated
  { xs: 1 }
];

class CustomerTable extends Component {
  constructor() {
    super();

    this.state = {
      users: []
    };
  }

  componentDidMount() {
    this.getUserList();
  }

  async getUserList() {
    const users = await this.props.getAllUsers();

    if (users && users.length > 0) {
      this.setState({ users: getPrettyUserList(users, true) });
    }
  }

  getColumns() {
    const { users } = this.state;
    const usersOnline = users.filter(u => u.isOnline === true);

    return [
      {
        key: "email",
        header: (
          <span className="semibold">E-Mail-Adresse ({users.length})</span>
        ),
        align: "left"
      },
      {
        key: "accountType",
        header: (
          <div>
            <span className="semibold">Kontotyp</span><InfoLink to="/faq/7:a" />
          </div>
        ),
        align: "left"
      },
      {
        key: "subscriptionType",
        header: "Abo"
      },
      {
        key: "onlineStatus",
        header: `online (${usersOnline.length})`
      },
      {
        key: "lastActive",
        header: "zuletzt online"
      },
      {
        key: "usedSpace",
        header: "genutzter Speicherplatz"
      },
      {
        key: "accountInitComplete",
        header: "initialisiert"
      },
      {
        header: "",
        render: this.renderBlock
      }
    ];
  }

  lockUserAccount = id => {
    this.props.lockUserAccount(id).then(() => {
      this.getUserList();

      this.props.addNotification({
        type: "success",
        text: "Das Konto wurde erfolgreich gesperrt, der User wurde benachrichtigt"
      });
    });
  };

  unlockUserAccount = id => {
    this.props.unlockUserAccount(id).then(() => {
      this.getUserList();

      this.props.addNotification({
        type: "success",
        text: "Das Konto wurde erfolgreich entsperrt"
      });
    });
  };

  renderBlock = ({ id, isLocked }) => {
    const disabled = id === this.props.currentUserId;

    if (isLocked) {
      return (
        <UnstyledButton
          disabled={disabled}
          onClick={() => this.unlockUserAccount(id)}
        >
          <Unlock viewBox="0 0 24 24" />
        </UnstyledButton>
      );
    }

    return (
      <UnstyledButton
        disabled={disabled}
        onClick={() => this.lockUserAccount(id)}
      >
        <Lock viewBox="0 0 24 24" />
      </UnstyledButton>
    );
  };

  render() {
    const { users } = this.state;

    if (users.length === 0) return null;

    const columns = this.getColumns();

    return (
      <Table
        className="is-smaller"
        colClassName="condensed"
        sizes={columnSizes}
        columns={columns}
        data={users}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUserId: state.auth.user.id
});

export default connect(mapStateToProps, {
  lockUserAccount,
  unlockUserAccount,
  getAllUsers,
  addNotification
})(CustomerTable);
