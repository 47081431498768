import axios from 'axios';

export const USER_LOGIN = 'USER_LOGIN/auth';
export const USER_LOGOUT = 'USER_LOGOUT/auth';
export const REFRESHING_TOKENS = 'REFRESHING_TOKENS/auth';

export const userLogin = (accessToken, refreshToken) => ({
  type: USER_LOGIN,
  accessToken,
  refreshToken
})

export const refreshAuthTokens = () => (
  dispatch => axios.get('/api/auth/refresh')
)
  
export const userLogout = () => ({
  type: USER_LOGOUT
})

export const userLoginRequest = data => (
  dispatch => axios.post('/api/auth', data)
)