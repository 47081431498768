import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { updateItem } from 'actions/itemActions';
import { deselectLibraryItems } from 'actions/libraryActions';
import CropArea from 'components/Import/CropArea';
import DropFile from 'components/Import/DropFile';
import {
  Button,
  ControlsRow,
  SectionTitle,
  SplitPaneWrapper,
} from 'components/common/StyledComponents';
import { cropToFile } from 'utils/cropImage';

class ReplaceThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnail: null,
      crop: null,
      itemId: null,
    };
  }

  static propTypes = {
    items: PropTypes.array.isRequired,
  };

  onChange = (thumbnail) => this.setState({ thumbnail });

  UNSAFE_componentWillMount() {
    const { id, thumbnailUrl } = this.props.items[0];
    this.setState({
      thumbnail: { preview: thumbnailUrl, type: 'image/jpeg' },
      itemId: id,
    });
  }

  updateThumbnail = async () => {
    const itemData = new FormData();
    let { thumbnail, crop, itemId } = this.state;
    const { deselectLibraryItems, updateItem, history } = this.props;

    // Crop the thumbnail
    if (thumbnail && crop) {
      thumbnail = await cropToFile(thumbnail.preview, crop, thumbnail.name);
    }

    itemData.append('thumbnail', thumbnail);

    // Dispatch the request
    updateItem(itemId, itemData, 'thumbnail').then(() => {
      deselectLibraryItems();
      history.push('/');
    });
  };

  render() {
    const { thumbnail, crop } = this.state;

    return (
      <SplitPaneWrapper>
        <SectionTitle>Vorschaubild ersetzen</SectionTitle>
        {thumbnail.preview && (
          <CropArea onChange={(c) => this.setState({ crop: c })} image={thumbnail} />
        )}

        <DropFile
          file={thumbnail}
          accept={{ 'image/*': [], 'application/pdf': [] }}
          onChange={this.onChange}
        />

        <ControlsRow>
          <Button
            wide
            onClick={this.updateThumbnail}
            disabled={isEmpty(thumbnail) || isEmpty(crop)}
          >
            Vorschaubild speichern
          </Button>
        </ControlsRow>
      </SplitPaneWrapper>
    );
  }
}

export default connect(null, { updateItem, deselectLibraryItems })(ReplaceThumbnail);
