import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import vars from 'variables';
import { FormGroup } from './StyledComponents';
import { Eye, EyeOff } from 'components/icons/';

const InputWrapper = styled.div`
  position: relative;
  input {
    border: 1px solid ${vars.colors.lightGray};
    background: ${props => props.gray ? vars.colors.lightestGray : '#fff'};
    width: 100%;
    padding: 3px 20px;
    font-size: .85rem;
    line-height: 1.3;
    outline: none;
  }
  &.is-password-field input {
    padding: 3px 25px 3px 20px;
  }
`;

const EyeToggle = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  height: 100%;
  margin: auto;
  cursor: pointer;
  user-select: none;
  svg {
    height: 100%;
  }
`;

class InputField extends Component {  
  constructor(props) {
    super(props)
    
    this.state = {
      isVisible: null
    }
  }
  
  // Don't return mask value as the new value
  handleChange = e => {
    if (e.target.value !== this.props.mask) this.props.onChange(e)
  }
  
  // Toggle password visibility
  toggleVisibility = () => {
    if (this.props.type === 'password') {
      this.setState({ isVisible: !this.state.isVisible })
    }
  }
  
  render() {
    const {
      name,
      value,
      label,
      error,
      warning,
      type,
      disabled,
      placeholder,
      onBlur,
      gray,
      formClassName,
      className,
      autoFocus,
      margin,
      mask
    } = this.props;
    const InputComponent = mask ? InputMask : 'input'
    const { isVisible } = this.state
    const EyeIcon = isVisible ? EyeOff : Eye
    
    return (
      <FormGroup margin={margin} className={cx({ 'has-danger': error }, formClassName, 'form-group--input')}>
        { label && <label>{label}</label> }
        <InputWrapper
          className={cx({ 'is-password-field': type === 'password' })}
          gray={gray ? true : undefined}
        >
          <InputComponent
            disabled={disabled}
            autoFocus={autoFocus}
            className={className}
            onChange={this.handleChange}
            onBlur={onBlur}
            value={value}
            type={isVisible ? 'text' : type}
            name={name}
            placeholder={placeholder}
            mask={mask}
          />
          {type === 'password' &&
          <EyeToggle onClick={this.toggleVisibility}>
            <EyeIcon viewBox='0 0 24 24' />
          </EyeToggle>}
        </InputWrapper>
        {error && <span className="form-feedback">{error}</span>}
        {warning && <span className="form-feedback">{warning}</span>}
      </FormGroup>
    )
  }
}

InputField.propTypes = {
  formClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  warning: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool
}

InputField.defaultProps = {
  type: 'text',
  value: ''
}

export default InputField;