import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userLoginRequest } from 'actions/authActions';
import { addNotification } from 'actions/notificationActions';
import validateInput from 'modules/validations/login';

import { Col, Container } from 'components/common/Grid';
import InputField from 'components/common/InputField';
import { Button, FormGroup } from 'components/common/StyledComponents';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      isLoading: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  openResetPasswordPopup = () => {
    this.props.addNotification({
      type: 'RESET_PASSWORD',
      modal: true,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });

      this.props.userLoginRequest(this.state).catch((err) => {
        const { tryAgain, message, text } = err.response.data;
        this.setState({ isLoading: false });

        if (!message) return;

        if (tryAgain === false) {
          this.props.addNotification({
            type: 'error',
            title: message,
            dismissButton: 'OK',
            modal: true,
            text,
          });
        } else {
          this.props.addNotification({
            type: 'error',
            title: message || 'E-Mail und Passwort passen nicht zusammen',
            dismissButton: 'Erneut versuchen',
            actionButton: 'Kennwort vergessen',
            onAction: this.openResetPasswordPopup,
            modal: true,
          });
        }
      });
    }
  };

  render() {
    const { errors, email, password, isLoading } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Container className="padded">
          <Col>
            <InputField
              autoFocus={true}
              error={errors.email}
              onChange={this.onChange}
              value={email}
              name="email"
              placeholder="E-Mail-Adresse"
            />
          </Col>
          <Col>
            <InputField
              error={errors.password}
              onChange={this.onChange}
              value={password}
              name="password"
              type="password"
              placeholder="Kennwort eingeben"
            />
          </Col>
        </Container>

        <FormGroup>
          <Button white wide disabled={isLoading}>
            Anmelden
          </Button>
          {/* <ButtonLink to='/signup'>Registrieren</ButtonLink> */}
        </FormGroup>
      </form>
    );
  }
}

LoginForm.propTypes = {
  userLoginRequest: PropTypes.func.isRequired,
};

export default connect(null, { addNotification, userLoginRequest })(LoginForm);
