import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import classnames from 'classnames';
import styled from 'styled-components';

import { FormGroup } from './StyledComponents';
import vars from 'variables';

const Textarea = styled(TextareaAutosize)`
  border: 1px solid ${vars.colors.lightGray};
  background: #fff;
  width: 100%;
  padding: 3px 15px;
  font-family: inherit;
  font-size: .85rem;
  font-weight: 200;
  line-height: 1.3;
  outline: none;
  appearance: none;
  
  &.gray {
    background-color: ${vars.colors.lightestGray};
  }
`;

const TextareaField = (
  { name, value, label, error, placeholder, onChange, onBlur, disabled, formClassName, className }
) => (
  <FormGroup className={classnames({ 'has-danger': error }, formClassName, 'form-group--textarea')}>
    {label && <label>{label}</label>}
    <Textarea
      rows={5}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
    />
    {error && <span className="form-feedback">{error}</span>}
  </FormGroup>
);

Textarea.propTypes = {
  formClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
};

TextareaField.defaultProps = {
  disabled: false
};

export default TextareaField;
