import React, { Component } from "react";
import { connect } from 'react-redux';

import Table from "components/common/Table";
import InfoLink from 'components/common/InfoLink';
import { getPrettyUserList } from 'utils/users';

class UserTable extends Component {
  getColumns() {
    const { user, users } = this.props
    const usersOnline = users.filter(u => u.isOnline === true)
    
    const columns = [
      {
        key: "email",
        header: <span className='semibold'>E-Mail-Adresse ({users.length})</span>,
        align: "left"
      },
      {
        key: "accountType",
        header: <div><span className='semibold'>Kontotyp</span> <InfoLink to='/faq/7:a' /></div>,
        align: "left"
      },
      {
        key: "onlineStatus",
        header: `aktuell online (${usersOnline.length})`
      }
    ];
    
    if (user.accountType.indexOf('admin') > -1) {
      return columns
    } else {
      return columns.filter(c => c.key !== 'accountType')
    }
  }
  
  render() {
    const { users, user } = this.props;
    
    // Display the table only for archicloud Pro and if the team isn't empty
    if (users.length === 0 || !user.hasProAccount) return null;
    
    const columns = this.getColumns()
    
    // Set default column sizes for 3-column layout
    let columnSizes = [{ xs: 8, sm: 5, lg: 4 }, { xs: 4 }, { xs: 0, sm: 3, lg: 4 }]
    // Standard/simple users get a 2-column layout instead
    if (columns.length !== 3) {
      columnSizes = [{ xs: 8, sm: 6 }, { xs: 4, sm: 6 }]
    }
    
    return (
      <Table
        colClassName='condensed'
        sizes={columnSizes}
        columns={columns}
        data={users}
      />
    )
  }
}

const mapStateToProps = state => ({
  users: getPrettyUserList(state.team.users),
  user: state.auth.user
})

export default connect(mapStateToProps)(UserTable)