export const isRequired = fieldName => `${fieldName} darf nicht leer sein`;

export const isInvalid = fieldName => `${fieldName} ist falsch`;

export const mustMatch = fieldName => {
  return `Die ${fieldName} müssen übereinstimmen`
}

export const mustNotMatch = fieldName => {
  return `${fieldName} muss unterschiedlich sein`
}

export const minLength = length => {
  return fieldName => `${fieldName} muss mindestens ${length} Zeichen lang sein`;
}

export const isChecked = fieldName => `Bitte akzeptiere ${fieldName}`;