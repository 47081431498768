import { SET_SEARCH_TERM } from 'actions/searchActions';

export default (state = '', action = {}) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return action.searchTerm;
      
    default: return state;
  }
}
