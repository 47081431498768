import React, { Component } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import { connect } from 'react-redux';

import { deleteItems } from 'utils/common';
import {
  setPrimaryFilter,
  setSecondaryFilters,
  deselectFilters
} from 'actions/filterActions';

const createAccordion = Accordion => {
  class CustomAccordion extends Component {    
    updateSelectedFilters(keys) {
      const { filters, deselectFilters } = this.props;
      // Find which filter groups have been collapsed, convert strings into numbers 
      const collapsedFilters = deleteItems(filters.secondary, keys);
      if (collapsedFilters.length > 0) {
        // Get selected filters from collapsed filter groups
        const filtersToDeselect = filters.selected.filter(id => 
          collapsedFilters.indexOf(String(filters.items[id].parent_id)) > -1
        );
        if (filtersToDeselect.length > 0) deselectFilters(filtersToDeselect);
      }
    }

    handleChange = active => {
      const { setSecondaryFilters, filters, level, setPrimaryFilter } = this.props;

      // If key is a string -> accordion contains single value
      if (typeof active === 'string') {
        const item = this.props.filters.items[active];
        const id = item ? item.id : null;

        if (!id) console.error(`Couldn't find a filter with an ID=${active}`);

        // Check if current item is a primary filter (level === 0)
        if (id && level === 0 && item.level === 0 && filters.primary !== id) {
          // If all checks pass, update the current primary filter
          setPrimaryFilter(id)
        }
        // Inner accordions support multiple expanded panels
        // Active Array contains ids of expanded panels
      } else if (Array.isArray(active)) {
        if (level === 1 && filters.secondary !== active) {
          // Filter out null or undefined keys
          const keys = active.filter(k => k);
          // Remove children of collapsed filter groups from state
          if (filters.selected.length > 0) this.updateSelectedFilters(keys);
          
          // Update secondary filters
          setSecondaryFilters(keys);
        }
      } else if (level === 0) {
        // Reset current primary filter
        setPrimaryFilter() 
      }
    }

    render() {
      const { level, filters, onChange, active, ...restProps } = this.props;

      // Render an accordion without level as a dummy component
      if (isNaN(level)) return <Accordion {...restProps} />

      // Accordion as a filterList container
      const { primary, secondary } = filters;
      const activeKey = level === 0 ? primary && primary.toString() : secondary;
      const isActive = level === 0 ? primary !== null : secondary && secondary.length > 0;

      return (
        <Accordion
          activeKey={activeKey}
          className={isActive ? 'has-expanded-items' : undefined}
          onChange={this.handleChange}
          {...restProps}
        />
      );
    }
  }
  
  const mapStateToProps = state => ({
    filters: state.filters
  })
  
  return connect(mapStateToProps, {
    setPrimaryFilter,
    setSecondaryFilters,
    deselectFilters
  })(CustomAccordion);
}

// ExpandedPanel component is supposed to be used as an ALWAYS expanded panel
// It ignores props passed from the accordion, mimics other panels and is always visible 

const ExpandedPanel = ({ children, header }) => (
  <div className="ListItem rc-collapse-item-active">
    <div className="list-item-title">{header}</div>
    {children}
  </div>
);

const Accordion = createAccordion(Collapse)

export { Accordion, Panel, ExpandedPanel, Collapse as SimpleAccordion };
