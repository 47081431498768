import { fetchFilters } from 'actions/filterActions';
import { fetchItems } from 'actions/itemActions';
import {
  appendSelectedLibraryItems,
  selectLibraryItems,
  toggleLibraryItem,
} from 'actions/libraryActions';
import { openPreviousSidebar, toggleSidebar } from 'actions/sidebarActions';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { ReactMouseSelect } from 'react-mouse-select';
import { connect } from 'react-redux';

import { getFilteredItems } from 'selectors';
import { createFilter } from '../Search';
import { Grid, LibraryHint, ViewWrapper } from '../common/StyledComponents';
import EmptyLibraryHint from './EmptyLibraryHint';
import LibraryItem from './LibraryItem';

const FETCH_ITEMS_LIMIT = 500;
const RENDER_ITEMS_COUNT = 15;

const GridRenderer = ({ selectedItems, onItemClick, items, scrollPosition }) => {
  return (
    <Grid divisions={60}>
      {items.map(({ id, name, thumbnailUrl }) => (
        <LibraryItem
          id={id}
          key={id}
          name={name}
          className="mouse-select__selectable"
          selectableKey={id}
          selected={selectedItems.indexOf(id) > -1}
          onClick={onItemClick}
          thumbnailUrl={thumbnailUrl}
          scrollPosition={scrollPosition}
        />
      ))}
    </Grid>
  );
};

const LazyGrid = trackWindowScroll(GridRenderer);

class LibraryGrid extends Component {
  constructor() {
    super();

    this.state = {
      hasMore: true,
      canRenderMore: true,
      renderCount: RENDER_ITEMS_COUNT,
      currentPage: 1,
      lastPage: undefined,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { toggleSidebar, selectedItems, items } = this.props;

    if (selectedItems.length > 0) {
      toggleSidebar('library');
    } else if (items.length > 0) {
      toggleSidebar('filters');
    }

    // Pre-fetch filters on mount if needed
    if (!this.props.filtersAreLoaded) {
      this.props.fetchFilters();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedItems, toggleSidebar, openPreviousSidebar, items } = this.props;

    // Open filters sidebar if the library is not empty
    if (nextProps.items.length > 0 && items.length === 0) {
      toggleSidebar('filters');
    }

    // Toggle sidebar if files were selected or unselected
    if (nextProps.selectedItems.length !== selectedItems.length) {
      if (selectedItems.length === 0) {
        toggleSidebar('library');
      } else if (nextProps.selectedItems.length === 0) {
        openPreviousSidebar();
      }
    }
  }

  componentDidUpdate(prevProps) {
    /*
      Reset rendering count if the item count increases while further rendering was deactivated:
      (when filtering/searching/new files are added to the library, etc)
    */
    if (!this.state.canRenderMore && this.props.items.length > prevProps.items.length) {
      this.setState({ canRenderMore: true, renderCount: RENDER_ITEMS_COUNT });
    }
  }

  loadItems = () => {
    if (this.props.items.length > this.state.renderCount) {
      return this.setState({
        renderCount: this.state.renderCount + RENDER_ITEMS_COUNT,
      });
    } else if (!this.state.hasMore) {
      return this.setState({ canRenderMore: false });
    }

    // Fetch items asynchronously
    if (this.state.isLoading) return;

    const { currentPage } = this.state;
    this.setState({ isLoading: true });

    this.props
      .fetchItems({ page: currentPage, limit: FETCH_ITEMS_LIMIT })
      .then((lastPage) => {
        if (currentPage >= lastPage) {
          this.setState({ hasMore: false, isLoading: false });
        } else {
          this.setState({
            currentPage: currentPage + 1,
            lastPage,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ hasMore: false, isLoading: false });
      });
  };

  onItemClick = (itemId, event) => {
    const isTouch = event.type.slice(0, 5) === 'touch';

    if (isTouch || event.shiftKey) {
      this.props.toggleLibraryItem(itemId);
    } else {
      this.props.selectLibraryItems([itemId]);
    }
  };

  handleGroupSelection = (elements, event) => {
    const selectedKeys = elements.map((element) => parseInt(element.getAttribute('data-id'), 10));

    // Append selection if the shift key was pressed
    if (event.shiftKey) {
      this.props.appendSelectedLibraryItems(selectedKeys);
    } else {
      this.props.selectLibraryItems(selectedKeys);
    }
  };

  render() {
    const { items, libraryIsEmpty, sidebarIsOpen, canEdit } = this.props;
    const { canRenderMore, renderCount } = this.state;
    const noItemsFound = !libraryIsEmpty && !canRenderMore && items.length === 0;

    return (
      <ViewWrapper className={cx({ 'is-shifted': sidebarIsOpen })}>
        {libraryIsEmpty && <EmptyLibraryHint canEdit={canEdit} />}
        {noItemsFound && (
          <LibraryHint>
            <p>
              {this.props.searchTerm
                ? `Die Suche nach „${this.props.searchTerm}“ ergab keine Treffer`
                : 'Keine Treffer'}
            </p>
          </LibraryHint>
        )}
        <InfiniteScroll pageStart={1} loadMore={this.loadItems} hasMore={canRenderMore}>
          <LazyGrid
            selectedItems={this.props.selectedItems}
            items={items.slice(0, renderCount)}
            onItemClick={this.onItemClick}
          />
        </InfiniteScroll>

        <ReactMouseSelect
          containerRef={this.containerRef}
          itemClassName="mouse-select__selectable"
          finishSelectionCallback={this.handleGroupSelection}
        />
      </ViewWrapper>
    );
  }
}

LibraryGrid.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  fetchItems: PropTypes.func.isRequired,
  toggleLibraryItem: PropTypes.func.isRequired,
  selectLibraryItems: PropTypes.func.isRequired,
  appendSelectedLibraryItems: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  openPreviousSidebar: PropTypes.func.isRequired,
  fetchFilters: PropTypes.func.isRequired,
  libraryIsEmpty: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { searchTerm, library, sidebar, filters } = state;
  const items = getFilteredItems(state).filter(createFilter(searchTerm, 'name'));

  return {
    filtersAreLoaded: filters.items.length > 0,
    libraryIsEmpty: library.items.length === 0,
    selectedItems: library.selected,
    items,
    sidebarIsOpen: sidebar && sidebar.active !== null,
    canEdit: state.auth.user.canEdit,
    searchTerm,
  };
};

export default connect(mapStateToProps, {
  fetchItems,
  toggleSidebar,
  openPreviousSidebar,
  toggleLibraryItem,
  selectLibraryItems,
  appendSelectedLibraryItems,
  fetchFilters,
})(LibraryGrid);
