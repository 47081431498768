import styled from 'styled-components';
import vars from 'variables';

const Nav = styled.div`
  top: 0;
  width: 100%;
  height: ${vars.main.headerHeight}px;
  color: #fff;
  background-color: ${vars.colors.green};
  position: fixed;
  z-index: 999;
  line-height: 1;
  font-size: .85rem;
  
  > div {
    display: flex;
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translate(0, -50%);
  }
`;

const NavItems = styled.div`
  align-items: center;
  right: 0;
  left: auto;
`;

const NavItem = styled.div`
  cursor: pointer;
  margin-right: ${vars.main.gutter*1.5}px;
  user-select: none;

  a {
    &, &:focus, &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
`;

const Brand = styled.div`
  right: auto;
  left: 0;
  margin-left: ${vars.main.gutter*1.5}px;
  text-transform: lowercase;
  a {
    user-select: none;
    text-decoration: none;
    color: #fff;
  }
  span {
    display: none;
  }
  @media (min-width: ${vars.media.md}) {    
    span {
      display: initial;
    }
    svg {
      display: none;
    }
  }
`;

export {
  Nav, NavItem, NavItems, Brand
}