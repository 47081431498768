import { addNotification } from 'actions/notificationActions';
import { Component } from 'react';
import { connect } from 'react-redux';

export default (ComposedComponent, allowedAccountTypes = []) => {
  class Authenticate extends Component {
    UNSAFE_componentWillMount() {
      const { isAuthenticated, isPermitted, addNotification, history } = this.props;
      let errorMessage;

      if (!isAuthenticated) {
        errorMessage = 'Du musst eingeloggt sein, um auf diese Seite zugreifen zu können';
      } else if (!isPermitted) {
        errorMessage = 'Du hast nicht ausreichend Rechte, um auf diese Seite zuzugreifen';
      }

      if (errorMessage) {
        addNotification({
          type: 'error',
          text: errorMessage,
        });

        history.push('/');
      }
    }

    UNSAFE_componentWillUpdate({ isAuthenticated, isPermitted, history }) {
      if (!isAuthenticated || !isPermitted) {
        history.push('/');
      }
    }

    render() {
      const { isAuthenticated, isPermitted, addNotification, ...restProps } = this.props;

      if (!isAuthenticated || !isPermitted) return null;

      return <ComposedComponent {...restProps} />;
    }
  }

  const mapStateToProps = (state) => {
    const {
      isAuthenticated,
      user: { accountType },
    } = state.auth;
    let isPermitted = true;

    if (allowedAccountTypes.length > 0 && allowedAccountTypes.indexOf(accountType) < 0) {
      isPermitted = false;
    }

    return {
      isAuthenticated,
      isPermitted,
    };
  };

  return connect(mapStateToProps, { addNotification })(Authenticate);
};
