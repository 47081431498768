import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { createLocalForm } from 'components/Form';
import createModal from 'components/Modal/createModal';
import CheckboxField from 'components/common/CheckboxField';
import InputField from 'components/common/InputField';
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import getPasswordRules from 'modules/validations/passwordRules';

class CreatePassword extends Component {
  static defaultProps = {
    showTermsConditions: true,
  };

  UNSAFE_componentWillMount() {
    this.props.validate();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, fields } = this.props;

    if (this.props.validate()) {
      onSubmit && onSubmit(fields);
    }
  };

  onCheckboxChange = (e) => {
    const { name } = e.target;
    this.props.onChange({
      target: {
        name,
        value: !this.props.fields[name],
      },
    });
  };

  render() {
    const { errors, fields, onBlur, onChange, isInvalid, showTermsConditions } = this.props;
    const { password, passwordConfirmation, agreedToTerms } = fields;

    return (
      <form onSubmit={this.onSubmit}>
        <ContentWrapper>
          <InputField
            gray
            error={errors.password}
            label="Kennwort vergeben"
            onBlur={onBlur}
            onChange={onChange}
            value={password}
            name="password"
            type="password"
            placeholder="Kennwort eingeben"
          />

          <InputField
            gray
            margin={3}
            error={errors.passwordConfirmation}
            onBlur={onBlur}
            onChange={onChange}
            value={passwordConfirmation}
            name="passwordConfirmation"
            type="password"
            placeholder="Kennwort bestätigen"
          />
          {showTermsConditions && (
            <CheckboxField
              margin={2}
              title="AGB und Datenschutzhinweise"
              error={errors.agreedToTerms}
              isChecked={agreedToTerms}
              onChange={this.onCheckboxChange}
              name="agreedToTerms"
            >
              Ich habe die <Link to="/agb">AGB</Link> und{' '}
              <Link to="/datenschutz">Datenschutzhinweise</Link> gelesen
            </CheckboxField>
          )}
        </ContentWrapper>
        <Button disabled={isInvalid || (showTermsConditions && !agreedToTerms)} wide>
          Weiter
        </Button>
      </form>
    );
  }
}

const CreatePasswordForm = createLocalForm({
  fields: ['agreedToTerms', 'password', 'passwordConfirmation'],
  initialValues: {
    agreedToTerms: false,
  },
  validationRules: getPasswordRules(),
})(CreatePassword);

export default createModal(CreatePasswordForm);
