import { css } from 'styled-components';
import vars from 'variables';

const { media } = vars;

export default Object.keys(media).reduce((acc, label) => {
  const accumulator = acc;
  accumulator[label] = (...args) => css`
    @media (min-width: ${media[label]}) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});
