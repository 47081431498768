import React from "react";
import { Search } from "./index";
import { connect } from "react-redux";
import { setSearchTerm } from "actions/searchActions";

const SearchBar = ({ setSearchTerm, searchTerm }) => (
  <Search
    wrapperClassName="sidebar-search"
    className="sidebar-search-form"
    placeholder="Suche"
    onChange={value => setSearchTerm(value)}
  />
);

const mapStateToProps = ({ searchTerm }) => ({ searchTerm });

export default connect(mapStateToProps, { setSearchTerm })(SearchBar);
