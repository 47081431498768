import { Link } from 'react-router-dom';
import styled from 'styled-components';
import vars from 'variables';
import { LibraryHint } from '../common/StyledComponents';
import { ImportIconHuge } from '../icons';

const HintWrapper = styled(LibraryHint)`
  height: calc(100vh - ${vars.main.headerHeight}px);

  .import-hint {
    text-align: center;
    @media (min-height: 500px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    div {
      margin-bottom: 1.25em;
    }
    a {
      color: ${vars.colors.green};
      text-decoration: none;
    }
  }
`;

const EmptyLibraryHint = ({ canEdit }) => {
  return (
    <HintWrapper>
      <p>Leer hier – du hast keine Objekte importiert</p>
      {canEdit && (
        <div className="import-hint">
          <Link to="/import">
            <div>Jetzt Objekte importieren</div>
            <ImportIconHuge viewBox="0 0 190 190" width={90} height={90} />
          </Link>
        </div>
      )}
    </HintWrapper>
  );
};

export default EmptyLibraryHint;
