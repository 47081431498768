import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import * as Icons from 'components/icons/';
import { Button } from 'components/common/StyledComponents';
import InfoField from 'components/common/InfoField';

const CenteredText = styled.p`
  font-size: 0.85rem;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledPrimaryFilter = styled.div`
  font-size: 0.85rem;
  margin: 0 0 0.5rem;
  position: relative;

  svg {
    position: absolute;
    bottom: 2px;
  }

  span {
    margin-left: 20px;
  }
`;

const ItemsFilter = ({ items, filters, history, canEdit }) => {
  let itemFilters = [],
    itemPrimaryFilters = [];

  items.forEach((i) => {
    itemFilters.push(i.filters);
    if (itemPrimaryFilters.indexOf(i.primaryFilterId) === -1) {
      itemPrimaryFilters.push(i.primaryFilterId);
    }
  });

  const renderPrimaryFilter = (id) => {
    const { name, level } = filters[id];
    const Icon = Icons[`${name}Icon`];

    return (
      <StyledPrimaryFilter>
        {level === 0 && Icon && <Icon />}
        <span>{name}</span>
      </StyledPrimaryFilter>
    );
  };

  const renderFilters = (parentId) => {
    // Find common filters in given items
    const commonFilters = intersection(...itemFilters);

    if (commonFilters.length === 0) {
      return (
        <CenteredText>
          {items.length === 1
            ? 'Das Objekt besitzt keine Filter'
            : 'Objekte besitzen keine gemeinsamen Filter'}
        </CenteredText>
      );
    }

    // Create an object where keys are filter groups and values are lists of filters
    const commonParents = {};
    commonFilters.forEach((id) => {
      const parentId = filters[id].parent_id;
      commonParents[parentId] = commonParents[parentId] || [];
      commonParents[parentId].push(id);
    });

    return Object.keys(commonParents).map((key) => {
      const children = commonParents[key].map((id) => filters[id].name);
      const item = filters[key];
      let itemName = item.name;

      // Filters with a location_key displayed inside the literature filter section
      // have a name followed by their location_key in brackets
      if (
        item.location_key &&
        parentId &&
        filters[parentId].name === 'Literatur'
      ) {
        itemName += ` (${item.location_key})`;
      }

      return (
        <InfoField key={key} label={itemName}>
          {children}
        </InfoField>
      );
    });
  };

  return (
    <div>
      {itemPrimaryFilters.length === 1 ? (
        <Fragment>
          {renderPrimaryFilter(itemPrimaryFilters[0])}
          {renderFilters(itemPrimaryFilters[0])}
        </Fragment>
      ) : (
        <CenteredText>Objekte besitzen keine gemeinsamen Filter</CenteredText>
      )}
      {canEdit && (
        <Button
          disabled={itemPrimaryFilters.length > 1}
          onClick={() =>
            history.push(`/action/${items.map((i) => i.id).join()}/filters`)
          }
          wide
        >
          Filter anpassen
        </Button>
      )}
    </div>
  );
};

ItemsFilter.propTypes = {
  items: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  filters: state.filters.items
});

export default withRouter(connect(mapStateToProps)(ItemsFilter));
