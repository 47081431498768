const nextLetter = l => {
  return (l < 122) ? String.fromCharCode(l + 1) : 'a'
}

const same = (str, char) => {
  let i = str.length;
  while (i--) {
    if (str[i] !== char) return false;
  }
  return true;
}

const nextChar = c => {
  const u = c.toLowerCase();
  if (same(u, "z")) {
    let out = "", i = u.length;
    while (i--) out += "a";
    return out + "a";
  } else {
    let p = "", q = "";
    if (u.length > 1) {
      p = u.substring(0, u.length - 1);
      q = String.fromCharCode(p.slice(-1).charCodeAt(0));
    }
    const l = u.slice(-1).charCodeAt(0);
    const z = nextLetter(l);
    return (z !== "a") ? p + z : p.slice(0, -1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
  }
}

export default nextChar