import React from "react";
import { connect } from "react-redux";

import { ButtonLink, ContentWrapper } from "components/common/StyledComponents";
import Modal from "components/Modal/Modal";
import SpaceUsageBar from "./SpaceUsageBar";
import { General } from "translations/de";

const SpaceModal = ({ hasProAccount, isAdmin, onClose }) => {
  const renderActionButton = () => {
    if (!isAdmin) return null;

    if (!hasProAccount) {
      return (
        <ButtonLink onClick={onClose} to="/upgrade">
          Zu archicloud-Pro wechseln
        </ButtonLink>
      );
    }

    return (
      <ButtonLink onClick={onClose} to="/subscription">
        Lizenzen hinzufügen
      </ButtonLink>
    );
  };

  return (
    <Modal title="Zu wenig Speicherplatz" isOpen={true} onClose={onClose}>
      <ContentWrapper isSubtitle>
        {General.notEnoughSpace[hasProAccount ? "pro" : "free"]}
      </ContentWrapper>
      <ContentWrapper>        
        <SpaceUsageBar />
      </ContentWrapper>
      {renderActionButton()}
    </Modal>
  );
};

const mapStateToProps = state => ({
  hasProAccount: state.auth.user.hasProAccount,
  isAdmin: state.auth.user.isAdmin
});

export default connect(mapStateToProps)(SpaceModal);
