import React from 'react';
import cx from 'classnames';
import LibraryItem from '../LibraryItem';
import { Grid } from 'components/common/StyledComponents';

const ItemsGrid = ({ items, location }) => {
    
  const itemList = items.map(i =>
    <LibraryItem
      className='is-active'
      thumbnailUrl={i.thumbnailUrl}
      id={i.id}
      key={i.id}
      name={i.name}
      xs={30} sm={30} md={30} lg={20}
    />
  );
  
  const path = location.pathname
    
  return (
    <Grid
      className={cx({
        'has-one-item': items.length === 1 && (path.match(/properties/) || path.match(/filters/))
      })}
      divisions={60}>
      {itemList}
    </Grid>
  );
}

export default ItemsGrid