import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Accordion, Panel, ExpandedPanel } from 'components/common/Accordion';
import * as Icons from 'components/icons/';
import SelectView from './SelectView';
import AddLiteratureDetails from 'components/Import/AddLiteratureDetails';
import { getPossibleFilters, getPrimaryFilters, getSortedFilters } from 'selectors';
import { fetchFilters } from 'actions/filterActions';
import InfoLink from 'components/common/InfoLink';

class FilterList extends Component {
  componentDidMount() {
    const { fetchFilters, filters } = this.props;

    if (isEmpty(filters.items)) {
      fetchFilters();
    }
  }

  renderItemTitle = ({ name, level }) => {
    const Icon = Icons[`${name}Icon`];
    const shouldRenderInfoLink =
      level === 1 && name.match(/^Ort/) && this.props.editable;

    return (
      <div className="list-item-title">
        {level === 0 && Icon && <Icon />}
        <span>{name}</span>
        {shouldRenderInfoLink && (
          <InfoLink className="info-link-small" to="/faq/6:k" />
        )}
      </div>
    );
  };

  renderSelectView = (item, parentId) => {
    const {
      filters,
      editable,
      selectable,
      usedFiltersOnly,
      showHalfSelected,
      reverseSelectors
    } = this.props;

    let children = item.children;

    if (usedFiltersOnly) {
      // Get the array of used children, filter out children based on context
      children = filters.items[parentId].usedChildren[item.id].filter(
        (id) => filters.items[id]
      );
      if (children.length === 0) return null;
    }

    return (
      <SelectView
        editable={editable}
        selectable={selectable}
        options={children}
        searchable={item.searchable}
        showHalfSelected={showHalfSelected}
        reverseSelectors={reverseSelectors}
        id={item.id}
        name={item.name}
      />
    );
  };

  renderItems = (keys, level = 0, parentId = null) => {
    if (!keys) return null;

    const {
      showLiteratureInfos,
      filters,
      usedFiltersOnly,
      editable
    } = this.props;

    let itemList = [];

    if (
      showLiteratureInfos &&
      parentId &&
      filters.items[parentId].name === 'Literatur'
    ) {
      itemList.push(
        <ExpandedPanel
          header={
            <div>
              Datenbankabfrage über ISBN <InfoLink to="/faq/6:j" />
            </div>
          }
          key="Literatur-Infos"
        >
          <AddLiteratureDetails />
        </ExpandedPanel>
      );
    }

    keys.forEach((key) => {
      let item = filters.items[key]; // current item
      // Skip dynamic filter groups when listing editable filters
      if (!item || (editable && item.dynamic)) return;

      let children = item.children;

      if (item.level === 0) {
        if (usedFiltersOnly) {
          children = item.usedChildrenIds;
          if (!children || children.length === 0) return;
        } else {
          // Prepend shared filters to the list of children
          children = [...filters.shared, ...children];
        }
      }

      // Filters with a location_key displayed inside the literature filter section
      // have a name followed by their location_key in brackets 
      if (item.location_key && parentId && filters.items[parentId].name === 'Literatur') {
        item = {
          ...item,
          name: `${item.name} (${item.location_key})`
        };
      }

      itemList.push(
        <Panel
          className="ListItem"
          header={this.renderItemTitle(item)}
          key={item.id}
        >
          {item.level === 1
            ? this.renderSelectView(item, parentId)
            : this.renderItems(children, level + 1, item.id)}
        </Panel>
      );
    });

    return (
      <Accordion accordion={level === 0} level={level}>
        {itemList}
      </Accordion>
    );
  };

  render() {
    const { filters } = this.props;

    return (
      <div>
        {isEmpty(filters.items) ? null : this.renderItems(filters.primaryKeys)}
      </div>
    );
  }
}

FilterList.propTypes = {
  filters: PropTypes.object,
  selectable: PropTypes.bool,
  editable: PropTypes.bool,
  usedFiltersOnly: PropTypes.bool,
  showLiteratureInfos: PropTypes.bool,
  showHalfSelected: PropTypes.bool,
  reverseSelectors: PropTypes.bool,
  fetchFilters: PropTypes.func.isRequired
};

FilterList.defaultProps = {
  selectable: true,
  editable: true,
  usedFiltersOnly: false,
  showLiteratureInfos: false,
  showHalfSelected: false
};

const mapStateToProps = (state, props) => ({
  filters: {
    items: props.usedFiltersOnly
      ? getPossibleFilters(state)
      : getSortedFilters(state),
    shared: state.filters.shared,
    primaryKeys: getPrimaryFilters(state)
  }
});

export default connect(
  mapStateToProps,
  { fetchFilters }
)(FilterList);
