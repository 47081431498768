import axios from 'axios';
import { REFRESHING_TOKENS } from '../actions/authActions';
import { isAccessTokenExpired } from '../reducers';

export const refreshToken = (dispatch) => {
  const refreshPromise = axios
    .get('/api/auth/refresh')
    .then(() => {
      // axios response interceptor takes care of the new tokens
      return Promise.resolve();
    })
    .catch((e) => {
      return Promise.reject(e);
    });

  dispatch({
    type: REFRESHING_TOKENS,
    // Keep track of token promise to make sure no additional refreshes are initiated
    refreshPromise
  });

  return refreshPromise;
};

const jwt = ({ dispatch, getState }) => {
  return (next) => (action) => {
    // Track async actions only
    if (typeof action === 'function') {
      if (getState().auth.isAuthenticated && isAccessTokenExpired(getState())) {
        // Check if the token's already being refreshed
        const refreshPromise = getState().auth.refreshPromise;

        if (!refreshPromise || !refreshPromise.then) {
          return refreshToken(dispatch).then(() => next(action));
        } else {
          return refreshPromise.then(() => next(action));
        }
      }
    }
    return next(action);
  };
};

export default jwt;
