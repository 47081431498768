import React from 'react';
import { spring, TransitionMotion } from 'react-motion';
import { connect } from 'react-redux';

import SidebarFilters from './SidebarFilters';
import SidebarPrimary from './SidebarPrimary';
import SidebarLibrary from './SidebarLibrary';
import SidebarGuest from './SidebarGuest';
import { StyledSidebar } from './sidebarStyles';

const Sidebar = ({ auth, sidebar }) => { 
  
  const preset = {stiffness: 300}
  
  const willEnter = () => ({ translateX: 100 })
  const willLeave = () => ({ translateX: spring(100, preset) })
  const getStyles = () => ({ translateX: spring(0, preset) })
  
  const userSidebars = [
    {
      key: 'primary',
      data: <SidebarPrimary />
    },
    {
      key: 'library',
      data: <SidebarLibrary />
    },
    {
      key: 'filters',
      data: <SidebarFilters />
    }
  ];
  
  const guestSidebars = [
    {
      key: 'guest',
      data: <SidebarGuest className='sidebar-guest' />
    }
  ]
  
  const sidebars = auth.isAuthenticated ? userSidebars : guestSidebars
  
  const getData = () => sidebars.filter(i => i.key === sidebar.active).map(i => ({...i, style: getStyles()}))

  return (
    <TransitionMotion
      component={false}
      willEnter={willEnter}
      willLeave={willLeave}
      styles={getData()}
    >
      {interpolatedStyles => (
        <div className='sidebars'>
          {interpolatedStyles.map(({ key, style, data }) => (
            <StyledSidebar
              key={key}
              className={`sidebar sidebar-${key}`}
              style={{transform: `translateX(${style.translateX}%)`}}
            >
              {data}
            </StyledSidebar>
          ))}
        </div>
      )}
    </TransitionMotion>
  );
}

const mapStateToProps = ({ auth, sidebar }) => ({ auth, sidebar })

export default connect(mapStateToProps)(Sidebar);