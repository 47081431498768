import React, { Component } from 'react';
import Modal from './Modal';
import { Button } from 'components/common/StyledComponents';

const createModal = WrappedComponent => class extends Component {
  static defaultProps = {
    showTrigger: true
  }
  
  constructor({ isModal }) {
    super();
    
    this.state = {
      isOpen: isModal || false
    }
  }
  
  toggleModal(isOpen = true) {
    this.setState({ isOpen });
  }
  
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    } else {
      this.toggleModal(false)
    }
  }
  
  onTriggerClick = () => this.toggleModal()
  
  render() {
    const { modalTitle, trigger, showTrigger, isModal, ...props } = this.props;
    
    let triggerButton = <Button wide onClick={this.onTriggerClick}>{modalTitle || 'Öffnen'}</Button>
    
    if (showTrigger && trigger) {
      triggerButton = React.cloneElement(trigger, { onClick: this.onTriggerClick })  
    }
    
    return (
      <div>
        {showTrigger && triggerButton}
        <Modal
          title={modalTitle}
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          isModal={isModal}
        >
          <WrappedComponent
            title={modalTitle}
            onSuccess={() => this.toggleModal(false)}
            {...props} />
        </Modal>
      </div>
    );
  }
}

export default createModal;
