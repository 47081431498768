import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { isEqual } from 'lodash';

const StyledCropArea = styled.div`
  margin: 0 10px 3em;
  text-align: center;
  p {
    padding: 0 1em;
    text-align: center;
  }
  .ReactCrop {
    overflow: hidden;
  }
  .ReactCrop__crop-selection {
    background-image: none;
    animation: none;
    box-shadow: 0 0 0 9999em rgba(255,255,255,.4);
  }
  .ReactCrop__crop-wrapper {
    background-color: rgba(255,255,255,.4);
  }
  .ReactCrop__drag-handle {
    border: 0;
    background-color: rgb(75,75,75);
    width: 10px;
    height: 10px;
  }
  .ReactCrop__image {
    max-height: 50vh;
  }
`;

class CropArea extends Component {
  
  static propTypes = {
    image: PropTypes.object,
    onChange: PropTypes.func
  }
  
  constructor(props) {
    super(props)
    
    this.crop = {
      height: 100,
      aspect: 1
    }
    
    this.state = {
      pixelCrop: null,
      crop: this.crop
    }
  }

  onChange = (crop) => {
    this.setState({ crop });
  }
  
  updateCropState = (crop, pixelCrop) => {
    if (isEqual(crop, this.state.crop) && isEqual(pixelCrop, this.state.pixelCrop)) return;

    this.setState({ crop, pixelCrop });
    
    const { onChange } = this.props;
    if (onChange) onChange(crop, pixelCrop);
  }
  
  onImageLoaded = (image, pixelCrop) => {
    const { crop } = this.state;
    const cropHeight = crop.height === undefined ? 100 : crop.height;
    const cropWidth = crop.width === undefined ? 100 : crop.width;
    
    const centeredCrop = {
      ...crop,
      x: Math.floor((100 - cropWidth)/2),
      y: Math.floor((100 - cropHeight)/2)
    }

    const centeredPixelCrop = {
      ...pixelCrop,
      x: Math.floor((image.naturalWidth - pixelCrop.width)/2),
      y: Math.floor((image.naturalHeight - pixelCrop.height)/2)
    }
    
    this.updateCropState(centeredCrop, centeredPixelCrop);
  }

  render() {
    const { image } = this.props;
    
    if (!image || !image.preview) return null;
        
    return (
      <StyledCropArea>
        <p>Bildausschnitt wählen:</p>
        <ReactCrop
          minHeight={5}
          minWidth={5}
          onChange={this.onChange}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.updateCropState}
          src={image.preview}
          crop={this.state.crop}
          keepSelection={true}
        />
      </StyledCropArea>
    );
  }
}

export default CropArea;