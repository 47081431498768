import validator from 'validator';
import * as ErrorMessages from './errorMessages.js';

export const required = text => {
  if (text && text.length) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
}

export const mustMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] === text ? null : ErrorMessages.mustMatch;
  }
}

export const mustNotMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] !== text ? null : ErrorMessages.mustNotMatch;
  }
}

export const minLength = length => {
  return text => {
    return text.length >= length ? null : ErrorMessages.minLength(length);
  }
}

export const hasLength = length => {
  return text => {
    return text.length === length ? null : ErrorMessages.isInvalid;
  }
}

// Check if a date corresponds to format Number/Number
export const isExpiryDate = date => {
  if (date && date.length) {
    const dateUnits = date.split('/')
    if (dateUnits.length === 2 && validator.isNumeric(dateUnits[0]) && validator.isNumeric(dateUnits[1])) {
      return null
    }
  }
  return ErrorMessages.isInvalid;
}

export const isEmail = email => {
  if (validator.isEmail(email)) {
    return null
  } else {
    return ErrorMessages.isInvalid;
  }
}

export const isCreditCard = cardNumber => {
  if (cardNumber && cardNumber.length) {
    const number = cardNumber.replace(/\s/g,'')
    if (validator.isCreditCard(number)) return null
  }
  return ErrorMessages.isInvalid;
}

export const isChecked = checkbox => {
  if (checkbox) {
    return null
  }
  return ErrorMessages.isChecked;
}