import React from 'react'

import { SectionChildren, SearchableContainer, Button } from 'components/common/StyledComponents'
import { SimpleAccordion as Accordion, Panel } from 'components/common/Accordion'
import createSearchable from 'components/common/createSearchable'
import faqData from './faqData'
import { createFilter } from 'components/Search'
import reactToString from 'modules/reactToString'
import FaqPdf from 'documents/Faq.pdf'

const getFilteredData = (searchTerm, data) => {  
  if (!searchTerm) {
    return { relevantSections: data, activeKey: null }
  } 
  
  // Convert each subsection's text into string
  const sections = data.map(_section => {
    const section = {..._section}
    section.children = section.children.map(subSection => {
      subSection.stringText = reactToString(subSection.text)
      return subSection
    })
    return section
  })
  // Find all relevant Sections (those with any matched search keys)
  let relevantSections = sections.filter(createFilter(searchTerm, ['title', 'children.stringText', 'children.title']))

  const activeSections = {}
  
  relevantSections = relevantSections.map(_section => {
    const section = {..._section}
    // Find active Subsections and order them by parent Section
    const subSectionKeys = section.children.filter(createFilter(searchTerm, 'stringText')).map(c => c.key.toString())
    activeSections[section.key.toString()] = subSectionKeys
    // Find relevant children with matched search keys
    section.children = section.children.filter(createFilter(searchTerm, ['title', 'stringText']))
    return section
  })
  return { relevantSections, activeKey: activeSections }
}

const FaqView = ({ activeKey, content, onAccordionChange }) => {
  const activeSections = activeKey ? Object.keys(activeKey) : undefined
  
  return (
    <div>
      <SearchableContainer className='searchableContainer'>
        <Accordion activeKey={activeSections} onChange={onAccordionChange}>
          {content.map(({ key, title, children }) => (
            <Panel key={key} header={<div>{key}. {title}</div>} className='section-header'>
              <SectionChildren>
                {children.map((item, i) => {
                  if (React.isValidElement(item) || typeof item === 'string') {
                    return <li key={i}>{item}</li>
                  } else if (typeof item === 'object' && 'title' in item && 'text' in item) {
                    const activeSubSections = (activeKey && key in activeKey) ? activeKey[key] : undefined
                    return (
                      <li data-key={item.key} key={i} className='with-border'>
                        <Accordion
                          activeKey={activeSubSections}
                          onChange={active => onAccordionChange(active, key)}>
                          <Panel key={item.key} header={item.title} className='subsection-header'>
                            {item.text}
                          </Panel>
                        </Accordion>
                      </li>
                    )
                  }
                  return null
                })}
              </SectionChildren>
            </Panel>
          ))}
        </Accordion>
      </SearchableContainer>
      <a href={FaqPdf} target='_blank' rel="noopener noreferrer"><Button wide>FAQ als PDF öffnen</Button></a>
    </div>
  );
}

export default createSearchable({
  title: 'FAQ',
  data: faqData,
  filterData: getFilteredData,
  activeKeyIsObject: true
})(FaqView);
