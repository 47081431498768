import { createGlobalStyle } from 'styled-components';
import vars from './variables';

const { gray, green, lightGray, lightestGray } = vars.colors;

export const fontFace = (name, src, fontWeight = '400', fontStyle = 'normal') => `
  @font-face {
    font-family: "${name}";
    src: url(${require('./fonts/' + src + '.woff2')}) format('woff2'),
         url(${require('./fonts/' + src + '.woff')}) format('woff');
    font-style: ${fontStyle};
    font-weight: ${fontWeight};
  }
`;

/* eslint no-unused-expressions: 0 */

export const GlobalStyles = createGlobalStyle`
  ${fontFace('swiss_721', 'swiss_721_italic', '400', 'italic')}
  ${fontFace('swiss_721', 'swiss_721')}
  ${fontFace('swiss_721', 'swiss_721_light_italic', '200', 'italic')}
  ${fontFace('swiss_721', 'swiss_721_light', '200')}
  ${fontFace('swiss_721', 'swiss_721_thin_italic', '100', 'italic')}
  ${fontFace('swiss_721', 'swiss_721_thin', '100')}

  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 16px;
  }
    
  body {
    font-family: 'swiss_721', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: ${gray};
    line-height: 1.3;
  }
  
  a {
    color: ${green};
    text-decoration: none;
    &.unstyled {
      color: inherit;
    }
  }
  
  button {
    font-family: inherit;
    border: 0;
  }
  
  img {
    max-width: 100%;
  }
  
  .container {
    max-width: 100%;
    margin: 0 auto;
    overflow: auto;
    position: relative;
    min-height: calc(100vh - ${vars.main.headerHeight}px);
  }
  
  input {
    &[type="text"], &[type="email"], &[type="number"], &[type="password"], &[type="search"] {
      appearance: none;
      border-radius: 0;
    }
  }
  
  .list-item-title {
    line-height: 1.3;
    padding: 6px 0;
    .info-link {
      display: none;
    }
  }
  
  .ListItem {
    font-size: .85rem;
    line-height: 2.2;
    border-bottom: 1px solid ${lightGray};
    padding: 1em 0;
    user-select: none;
    
    & & {
      border: 0;
      padding: 0 0 0 21px;
    }
    
    &:last-child {
      border-bottom: none;
    }
    
    &, a {
      color: ${gray};
      text-decoration: none;
    }
    
    > button.unstyled {
      padding: 0;
      outline: 0;
      cursor: pointer;
    }
    
    &.unavailable {
      color: ${lightGray};
      svg path {
        stroke: ${lightGray};
      }
    }
    
    svg {
      line-height: 1;
      position: relative;
      top: -0.125em;
      & + span, & + a {
        padding-left: 5px;
      }
    }
  }
  
  .sidebar-library .ListItem {
    cursor: pointer;
  }
  
  /* Search form in a sidebar */
  
  .sidebar .search-form {
    background: ${lightestGray};
    border: 0;
  }
  
  /* Accordion */
  
  .has-expanded-items > div:not(.rc-collapse-item-active) {
    color: ${lightGray};
    
    svg path {
      stroke: ${lightGray};
    }
  }
  
  .rc-collapse-item-active > .list-item-title,
  .rc-collapse-item-active > .rc-collapse-header {
    .info-link {
      display: inline-block;
    }
  }
  
  .rc-collapse-item-active > .rc-collapse-header > div {
    position: relative;
    padding-right: 30px;
    
    &:after {
      content: "";
      background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cpath%20d%3D%22M12.64%203.1L3%2012.72M3%203.1l9.64%209.63%22%20stroke%3D%22%23979797%22%20fill%3D%22%23D8D8D8%22%20fill-rule%3D%22evenodd%22/%3E%0A%3C/svg%3E);
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      height: 14px;
      width: 14px;
      cursor: pointer;
    }  
  }
  
  .rc-collapse-anim-active {
    transition: height .3s ease-out;
  }
  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    cursor: pointer;
    outline: 0;
  }
  .rc-collapse-content {
    overflow: hidden;
  }
  .rc-collapse-content-inactive {
    display: none;
  }
  
  /* Change placeholder color */
  
  ::-webkit-input-placeholder {
    color: ${lightGray};
  }
  :-moz-placeholder {
    color: ${lightGray};
    opacity: 1;
  }
  ::-moz-placeholder {
    color: ${lightGray};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${lightGray};
  }
  
  /* Helpers */
  
  .semibold {
    font-weight: 500;
  }
  
  .text-center { text-align: center; }
  .text-left { text-align: left; }
  .text-right { text-align: right; }

  .lightGray {
    color: ${lightGray};
  }

  mark {
    background-color: ${green} !important;
  }
  
  /* Headlines */
  
  h1 {
  	color: ${green};
  }
  h1, h2, h3, h4, h5, h6 {
  	font-weight: 200;
  }
  h4 {
    padding-left: 20px;
    font-weight: 500;
    font-size: .85rem;
    &.form-title {
      margin-bottom: .5rem;
    }
  }

  /* react-mouse-select */

  .mouse-select__frame {
    z-index: 9999;
    border: 1px dashed #999;
}
`;
