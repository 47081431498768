import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from 'components/common/StyledComponents';
import { cropToFile } from 'utils/cropImage';
import CropArea from './CropArea';
import DropFile from './DropFile';

import { resetFiltersState } from 'actions/filterActions';
import { resetFormState, updateFormData, updateFormStatus } from 'actions/formActions';
import { createNewItem } from 'actions/itemActions';
import { addNotification } from 'actions/notificationActions';
import { addPreference } from 'actions/preferenceActions';

class StepThree extends Component {
  onChange = (thumbnail) => {
    const { updateFormStatus, updateFormData, id, formId } = this.props;
    updateFormStatus(formId, id, true);
    updateFormData(formId, { thumbnail });
  };

  onClick = () => {
    if (this.props.formData.thumbnail) {
      this.sendCreateItemRequest();
    } else {
      this.props.addNotification({
        type: 'warning',
        text: 'Du hast dem Objekt kein Vorschaubild zugewiesen',
        title: 'Kein Vorschaubild zugewiesen',
        dismissButton: 'Vorschaubild wählen',
        actionButton: 'Objekt ohne Vorschaubild importieren',
        onAction: this.sendCreateItemRequest,
        modal: true,
      });
    }
  };

  sendCreateItemRequest = async () => {
    const {
      formData,
      createNewItem,
      addPreference,
      filters,
      history,
      formId,
      resetFormState,
      resetFiltersState,
    } = this.props;
    let { thumbnail, file, crop, ...details } = formData;

    // Crop the thumbnail
    if (thumbnail && crop) {
      thumbnail = await cropToFile(thumbnail.preview, crop, thumbnail.name);
    }

    // Delete preview from the file
    if (file && file.preview) delete file['preview'];

    // Prepare the file for submission
    const itemData = new FormData();

    if (details.itemType !== 'analog') {
      itemData.append('file', file);
    }

    itemData.append('thumbnail', thumbnail);
    itemData.append('primaryFilterId', filters.primary);
    // Data may either be empty or contain literature details
    itemData.append('details', JSON.stringify(details || {}));

    // Append the selected filters to the FormData
    filters.selected.forEach((f) => itemData.append('selectedFilters[]', f));

    // Dispatch the request
    createNewItem(itemData);

    // Save filter selection
    addPreference('filters', {
      selected: filters.selected,
      primary: filters.primary,
      secondary: filters.secondary,
    });

    // Reset filters state
    resetFiltersState();

    // Reset form data
    resetFormState(formId);

    // Redirect the user
    history.push('/');
  };

  updateCropState = (crop) => {
    this.props.updateFormData(this.props.formId, { crop });
  };

  render() {
    const { thumbnail } = this.props.formData;
    const buttonText = `Weiter - Import ${thumbnail ? '' : 'ohne Vorschaubild'} abschließen`;

    return (
      <div className="import-form">
        {thumbnail && <CropArea onChange={this.updateCropState} image={thumbnail} />}

        <DropFile
          file={thumbnail}
          accept={{ 'image/*': [], 'application/pdf': [] }}
          onChange={this.onChange}
        />

        <Button wide onClick={this.onClick}>
          {buttonText}
        </Button>
      </div>
    );
  }
}

StepThree.propTypes = {
  updateFormStatus: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  resetFormState: PropTypes.func.isRequired,
  createNewItem: PropTypes.func.isRequired,
  resetFiltersState: PropTypes.func.isRequired,
  filters: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formData: PropTypes.object.isRequired,
  controls: PropTypes.array,
};

const mapStateToProps = ({ form, filters }, ownProps) => ({
  filters,
  formData: get(form[ownProps.formId], 'data', {}),
});

const ConnectedStepThree = connect(mapStateToProps, {
  createNewItem,
  updateFormData,
  updateFormStatus,
  resetFormState,
  resetFiltersState,
  addPreference,
  addNotification,
})(StepThree);

export default withRouter(ConnectedStepThree);
