import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import InfoField from 'components/common/InfoField';
import { ButtonLink, StepNavBar, StepNavItem, Wrapper } from 'components/common/StyledComponents';
import { FacebookIcon, Logo } from 'components/icons';
import vars from 'variables';

const SocialLinks = styled.div`
  a {
    display: inline-block;
    margin-right: 20px;
    &:hover svg path {
      fill: ${vars.colors.green};
    }
  }
`;

const LogoTitle = styled.div`
  display: inline;
  font-size: 1rem;
  font-weight: 300;
  span {
    font-weight: 500;
  }
  svg {
    vertical-align: -10%;
  }
`;

const Contact = (props) => {
  return (
    <Wrapper fixed>
      <Helmet>
        <title>Kontakt</title>
      </Helmet>
      <StepNavBar>
        <StepNavItem>
          <span>Kontakt</span>
        </StepNavItem>
      </StepNavBar>
      <InfoField margin={3} white={false}>
        <LogoTitle>
          <Logo height={15} width={15} viewBox="0 0 111 111" /> archi<span>cloud</span> e.K.
        </LogoTitle>
      </InfoField>
      <InfoField margin={3} labelClassname="semibold" white={false} label="Adresse">
        <span>
          Silberburgstraße 66a
          <br />
          70176 Stuttgart
          <br />
          mail@archicloud.net
          <br />
          0711 88869859
        </span>
      </InfoField>
      <InfoField margin={3} labelClassname="semibold" white={false} label="Allgemeines">
        <span>
          Registernummer: HRA 732993
          <br />
          Registergericht: Amtsgericht Stuttgart
          <br />
          Umsatzsteuer-Identikationsnummer: DE301231110
          <br />
          Inhaber und inhaltlich verantwortlich: Achim Wangler
        </span>
      </InfoField>
      <InfoField labelClassname="semibold" white={false} label="News & Feedback">
        <SocialLinks>
          <a href="https://www.facebook.com/archicloud.net">
            <FacebookIcon width={8} height={15} viewBox="0 0 17 31" />
          </a>
        </SocialLinks>
      </InfoField>
      <ButtonLink to="/feedback">Feedback</ButtonLink>
    </Wrapper>
  );
};

export default Contact;
