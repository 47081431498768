import bytes from 'bytes';
import { YesIconSmall as YesIcon, NoIconSmall as NoIcon } from 'components/icons';
import { AccountTypes } from 'translations/de'
import { isLessThanMinutesAgo, formatDate } from 'utils/common';

export const getPrettyUserList = (users, extended) => {
  return users.map(u => {
    const isOnline = isLessThanMinutesAgo(u.last_active);
    
    let user = {
      ...u,
      accountType: AccountTypes[u.accountType],
      isOnline,
      onlineStatus: isOnline ? YesIcon : NoIcon
    }
    
    if (extended) {
      user = {
        ...user,
        lastActive: u.last_active ? formatDate(u.last_active) : null,
        accountInitComplete: u.isActive ? YesIcon : NoIcon,
        subscriptionType: u.hasProAccount ? "Pro" : "Free",
        usedSpace: bytes(u.usedSpace, {unitSeparator: ' '})
      }
    }
    
    return user
  })
}