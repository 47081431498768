import React from "react";
import Slider from "components/Slider/Slider";

const ItemsPreview = ({ items }) => (
  <Slider
    showNav={true}
    showArrows={true}
    showTitle={true}
    items={items}
    isImageSlider={true}
  />
);

export default ItemsPreview;
