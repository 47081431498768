import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import createModal from 'components/Modal/createModal'; 
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import InputField from 'components/common/InputField';
import { addNewFilter } from 'actions/filterActions';
import validateInput from 'modules/validations/addFilter';

class AddFilter extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      filterName: '',
      isLoading: false,
      isInvalid: false,
      errors: {}
    }
  }
  
  isValid(data = this.state) {
    const { errors, isValid } = validateInput(data);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }
  
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }
  
  onSubmit = e => {
    e.preventDefault();
    const {parentId, addNewFilter, onSuccess} = this.props;
    
    const data = {
      filterName: this.state.filterName,
      parentId
    }
    
    if (this.isValid(data)) {
      this.setState({ errors: {}, isLoading: true });
      
      addNewFilter(data)
      .then(() => {        
        this.setState({
          isLoading: false,
          filterName: ''
        });
        if (onSuccess) onSuccess();
      })
      .catch(error => {
        if (error.response) {
          this.setState({ errors: error.response.data, isLoading: false });
        } else {
          console.log(error);
        }
      });
    }
  }
  
  render() {
    const { filterName, isLoading, isInvalid, errors } = this.state;
    
    return (
      <form onSubmit={this.onSubmit}>
        <ContentWrapper>
          <InputField
            gray
            autoFocus
            error={errors.filterName}
            onChange={this.onChange}
            value={filterName}
            name="filterName"
            placeholder="neuen Filternamen eingeben"
          />
        </ContentWrapper>
        <Button wide disabled={isLoading || isInvalid}>Hinzufügen</Button>
      </form>
    );
  }
}

AddFilter.propTypes = {
  parentId: PropTypes.number.isRequired,
  addNewFilter: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

const AddFilterModal = createModal(AddFilter)

export default connect(null, {addNewFilter})(AddFilterModal);
