import axios from "axios";

export const GET_TEAM_USERS = "GET_TEAM_USERS/team";

export const getTeamUsers = () => dispatch =>
  axios.get("/api/teams").then(res => {
    const { users } = res.data;
    if (users && users.length > 0) {
      dispatch({
        type: GET_TEAM_USERS,
        users
      });
    }
  });

export const createTeamUser = data => dispatch =>
  axios.post("/api/teams", data).then(res => {
    // Update the list of users
    dispatch(getTeamUsers());
  });
  
export const updateTeamUser = (userId, data) => dispatch =>
  axios.post(`/api/teams/${userId}`, data).then(res => {
    // Update the list of users
    dispatch(getTeamUsers());
  });

export const deleteTeamUsers = data => dispatch =>
  axios.delete("/api/teams", {
    params: data
  }).then(res => {
    // Update the list of users
    dispatch(getTeamUsers());
    
    return res
  });
