import { omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import vars from 'variables';
import { Column, Row } from '../../external/hedron/src/index';

const { sm, md } = vars.media;
const { gutter } = vars.main;

// parentBindings is a prop added by react-scroll to be able to scroll to the container

const OmitStyles = {
  Col: ['alignSelf', 'mt', 'mb', 'ml', 'mr', 'order', 'smOrder', 'mdOrder', 'maxWidth'],
  Container: ['pt', 'pb', 'fixed', 'parentBindings'],
};

export const Col = styled((props) => <Column {...omit(props, OmitStyles.Col)} />)`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'auto')};
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  ${(props) => props.ml && `margin-left: ${props.ml};`};
  ${(props) => props.mr && `margin-right: ${props.mr};`};
  order: ${(props) => props.order};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
  @media (min-width: ${sm}) {
    order: ${(props) => props.smOrder};
  }
  @media (min-width: ${md}) {
    order: ${(props) => props.mdOrder};
  }
`;

Col.defaultProps = {
  fluid: true,
  sm: 6,
  order: 0,
  smOrder: 0,
  mdOrder: 0,
};

export const Container = styled((props) => <Row {...omit(props, OmitStyles.Container)} />)`
  padding-top: ${(props) => (props.pt ? props.pt : 0)};
  padding-bottom: ${(props) => (props.pb ? props.pb : 0)};

  @media (min-width: ${md}) {
    padding-left: ${(props) => (props.fixed ? '7.5%' : 0)};
    padding-right: ${(props) => (props.fixed ? '7.5%' : 0)};
  }

  &.mb-mobile {
    > div {
      margin-bottom: ${gutter}px;
      &:last-child {
        margin-bottom: 0;
      }
      @media (min-width: ${sm}) {
        margin-bottom: 0;
      }
    }
  }

  &.padded > div {
    @media (min-width: ${sm}) {
      padding: 0 ${gutter}px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.white {
    background-color: #fff;
  }
  &.gray {
    background-color: ${vars.colors.lightestGray};
  }
`;
