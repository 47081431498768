import styled from 'styled-components';
import vars from 'variables';

export const StyledFlashMessagesList = styled.div`
  position: fixed;
  width: 100%;
  top: ${vars.main.headerHeight}px;
  left: 0;
  z-index: 9999;
`;

export const StyledFlashMessage = styled.div`
  z-index: 1001;
  position: relative;
  background: #fff;
  width: 100%;
  width: calc(100% - ${vars.main.gutter*2}px);
  margin: ${vars.main.gutter}px auto;
  padding: 7px 35px 7px 15px;
  box-shadow: 0 0 2px 1px rgb(222, 222, 222);
  font-size: .85rem;
  
  button {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    outline: none;
    appearance: none;
    box-shadow: none;
    border: 0;
  }
`;