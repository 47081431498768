import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vars from 'variables';
import { CloseIcon } from 'components/icons/';

const ModalContainer = styled.div`
  max-width: 450px;
  width: 100%;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  padding: ${vars.main.gutter*2}px;
  
  .modal-text {
    margin-bottom: 35px;
  }
  
  .modal-controls-inline {
    display: flex;
    flex-flow: row-reverse;
    button {
      margin: 0 0 0 5px;
    }
    button + button {
      margin: 0 5px 0 0;
    }
  }
`;

const ModalTitle = styled.div`
  font-size: 1.25rem;
  color: ${vars.colors.gray};
  text-transform: uppercase;
  margin-bottom: 15px;
  line-height: 1.3;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.1);
  z-index: 999;
  transition: opacity .5s ease-in;
  pointer-events: auto;
  overflow-y: auto;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
  line-height: 1;
`;

class Modal extends Component {
  
  hideOnOuterClick(e){
    if (this.props.isModal) return;
    if (e.target.dataset.modal) this.props.onClose(e);
  }
  
  handleKeyDown = (e) => {
    if (this.props.isModal) return;

    // handle Esc key
    if (e.keyCode === 27) {
      e.preventDefault();
      this.props.onClose(e)
    }
  }
  
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
 
  render() {
    const { isModal, isOpen } = this.props
    if (isOpen === false) return null;
    
    return (
      <ModalWrapper
        onClick={e => this.hideOnOuterClick(e)}
        data-modal="true">
        <ModalContainer>
          {!isModal &&
          <CloseButton onClick={() => this.props.onClose()}>
            <CloseIcon height={20} width={20} viewBox='0 0 16 16' />
          </CloseButton>}
          {this.props.title && <ModalTitle>{this.props.title}</ModalTitle>}
          {this.props.children}
        </ModalContainer>
      </ModalWrapper>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isModal: PropTypes.bool
}

Modal.defaultProps = {
  isOpen: false
}

export default Modal;
