import React from 'react';
import { connect } from 'react-redux';

import { SplitPaneWrapper, Wrapper } from 'components/common/StyledComponents';
import MultiStep from 'components/MultiStep';
import ItemsInfo from './ItemsInfo';
import ItemsFilter from './ItemsFilter';
import ItemsDescription from './ItemsDescription';
import LiteratureDetails from './LiteratureDetails';
import { getLiteratureFilterId } from '../../../selectors';

const FORM_KEY = 'properties'

const ItemsProperties = ({ items, history, canEdit, literatureFilterId }) => {
  const props = { canEdit, items }
  
  let infoComponent = <ItemsInfo {...props} />

  if (items.length === 1 && items[0].primaryFilterId === literatureFilterId) {
    infoComponent = <LiteratureDetails {...props} history={history} item={items[0]} />
  }
  
  const steps = [
    {
      name: 'Info',
      component: infoComponent
    },
    {
      name: items.length > 1 ? 'Gemeinsame Filter' : 'Filter',
      component: <ItemsFilter {...props} />
    },
    {
      name: 'Beschreibung',
      component: <ItemsDescription {...props} />
    }
  ];
  
  return (
    <SplitPaneWrapper>
      <Wrapper>
        <MultiStep
          formId={FORM_KEY}
          isAlwaysValid={true}
          navClassName='step-navitem--splitpane'
          steps={steps} />
      </Wrapper>
    </SplitPaneWrapper>
  );
}

const mapStateToProps = state => ({
  canEdit: state.auth.user.canEdit,
  literatureFilterId: getLiteratureFilterId(state)
})

export default connect(mapStateToProps)(ItemsProperties)