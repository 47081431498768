import { get, isEmpty } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';

import { updateItem } from 'actions/itemActions';
import { addNotification } from 'actions/notificationActions';
import InfoField from 'components/common/InfoField';
import InputField from 'components/common/InputField';
import SelectField from 'components/common/SelectField';
import { Button, ControlsRow, FormGroup, HalfColumn } from 'components/common/StyledComponents';
import validate from 'modules/validations/itemDetails';
import { getPossibleLocations } from 'selectors';
import { formatDate } from 'utils/common';

class LiteratureDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      title: '',
      author: '',
      publisher: '',
      year: '',
      itemType: '',
      errors: {},
    };
  }

  validateInput() {
    const errors = validate(this.state);
    this.setState({ errors });
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.validateInput();
    });
  };

  updateItemState(item) {
    const details = { ...item.details };

    // Convert the 'digital' column in item's details into itemType parameter
    if (details.hasOwnProperty('digital')) {
      details.itemType = details.digital ? 'digital' : 'analog';
      delete details['digital'];
    } else {
      // Default item type is going to be "digital"
      details.itemType = 'digital';
    }

    // Remove null entries
    Object.keys(details).forEach((k) => details[k] == null && delete details[k]);

    this.setState(details);
  }

  UNSAFE_componentWillMount() {
    this.updateItemState(this.props.item);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item.details !== this.props.item.details) {
      this.updateItemState(nextProps.item);
    }
  }

  sendUpdateRequest = (data, cb) => {
    const { title, author, publisher, year, itemType, errors, location } = this.state;
    const { updateItem, item } = this.props;

    // Extract relevant details
    const details = data || { title, author, publisher, year, itemType, location };

    if (isEmpty(errors)) {
      this.setState({ isLoading: true });

      updateItem(item.id, { details })
        .then(() => {
          this.setState({ isLoading: false });
          if (cb) cb(true);
        })
        .catch((err) => {
          const errors = get(err, 'response.data.errors', {});
          this.setState({ isLoading: false, errors });
        });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { item, addNotification } = this.props;
    const { itemType, title } = this.state;

    // If the user is attempting to change item's type from digital into analog
    if (item.details.digital && item.hasFile && itemType !== 'digital') {
      addNotification({
        modal: true,
        type: 'warning',
        title: 'Hinterlegte Datei löschen',
        text: `Du hast zum Titel "${title}" eine Datei hinterlegt die im Format "${itemType}" nicht mehr zur Verfügung steht. Möchtest du die Datei trotzdem löschen?`,
        dismissButton: 'Nein',
        actionButton: 'Ja',
        onAction: this.sendUpdateRequest,
        inlineControls: true,
      });
    } else {
      this.sendUpdateRequest();
    }
  };

  handleManageFileClick = () => {
    const { itemType } = this.state;
    const { item, history } = this.props;
    const redirectUrl = `/action/${item.id}/file`;

    // Check if the user has updated item's type but didn't save it yet
    if (!item.details.digital && itemType === 'digital') {
      // Save the new item type if that's the case
      this.sendUpdateRequest(
        { itemType },
        // Redirect on success
        () => history.push(redirectUrl)
      );
    } else {
      history.push(redirectUrl);
    }
  };

  renderItemType() {
    const { itemType, location } = this.state;
    let { locations, canEdit } = this.props;
    const placeholder = locations.length > 0 ? 'Standort auswählen' : 'kein Standort';

    if (location) {
      const locationKey = location.split('.')[0];
      // Remove locations with the same location key from the option list
      locations = locations.filter((l) => l.split('.')[0] !== locationKey);
      // Make sure current value is always in the list of locations
      locations = [...locations, location];
    }

    const itemTypeSelector = (
      <SelectField
        gray
        disabled={!canEdit}
        onChange={this.onChange}
        label="Format"
        moreInfo="/faq/6:h"
        name="itemType"
        options={['analog', 'digital']}
        value={itemType}
      />
    );

    if (itemType !== 'analog') return itemTypeSelector;

    return (
      <ControlsRow className="inline">
        <HalfColumn>{itemTypeSelector}</HalfColumn>
        <HalfColumn>
          <SelectField
            gray
            onChange={this.onChange}
            label="Standort"
            moreInfo="/faq/6:i"
            name="location"
            options={locations}
            value={location}
            disabled={locations.length === 0 || !canEdit}
            placeholder={location ? undefined : placeholder}
          />
        </HalfColumn>
      </ControlsRow>
    );
  }

  render() {
    const { canEdit } = this.props;
    const { updatedAt, updatedBy, hasFile } = this.props.item;
    const { title, author, publisher, year, isLoading, errors, itemType } = this.state;

    const updatedByFormatted = updatedBy ? `; ${updatedBy}` : '';

    return (
      <form onSubmit={this.onSubmit}>
        <InputField
          gray
          disabled={!canEdit}
          onChange={this.onChange}
          value={title}
          name="title"
          label="Titel"
          placeholder="Titel"
          error={errors.title}
        />
        <InputField
          gray
          disabled={!canEdit}
          onChange={this.onChange}
          value={author}
          name="author"
          label="Autor"
          placeholder="Autor"
        />

        <ControlsRow className="inline">
          <HalfColumn>
            <InputField
              gray
              disabled={!canEdit}
              onChange={this.onChange}
              value={publisher}
              name="publisher"
              label="Verlag"
              placeholder="Verlag"
            />
          </HalfColumn>
          <HalfColumn>
            <InputField
              gray
              disabled={!canEdit}
              onChange={this.onChange}
              value={year}
              error={errors.year}
              name="year"
              type="number"
              label="Erscheinungsjahr"
              placeholder="Erscheinungsjahr"
            />
          </HalfColumn>
        </ControlsRow>

        {this.renderItemType()}

        <InfoField label="letzte Änderung">{formatDate(updatedAt) + updatedByFormatted}</InfoField>

        {canEdit && (
          <ControlsRow>
            <HalfColumn>
              <FormGroup>
                <Button wide type="submit" disabled={isLoading || !isEmpty(errors)}>
                  Änderungen speichern
                </Button>
              </FormGroup>
            </HalfColumn>
            <HalfColumn>
              <FormGroup>
                <Button
                  wide
                  type="button"
                  disabled={itemType !== 'digital' || isLoading}
                  onClick={this.handleManageFileClick}
                >
                  {hasFile ? 'Datei ersetzen' : 'Datei hinzufügen'}
                </Button>
              </FormGroup>
            </HalfColumn>
          </ControlsRow>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locations: getPossibleLocations(state, ownProps.item.filters),
});

export default connect(mapStateToProps, { updateItem, addNotification })(LiteratureDetails);
