import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { userLogout } from 'actions/authActions';
import { toggleSidebar } from 'actions/sidebarActions';

import { Accordion, Panel } from 'components/common/Accordion';
import { ImportIcon, InfoIcon, LogoutIcon, SettingsIcon } from 'components/icons/';
import { SidebarWrapper } from './sidebarStyles';

const allSettings = [
  // {
  //   title: "Aboverwaltung",
  //   url: "/subscription"
  // },
  {
    title: 'Nutzerverwaltung',
    url: '/settings',
  },
  {
    title: 'Filterverwaltung',
    url: '/filters',
  },
  {
    title: 'Feedback',
    url: '/feedback',
  },
];

const superAdminSettings = [
  {
    title: 'Kundenverwaltung',
    url: '/customers',
  },
];

const infos = [
  {
    title: 'Speicher',
    url: '/space',
  },
  {
    title: 'FAQ',
    url: '/faq',
  },
  {
    title: 'AGB',
    url: '/agb',
  },
  {
    title: 'Datenschutz',
    url: '/datenschutz',
  },
  {
    title: 'Kontakt',
    url: '/contact',
  },
];

const SidebarPrimary = ({ toggleSidebar, userLogout, hasProAccount, accountType }) => {
  const logout = (e) => {
    e.preventDefault();
    toggleSidebar();
    userLogout();
  };

  const settingsTitle = (
    <div>
      <SettingsIcon />
      <span>Einstellungen</span>
    </div>
  );

  const infoTitle = (
    <div>
      <InfoIcon />
      <span>Info</span>
    </div>
  );

  const getLinks = (title, items) => (
    <Accordion className="ListItem">
      <Panel header={title}>
        {items.map((item, k) => (
          <div className="ListItem" key={k}>
            <Link to={item.url ? item.url : '#'}>{item.title}</Link>
          </div>
        ))}
      </Panel>
    </Accordion>
  );

  const getSettings = () => {
    switch (accountType) {
      case 'superadmin':
        return [...allSettings, ...superAdminSettings];
      case 'admin':
        return allSettings;
      case 'standard':
        // Hide subscription management from "standard" accounts
        return allSettings.filter((i) => i.title !== 'Aboverwaltung');
      case 'simple':
        // Hide subscription/filter management from "simple" accounts
        return allSettings.filter(
          (i) => i.title !== 'Aboverwaltung' && i.title !== 'Filterverwaltung'
        );
      default:
        return [];
    }
  };

  return (
    <SidebarWrapper>
      <div>
        <div className="ListItem">
          <button className="unstyled" onClick={logout}>
            <LogoutIcon />
            <span>Abmelden</span>
          </button>
        </div>
        {accountType !== 'simple' && (
          <div className="ListItem">
            <Link to="/import">
              <ImportIcon />
              <span>Import</span>
            </Link>
          </div>
        )}
        {getLinks(settingsTitle, getSettings())}
        {getLinks(infoTitle, infos)}
      </div>
      {/* {!hasProAccount && <AdBanner />} */}
    </SidebarWrapper>
  );
};

SidebarPrimary.propTypes = {
  userLogout: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { hasProAccount, accountType } = state.auth.user;
  return { hasProAccount, accountType };
};

export default connect(mapStateToProps, { userLogout, toggleSidebar })(SidebarPrimary);
