import axios from 'axios';
import { addNotification } from './notificationActions';

export const submitFeedback = data => (
  dispatch => {
    return axios.post('/api/mail/feedback', data)
    .then(res =>
      dispatch(addNotification({
        type: 'success',
        title: 'Feedback versandt',
        text: 'Vielen Dank für deine Hinweise.',
        dismissButton: 'Ok',
        modal: true
      }))
    )
    .catch(err => {
      const message = err.response && err.response.data.message;
      
      dispatch(addNotification({
        type: 'error',
        text: message || 'Ein unbekannter Fehler ist beim Abschicken der Feedback-Form aufgetreten'
      }));
      
      return Promise.reject();
    })
  }
)