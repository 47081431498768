import validator from 'validator';
import { isEmpty, isString } from 'lodash';

export default data => {
  let errors = {};
  
  const { projectNumber, projectTitle, filterName } = data
  
  if (isString(projectNumber) && projectNumber.length > 0 && !validator.isNumeric(projectNumber)) {
    errors.projectNumber = 'Die Projektnummer muss nur aus Ziffern bestehen';
  }
  
  if (isString(projectTitle) && validator.isEmpty(projectTitle)) {
    errors.projectTitle = 'Dieses Feld muss ausgefüllt werden';
  }
  
  if (isString(filterName) && validator.isEmpty(filterName)) {
    errors.filterName = 'Dieses Feld muss ausgefüllt werden';
  }
  
  return {
    errors, 
    isValid: isEmpty(errors)
  }  
}
