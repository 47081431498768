export const ADD_ITEM_TO_LIBRARY = 'ADD_ITEM_TO_LIBRARY/library';
export const ADD_ITEMS_TO_LIBRARY = 'ADD_ITEMS_TO_LIBRARY/library';
export const DELETE_ITEMS_FROM_LIBRARY = 'DELETE_ITEMS_FROM_LIBRARY/library';
export const TOGGLE_LIBRARY_ITEM = 'TOGGLE_LIBRARY_ITEM/library';
export const SELECT_LIBRARY_ITEMS = 'SELECT_LIBRARY_ITEMS/library';
export const APPEND_SELECTED_LIBRARY_ITEMS = 'APPEND_SELECTED_LIBRARY_ITEMS/library';
export const DESELECT_LIBRARY_ITEMS = 'DESELECT_LIBRARY_ITEMS/library';
export const UPDATE_ITEM_STATE = 'UPDATE_ITEM_STATE/library';

export const addItemToLibrary = item => ({
  type: ADD_ITEM_TO_LIBRARY,
  item
});

export const addItemsToLibrary = ({ items }) => ({
  type: ADD_ITEMS_TO_LIBRARY,
  items
});

export const deleteItemsFromLibrary = itemIds => ({
  type: DELETE_ITEMS_FROM_LIBRARY,
  itemIds
});

export const toggleLibraryItem = itemId => ({
  type: TOGGLE_LIBRARY_ITEM,
  itemId
})

export const selectLibraryItems = itemIds => ({
  type: SELECT_LIBRARY_ITEMS,
  itemIds
})

export const appendSelectedLibraryItems = itemIds => ({
  type: APPEND_SELECTED_LIBRARY_ITEMS,
  itemIds
})

export const deselectLibraryItems = () => ({
  type: DESELECT_LIBRARY_ITEMS
})

export const updateItemInLibrary = (id, data) => ({
  type: UPDATE_ITEM_STATE,
  id,
  data
});
