import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import vars from 'variables';

const SidebarListItem = styled.div`
  font-size: .85rem;
  line-height: 2.2;
  border-bottom: 1px solid ${vars.colors.lightGray};
  padding: 1em 20px 1em;
  user-select: none;
  
  &, a {
    color: ${vars.colors.gray};
    text-decoration: none;
  }
  
  &:last-child, &.no-bb { border-bottom: none; }  
  &.no-pb { padding-bottom: 0; }
  &.no-pt { padding-top: 0; }
`;

const SidebarGuest = props => {  
  return (
    <div>
      <SidebarListItem>
        <Link to='/faq'>FAQ</Link>
      </SidebarListItem>
      <SidebarListItem className='no-bb no-pb'>
        <Link to='/agb'>AGB</Link>
      </SidebarListItem>
      <SidebarListItem className='no-pt'>
        <Link to='/datenschutz'>Datenschutz</Link>
      </SidebarListItem>
      <SidebarListItem>
        <Link to='/contact'>Kontakt</Link>
      </SidebarListItem>
    </div>
  );
}

export default SidebarGuest