export const General = {
  somethingIsWrong: "Hoppla!",
  notEnoughSpace: {
    pro: "Du musst Daten löschen um weitere Objekte importieren zu können oder weitere Lizenzen hinzufügen.",
    free: "Du musst Daten löschen um weitere Objekte importieren zu können oder zu archicloud-Pro wechseln. archicloud-Pro bietet pro Lizenz 25 GB Speicherplatz."
  }
}

export const Import = {
  fileIsTooLarge: "Die Datei, die du hochladen möchtest, ist zu groß. Objekte dürfen höchstens 500 MB groß sein.",
  fileIsInvalid: "Diese Datei ist im falschen Format.",
  fileIsNotSelected: "keine Datei ausgewählt"
}

export const AccountTypes = {
  admin: 'Administrator',
  superadmin: 'Administrator',
  standard: 'Standard',
  simple: 'Einfach'
}

export const ActionStatus = {
  paymentMethodUpdate: {
    success: 'Deine Zahlungsdaten wurden erfolgreich aktualisiert',
    error: 'Bei der Bearbeitung ist möglicherweise ein Fehler aufgetreten',
    isLoading: 'Deine Zahlungsdaten werden aktualisiert...'
  },
  signup: {
    success: 'Du hast dich erfolgreich registriert! Eine E-Mail wurde an deine Adresse gesendet. Klicke auf den enthaltenen Aktivierungslink, um deinen Account zu bestätigen!',
    error: 'Bei der Zahlung ist ein Fehler aufgetreten',
    isLoading: 'Zahlung wird bearbeitet...'
  },
  upgrade: {
    success: 'Du hast jetzt ein Pro-Abo',
    error: 'Bei der Zahlung ist ein Fehler aufgetreten',
    isLoading: 'Zahlung wird bearbeitet...'
  },
  activation: {
    title: "Registrierung abschliessen",
    success: "Dein Konto wurde erfolgreich aktiviert",
    error: "Die Aktivierung ist fehlgeschlagen"
  },
  passwordreset: {
    title: "Kennwort zurücksetzen",
    success: "Dein Kennwort wurde erfolgreich aktualisiert",
    error: "Das Kennwort konnte nicht zurückgesetzt werden"
  }
}