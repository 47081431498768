import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { updateItem } from 'actions/itemActions';
import { deselectLibraryItems } from 'actions/libraryActions';
import DropFile from 'components/Import/DropFile';
import {
  Button,
  ControlsRow,
  SectionTitle,
  SplitPaneWrapper,
} from 'components/common/StyledComponents';

class ManageFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      item: null,
    };
  }

  static propTypes = {
    items: PropTypes.array.isRequired,
  };

  onChange = (file) => this.setState({ file });

  UNSAFE_componentWillMount() {
    this.setState({
      item: this.props.items[0],
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newItem = newProps.items[0];
    const oldItem = this.props.items[0];

    if (newItem !== oldItem) {
      this.setState({ item: newItem });
    }
  }

  update = () => {
    const itemData = new FormData();
    let { file, item } = this.state;
    const { deselectLibraryItems, updateItem, history } = this.props;

    itemData.append('file', file);

    // Dispatch the request
    updateItem(item.id, itemData, 'file').then(() => {
      deselectLibraryItems();
      history.push('/');
    });
  };

  render() {
    const { file, item } = this.state;

    return (
      <SplitPaneWrapper>
        <SectionTitle>{item.hasFile ? 'Datei ersetzen' : 'Datei hinzufügen'}</SectionTitle>

        <DropFile file={file} onChange={this.onChange} />

        <ControlsRow>
          <Button wide onClick={this.update} disabled={isEmpty(file)}>
            Datei speichern
          </Button>
        </ControlsRow>
      </SplitPaneWrapper>
    );
  }
}

export default connect(null, { updateItem, deselectLibraryItems })(ManageFile);
