import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from 'variables';
import classnames from 'classnames';

const { checkboxHeight, gutter } = vars.main;

const StyledSelectListItem = styled.div`
  background-color: #fff;
  border: 1px solid ${vars.colors.lightGray};
  font-size: .85rem;
  line-height: 1.3;
  color: ${vars.colors.gray};
  text-decoration: none;  
  margin-bottom: ${gutter}px;
  padding: 3px 1.5rem 3px .75rem;
  position: relative;
  user-select: none;
  word-break: break-all;
    
  &:last-child {
    margin-bottom: 0;
  }
  
  &.borderless {
    border: none;
  }
  
  &.selectable {
    cursor: pointer;
    &:after {
      font-weight: 200;
      content: "–";
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1rem;
    }
  }
  
  &.is-selected, &.is-halfSelected {
    border-color: rgb(175, 175, 175);
  }
  
  &.is-selected:after {
    content: "+";
    right: 7.5px;
  }
  
  &.is-halfSelected:after {
    content: "–";
    right: 11px;
    transform: rotate(-45deg);
  }
  
  &.alt {
    margin-right: ${checkboxHeight + gutter}px;

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      right: -${checkboxHeight + gutter}px;
      padding: 0 ${checkboxHeight/2-1}px;
      height: ${checkboxHeight}px;
      border: 1px solid ${vars.colors.lightGray};
      background-color: ${vars.colors.lightestGray};
    }
  }
  &.alt.is-selected:after {
    border-color: rgb(175, 175, 175);
    background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cpath%20d%3D%22M14.63%201l-9%2013.63M1%207.73l4.63%206.9%22%20stroke%3D%22%237D7D7D%22%20stroke-width%3D%22.87%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22/%3E%0A%3C/svg%3E%0A);
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50%;
  }
`;

const SelectListItem = (
  {
    className,
    id,
    selected,
    halfSelected,
    selectable,
    editable,
    onChange,
    children
  }
) => {
    
  const onClick = e => {
    e.preventDefault();
    
    if (selectable && onChange) onChange(id);
  }
    
  return (
    <StyledSelectListItem
      onClick={e => onClick(e)}
      className={classnames('SelectListItem', 
        {'is-selected': selected},
        {'is-halfSelected': halfSelected},
        className, 
        {'selectable': selectable},
        {'borderless': !editable
      })}
    >
      {children}
    </StyledSelectListItem>
  );
}

SelectListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]).isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  halfSelected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  editable: PropTypes.bool,
  children: PropTypes.node.isRequired
}

SelectListItem.defaultProps = {
  selectable: true,
  selected: false,
  halfSelected: false,
  editable: true
}

export default SelectListItem;
