import React from 'react'
import AgbPdf from 'documents/Agb.pdf'
import DatenschutzPdf from 'documents/Datenschutz.pdf'

const archicloudEmail = <a href='mailto:mail@archicloud.net'>mail@archicloud.net</a>

const sections = [
  {
    title: 'Allgemeines',
    children: [
      'Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Geschäftsbeziehungen zwischen Nutzern und archicloud (nachfolgend archi genannt) für die Erbringung der Dienste archicloud-Free und archicloud-Pro (nachfolgend als archicloud zusammengefasst).',
      'Maßgeblich ist die jeweils zum Zeitpunkt des Vertragsschlusses aktuelle Fassung.',
      'archi bietet mit dem Dienst archicloud, Möglichkeiten eigene Architekturbibliotheksdateien zu importieren und zu strukturieren.',
      (
        <div>archicloud kann mittels zweier verschiedener Abonnements genutzt werden:
          <ul>
            <li>archicloud-Free (ein kostenfreies und im Umfang begrenztes Abonnement).</li>
            <li>archicloud-Pro (ein gebührenpflichtiges Abonnement, welches über Lizenzen, die Mehrbenutzerfähigkeit und die Höhe des Speicherplatzes regelt).</li>
          </ul>
        </div>
      ),
      'Voraussetzung für die Nutzung von archicloud ist die Einrichtung eines Benutzerkontos (Account) über den Befehl „Registrieren“. Die Registrierung erfordert das Akzeptieren der AGB und Hinweise zum Datenschutz.',
      'Zur Nutzung von archicloud benötigen Sie einen Internetzugang sowie ein Endgerät, das die jeweils gültigen System- und Kompatibiltätsanforderungen für die relevanten Inhalte erfüllt. Die Erfüllung der technischen Nutzungsanforderungen liegt im alleinigen Verantwortungsbereich des Kunden.',
      <div>Anbieter von archicloud und ihr Vertragspartner ist:<br /> archicloud e.K | Silberburgstr. 66a | 70176 Stuttgart | Tel.: 071188869859 | E-Mail: {archicloudEmail}.</div>
    ]
  },
  {
    title: 'Vertragsschluss',
    children: [
      <div>Mit erfolgreich abgeschlossener Registrierung kommt ein Nutzungsverhältnis zustande. Der Nutzer erhält eine Bestätigung über die Bestellung per E-Mail (Bestellbestätigung) in welcher die AGB, sowie die Hinweise zum Datenschutz, als separater Dateianhang mitversandt werden. Der Vertragstext wird von uns nicht gespeichert. Sie haben weiterhin die Möglichkeit den Inhalt der AGB und der Hinweise zum Datenschutz mithilfe der Funktion „<a href={AgbPdf} rel="noopener noreferrer" target='_blank'>AGB als PDF öffnen</a>“ oder „<a href={DatenschutzPdf} rel="noopener noreferrer" target='_blank'>Datenschutzhinweise als PDF öffnen</a>“ im Browser zu öffnen, zu speichern und zu drucken.</div>,
      'Die Vertragssprache ist Deutsch.',
      (
        <div>Der jeweilige Vertrag kommt wie folgt zustande:
          <ul>
            <li>Bei Neuregistrierungen mit Abschluss der Bestellung durch Bestätigen der Schaltfläche „Registrierung jetzt abschließen“.</li>
            <li>Bei Nutzern, die von Administratoren nachträglich hinzugefügt werden, durch Bestätigen der Einladungs-E-Mail, der Kennwortvergabe und dem Akzeptieren der Allgemeinen Geschäftsbedingungen und Datenschutzhinweise.</li>
          </ul>
        </div>
      ),
      'Das Vertragsverhältnis läuft auf unbestimmte Zeit.'
    ]
  },
  {
    title: 'Hinweis zum Widerrufsrecht',
    children: [
      (
        <div>
          Widerrufsrecht<br />
          Sie haben das Recht ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns:
          <p>archicloud e.K | Silberburgstr. 66a | 70176 Stuttgart | Tel.: +49 711 88869859 | E-Mail: {archicloudEmail}</p>
          mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
        </div>
      ),
      <div>Folgen des Widerrufs<br />Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</div>,
      <div>Weitere wichtige Hinweise:<br />Sie stimmen ausdrücklich zu, dass wir vor Ende der Widerrufsfrist mit der Ausführung der Dienstleistung beginnen.</div>
    ]
  },
  {
    title: 'Zahlung und Rechnung',
    children: [
      'Die Entgelte für einen archicloud-Pro Account sind mit Rechnungsstellung für die gesamte Laufzeit sofort zur Zahlung fällig.',
      'archi behält sich vor, das Entgelt für einen archicloud-Pro Account mit Wirkung zum Beginn eines neuen Verlängerungszeitraums, der auf den jeweils laufenden Verlängerungszeitraum folgt, angemessen zu erhöhen. In diesem Fall wird archi den Nutzer mindestens sechs (6) Wochen vor Ende des jeweils laufenden Verlängerungszeitraums entsprechend informieren. Sofern der Nutzer mit dieser Entgelterhöhung nicht einverstanden ist, kann er dieser binnen vier (4) Wochen per E-Mail oder Brief widersprechen. Der Widerspruch entspricht einer Kündigung des archicloud-Pro Accounts durch den Nutzer, so dass der archicloud-Pro Account mit Ablauf des bestehenden Mindestnutzungszeitraums bzw. des laufenden Verlängerungszeitraums endet.',
      'archicloud nimmt für den Zahlungsvorgang Dienstleistungen Dritter in Anspruch, welche folgende Zahlungsmethoden ermöglichen: Kreditkarte, Lastschrift, PayPal und Rechnung.',
      'Der Nutzer hat dafür Sorge zu tragen, dass das hinterlegte Bank- oder Kreditkarten-Konto, von dem der Betrag abgebucht wird, über die erforderliche Deckung verfügt. Für den Fall, dass die Zahlung aufgrund von Umständen, die vom Nutzer zu vertreten sind, nicht erfolgt, kann archi die entstandenen Mehrkosten (z.B. Kosten der Rücklastschrift) dem Nutzer in der jeweils angefallenen Höhe berechnen.',
      'Rechnungen und Zahlungserinnerungen werden ausschließlich auf elektronischem Weg übermittelt. Nutzern von archicloud werden monatlich Rechnungen im PDF-Format zur Verfügung gestellt.'
    ]
  },
  {
    title: 'Laufzeit und Kündigung',
    children: [
      'Wenn im Einzelfall keine besonderen Bestimmungen zur Laufzeit und Kündigung entgegenstehen, kann das Vertragsverhältnis von beiden Seiten jederzeit, mit einer Kündigungsfrist von einem Monat, beendet werden. Der Nutzer kann den Vertrag für die Nutzung von archicloud unter dem Menüpunkt „Einstellungen/Kündigen“ kündigen. Die Kündigung kann auch schriftlich per E-Mail oder Brief gegenüber archi erklärt werden.',
      'Der archicloud-Pro-Account läuft zunächst über den vom Nutzer gebuchten Nutzungszeitraum. Danach verlängert sich der Account, jeweils um Verlängerungszeiträume der gleichen Dauer, wenn das Abonnement nicht vom Nutzer oder archi gekündigt wird.',
      'Sollte der Nutzer mit der Zahlung des Betrages mehr als 8 Wochen in Verzug sein, behält archi sich das Recht vor, das Vertragsverhältnis zum Ende des laufenden Abrechnungsmonats zu kündigen. Ansprüche von archi, die durch die bisherige Nutzung von archicloud durch den Nutzer entstanden sind, bleiben hiervon unberührt.',
      'Im Falle einer missbräuchlichen Nutzung von archicloud, die zu erheblichen Beeinträchtigungen der Leistungen von archicloud für Dritte führt, behält archi sich die außerordentliche Kündigung des Vertragsverhältnisses vor.',
      'Ein etwaiges außerordentliches Kündigungsrecht des Nutzers oder archi bleibt unberührt.'
    ]
  },
  {
    title: 'Nutzungspflichten und -rechte',
    children: [
      'archi behält sich vor, die auf archicloud angebotenen Leistungen und diese Allgemeinen Geschäftsbedingungen (AGB) zu ändern oder abweichende Leistungen anzubieten. Die geänderten Leistungen und AGB werden Ihnen gegenüber wirksam, wenn Sie nicht innerhalb von vier (4) Wochen ab deren Zugang gegenüber archi deren Geltung widersprechen. archi wird Sie mit der Übersendung der geänderten Leistungen oder AGB auf diese Rechtsfolge und deren mögliche Vermeidung hinweisen.',
      'Der Nutzer ist verpflichtet, Informationen zu seinen Zahlungsdaten (einschließlich der Rechnungsanschrift), die im Rahmen der Account-Erstellung bzw. -Änderung angegeben werden, wahrheitsgemäß anzugeben.',
      'Der Nutzer ist verpflichtet, die Zugangsdaten für seinen archicloud Account vor der unberechtigten Kenntnisnahme Dritter zu schützen.',
      (
        <div>
          Dem Nutzer ist es untersagt, die Leistungen von archi, insbesondere den Dienst archicloud, missbräuchlich in Anspruch zu nehmen. Eine missbräuchliche Nutzung liegt insbesondere in folgenden Fällen vor:
          <ul>
            <li>Der Import oder die Verbreitung rechtswidriger oder diffamierender Inhalte</li>
            <li>Die Nutzung von technischen Hilfsmitteln oder Methoden, die die Funktionsfähigkeit der Dienste des Anbieters beeinträchtigen oder beeinträchtigen können (Software, Skripte, Bots etc.)</li>
          </ul>
          Die Nutzung ist ausschließlich für Planungsdienstleiter / Architekten gedacht. archi ist berechtigt, vom Nutzer erstellte Inhalte, die rechtswidrig und/oder missbräuchlich sind und/oder nicht im Zusammenhang mit Planungsdienstleitungen stehen, unverzüglich zu löschen.
        </div>
      ),
      'Für den Nutzer ist eine Reduzierung der Lizenzen und des daraus resultierenden geringeren Speicherplatzes, nur dann möglich, wenn die nach Reduzierung zur Verfügung stehende Speicherplatzgröße nicht überschritten wird. Sofern eine Lizenzreduzierung gewünscht ist und der Nutzer zum Zeitpunkt dieser mehr Speichervolumen nutzt, müssen Daten bis zur erlaubten Grenze vom Nutzer gelöscht werden.',
      'Wir begrenzen den Traffic auf das 2,5 fache Ihres gebuchten Speicherplatzes pro Monat, soweit nicht anders vereinbart. Wir behalten uns das Recht vor Accounts zu sperren oder zu löschen, bzw. die dazugehörigen Verträge auszusetzen oder zu kündigen, wenn die Häufigkeit und der Umfang der geladenen Daten darauf hindeuten, dass der Account für Filesharing oder ähnliches genutzt wird.',
      'Bei der Nutzung eines kostenlosen archicloud-Free-Account kann archi nach mindestens dreimonatiger Inaktivität und mehrfachem Anschrieb und Ansetzen einer endgültigen Widerspruchsfrist von zwei Wochen den Account löschen.',
      'archi räumt jedem Nutzer ein einfaches, nicht ausschließliches Recht ein, archicloud für die Dauer des Vertrages für eigene Zwecke zu nutzen. Dieses Nutzungsrecht ist nicht übertragbar.',
      'archicloud ist ein Webservice, der über den Zugriff zum Server erfolgt. Eine Überlassung der Software an den Nutzer erfolgt nicht.',
      'Zu Wartungszwecken kann die archicloud-Plattform außer Betrieb genommen werden. Archi ist bemüht dieses Wartungsfenster außerhalb der Hauptanwendungszeiten zu nutzen und informiert die Nutzer rechtzeitig vor Inanspruchnahme.',
      'Sofern während der Laufzeit des Vertrages neue Versionen, Updates, Upgrades oder andere Änderungen an archicloud vorgenommen werden, gelten die vorstehenden Regelungen.',
      'archi reagiert bei Kundenanfragen innerhalb von zwei Werktagen via E-Mail oder Telefon.'
    ]
  },
  {
    title: 'Haftungsbeschränkung',
    children: [
      'archi haftet nicht für indirekte Schäden, wie entgangenen Gewinn oder sonstige Vermögensschäden des Kunden.',
      'Eine Haftung für Beschädigungen oder den Verlust von Dateien auf Datenträgern durch archi oder ihrem Erfüllungsgehilfen, sowie auf den elektronischen Datenübertragungswegen und Netzwerken, ist ausgeschlossen.',
      'Eine Zusicherung von Eigenschaften der Produkte wird von archi nicht vorgenommen. Unbeschadet der vorstehenden Beschränkungen haftet archi bei Vorsatz und grober Fahrlässigkeit, auch seiner Erfüllungsgehilfen, nach den gesetzlichen Bestimmungen. Das Gleiche gilt bei fahrlässig verursachten Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Bei fahrlässig verursachten Sach- und Vermögensschäden haften archi und seine Erfüllungsgehilfen nur bei der Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht), jedoch der Höhe nach beschränkt auf die bei Vertragsschluss vorhersehbaren und vertragstypischen Schäden. Kardinalpflichten sind diejenigen Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf.'
    ]
  },
  {
    title: 'Datenschutz',
    children: [
      'Eine Weitergabe von personenbezogenen Daten des Nutzers an Dritte findet mit Ausnahme der Abwicklung von Bezahlvorgängen nicht statt.',
      'Für die Abwicklung der Bezahlvorgänge ist eine Übermittlung personenbezogener Daten an Dritte (Zahlungsanbieter, Bankinstitute, Kreditkartenanbieter) zwingend erforderlich. Hier werden jedoch nur die Daten übermittelt, die für die Durchführung der Bezahlvorgänge absolut notwendig sind. Eine Übermittlung von Daten aus den Bibliotheken des Nutzers erfolgt zu keiner Zeit.',
      'archi verarbeitet nur die personenbezogenen Daten von Nutzern, die für die Erbringung der Leistungen für archicloud erforderlich sind.',
      'Nach Beendigung des Vertragsverhältnisses erfolgt, mit einer Frist von einem Monat, nach Kündigung eine Löschung der personenbezogenen Daten, soweit keine gesetzlichen Aufbewahrungspflichten bestehen.',
      'Sie können auf bestimmte Daten, die wir über Sie gespeichert haben, über die Account-Einstellungen zugreifen und sie verändern. Des Weiteren hat jeder Nutzer die Möglichkeit, einmal pro Jahr, Auskunft über personenbezogene Daten zu erhalten. Nach Anfrage per E-Mail oder Post antworten wir innerhalb angemessener Frist nach Identitätsnachweis.',
      <div>Weitere Hinweise zum Datenschutz und zu Zweck, Art und Umfang der Erhebung, Verarbeitung und Nutzung personenbezogener Daten sind den Hinweisen zum Datenschutz zu entnehmen, die unter dem Link <a href='/datenschutz'>Datenschutz</a> einsehbar sind.</div>
    ]
  },
  {
    title: 'Schlussbestimmungen',
    children: [
      'Bei Rechtsstreitigkeiten im Zusammenhang mit dieser Geschäftsbeziehung gilt ausschließlich deutsches Recht.',
      'Ist der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, so ist der Sitz von archi ausschließlicher Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis.',
      'Sollten Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam sein oder werden, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht. Die vorstehende Regel gilt entsprechend bei Regelungslücken.'
    ]
  },
]

// Enumerate sections

sections.map((s, i) => s.key = i+1)

export default sections