import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, union } from 'lodash';
import cx from 'classnames';

import InfoLink from "components/common/InfoLink";
import { Button, StepNavItem, StepNavBar } from 'components/common/StyledComponents';

class MultiStep extends Component {
  
  static propTypes = {
    steps: PropTypes.array.isRequired,
    showPrevButton: PropTypes.bool,
    isAlwaysValid: PropTypes.bool,
    validSteps: PropTypes.array.isRequired,
    navClassName: PropTypes.string,
    formId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    prevButton: PropTypes.string,
    nextButton: PropTypes.string,
    initialStep: PropTypes.number,
    onStepUpdate: PropTypes.func
  }
  
  static defaultProps = {
    showPrevButton: true,
    isAlwaysValid: false,
    validSteps: []
  }
  
  constructor(props) {
    super();
    
    const initialStep = props.initialStep ? props.initialStep : 0;
    
    this.state = {
      visited: [initialStep],
      currentStep: initialStep
    };
  }
  
  componentDidMount() {
    this.handleStepUpdate(this.state.currentStep);
  }
  
  handleStepUpdate(step) {
    const { onStepUpdate, steps } = this.props
    
    onStepUpdate && onStepUpdate(steps[step].name)
  }

  setStep(step) {
    const { visited } = this.state;
    
    this.setState({
      currentStep: step,
      visited: (visited.indexOf(step) > -1) ? visited : [...visited, step]
    });
    
    this.handleStepUpdate(step);
  }

  jumpToStep = (e, nextStep) => {
    e.preventDefault();
    const { visited, currentStep } = this.state;
    const { steps, isAlwaysValid, validSteps } = this.props;
    
    // Validate before letting the user jump to a step
    if (nextStep >= 0 && nextStep < steps.length) {
      // Going back to one of the previous visited steps is always allowed
      // Otherwise only going to the very next step is possible
      const isPreviousAndVisited = (visited.indexOf(nextStep) > -1 && nextStep < currentStep)
      const currentIsValid = validSteps.indexOf(currentStep) > -1
      
      if (isAlwaysValid || isPreviousAndVisited || (currentIsValid && (nextStep - currentStep) === 1)) {
        this.setStep(nextStep);
      }
    }
  }

  next = () => {
    this.setStep(this.state.currentStep + 1);
  }

  previous = () => {
    this.setStep(this.state.currentStep - 1);
  }

  renderSteps() {
    const { steps, navClassName } = this.props;
    const { currentStep } = this.state;
    
    return steps.map((s, id) => {
      const { name, moreInfo } = steps[id]
      let stepStatus = 'next';

      if (id < currentStep) stepStatus = 'visited'
      else if (id === currentStep) stepStatus = 'active'
      
      return (
        <StepNavItem
          className={cx(`step-${stepStatus}`, navClassName)}
          onClick={e => this.jumpToStep(e, id)}
          key={id}
          data-step={id}>
          <span>{name} {moreInfo && <InfoLink to={moreInfo} />}</span>
        </StepNavItem>
      )
    })
  }
  
  getControls() {
    const { isAlwaysValid, validSteps, showPrevButton, steps, prevButton, nextButton } = this.props;
    const { currentStep } = this.state;
    const currentIsValid = validSteps.indexOf(currentStep) > -1
    
    const showPrev = showPrevButton && currentStep > 0;
    const showNext = currentStep < steps.length - 1;
    
    let controls = []
    
    if (showPrev) {
      controls.push(
        <Button type='button' key='prevButton' wide onClick={this.previous}>
          {prevButton || 'Zurück'}
        </Button>
      )
    }
    
    if (showNext) {
      controls.push(
        <Button type='button' key='nextButton' wide disabled={!currentIsValid && !isAlwaysValid} onClick={this.next}>
          {nextButton || `Weiter zu Schritt ${currentStep+2} von ${steps.length}`}
        </Button>
      )
    }
    
    return controls
  }

  render() {
    const { currentStep } = this.state;
    const { steps, formId } = this.props;
    
    const componentToRender = React.cloneElement(steps[currentStep].component, {
      id: currentStep,
      formId,
      nextStep: this.next,
      controls: this.getControls()
    });
    
    return (
      <div>
        <StepNavBar className="steps">
          {this.renderSteps()}
        </StepNavBar>
        
        {componentToRender}        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const globalValidSteps = ownProps.formId ? get(state.form[ownProps.formId], 'validSteps', []) : []
  // Merge passed valid steps with the global state
  const validSteps = union(globalValidSteps, ownProps.validSteps)
    
  return { validSteps }
}

export default connect(mapStateToProps)(MultiStep);