import EXIF from 'exif-js';

function findOrientation(file,cb) {
  if (file && typeof cb === 'function') {
    var r = new FileReader();
    r.onload = function(e) {
      var exif = EXIF.readFromBinaryFile(e.target.result);
      var val = exif.Orientation || 0;
      cb(undefined,val);
    };
    r.onerror = function(e) {
      cb(new Error('Could not read file.'));
    };
    r.readAsArrayBuffer(file);
  }
};

function load(file,cb) {
  return new Promise((resolve, reject) => {
    findOrientation(file, (err,orientation) => {
      if (!err) {
        loadImage(URL.createObjectURL(file), img =>
          img ? resolve(translate(img,orientation)) : reject(new Error('Could not load image.'))
        )
      } else {
        reject(err);
      }
    });
  });
};

function translate(image,orientation) {
  orientation = orientation || {};
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = image.width;
  c.height = image.height;
  ctx.save();
  const width = c.width, styleWidth = c.style.width;
  const height = c.height, styleHeight = c.style.height;
  
  if (orientation) {
    if (orientation > 4) {
      c.width  = height; c.style.width  = styleHeight;
      c.height = width;  c.style.height = styleWidth;
    }
    switch (orientation) {
      case 2: ctx.translate(width, 0);     ctx.scale(-1,1); break;
      case 3: ctx.translate(width,height); ctx.rotate(Math.PI); break;
      case 4: ctx.translate(0,height);     ctx.scale(1,-1); break;
      case 5: ctx.rotate(0.5 * Math.PI);   ctx.scale(1,-1); break;
      case 6: ctx.rotate(0.5 * Math.PI);   ctx.translate(0,-height); break;
      case 7: ctx.rotate(0.5 * Math.PI);   ctx.translate(width,-height); ctx.scale(-1,1); break;
      case 8: ctx.rotate(-0.5 * Math.PI);  ctx.translate(-width,0); break;
      default: break;
    }
  }
  
  ctx.drawImage(image,0,0);
  ctx.restore();
  return c;
};

function loadImage(src,cb) {
  const img = new Image();
  img.onload = function() { cb(img); };
  img.onerror = function() { cb(); };
  img.src = src;
}

export default load;