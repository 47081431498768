import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import uniqueId from 'utils/uniqueId';
import vars from 'variables';
import { FormGroup } from './StyledComponents';

const { checkboxHeight, gutter } = vars.main;
const { lightGray, gray } = vars.colors;

const Checkbox = styled.input`
  display: none;
  & + label {
    cursor: pointer;
    margin-left: 0;
    margin-right: ${checkboxHeight + gutter}px;
    border: 1px solid ${lightGray};
    font-size: 0.85rem;
    font-weight: 400;
    line-height: ${checkboxHeight - 2}px;
    padding: 0 20px;
    color: ${gray};
    text-decoration: none;
    position: relative;
    user-select: none;

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      right: -${checkboxHeight + gutter}px;
      padding: 0 ${checkboxHeight / 2 - 1}px;
      height: ${checkboxHeight}px;
      border: 1px solid ${lightGray};
      background-color: #fff;
    }
  }

  &:checked + label:after {
    background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cpath%20d%3D%22M14.63%201l-9%2013.63M1%207.73l4.63%206.9%22%20stroke%3D%22%237D7D7D%22%20stroke-width%3D%22.87%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22/%3E%0A%3C/svg%3E%0A);
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50%;
  }
`;

class CheckboxField extends Component {
  static defaultProps = {
    isChecked: false,
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
    value: PropTypes.string,
    title: PropTypes.string,
    error: PropTypes.string,
  };

  UNSAFE_componentWillMount() {
    this.id = uniqueId();
  }

  render() {
    const { title, value, name, isChecked, onChange, error, children, margin } = this.props;

    return (
      <FormGroup margin={margin} className={classnames({ 'has-danger': error })}>
        {title && <div className="label">{title}</div>}
        <Checkbox
          type="checkbox"
          name={name}
          id={this.id}
          value={value}
          checked={isChecked}
          onChange={onChange}
        />
        <label htmlFor={this.id}>{children}</label>
        {error && <span className="form-feedback">{error}</span>}
      </FormGroup>
    );
  }
}

export default CheckboxField;
