import * as auth from 'actions/authActions';
import jwtDecode from 'jwt-decode';
import { roles } from 'variables';

const initialState = {
  isAuthenticated: false,
  user: {},
  access: {},
  refresh: {},
  refreshPromise: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case auth.USER_LOGIN:
      const { accessToken, refreshToken } = action
      
      const { exp: accessExpiry, iat: accessIat, ...userData } = jwtDecode(accessToken);
      const { exp: refreshExpiry, iat: refreshIat } = jwtDecode(refreshToken);

      return {
        isAuthenticated: true,
        refreshPromise: null,
        access: {
          token: accessToken,
          exp: accessExpiry,
          iat: accessIat
        },
        refresh: {
          token: refreshToken,
          exp: refreshExpiry,
          iat: refreshIat
        },
        user: {
          ...userData,
          canEdit: roles.crud.indexOf(userData.accountType) > -1,
          isAdmin: roles.admin.indexOf(userData.accountType) > -1
        }
      }
    case auth.REFRESHING_TOKENS:
      return {
        ...state,
        refreshPromise: action.refreshPromise
      }

    case auth.USER_LOGOUT:
      return initialState
      
    default: return state;
  }
}
    
export const isAccessTokenExpired = state => {
  if (state.access && state.access.exp) {
    return 1000 * state.access.exp - (new Date()).getTime() < 5000
  }
  return true
}

export const isRefreshTokenExpired = state => {
  if (state.refresh && state.refresh.exp) {
    return 1000 * state.refresh.exp - (new Date()).getTime() < 5000
  }
  return true
}

export const getAccessToken = state => (state.access) ? state.access.token : null
export const getRefreshToken = state => (state.refresh) ? state.refresh.token : null
