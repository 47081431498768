import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { restoreFiltersState } from 'actions/filterActions';
import { updateFormStatus } from 'actions/formActions';
import FilterList from 'components/Filter/FilterList';
import { Button, ControlsRow, HalfColumn } from 'components/common/StyledComponents';

class StepOne extends Component {
  componentDidMount() {
    this.checkIfCurrentStepIsValid(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkIfCurrentStepIsValid(nextProps);
  }

  checkIfCurrentStepIsValid({ filters: { selected, primary }, isValid }) {
    const { updateFormStatus, id, formId } = this.props;
    const nextIsValid = selected.length > 0 && !isNaN(primary);

    if (nextIsValid !== isValid) {
      updateFormStatus(formId, id, nextIsValid);
    }
  }

  handleClick = () => {
    const {
      preferences: { filters },
      restoreFiltersState,
    } = this.props;

    if (filters) {
      restoreFiltersState(filters);
    }
  };

  render() {
    return (
      <div>
        <FilterList showLiteratureInfos={true} selectable={true} />
        <ControlsRow>
          <HalfColumn>{this.props.controls}</HalfColumn>
          {this.props.preferences.filters && (
            <HalfColumn>
              <Button wide onClick={this.handleClick}>
                Letzte Filterauswahl verwenden
              </Button>
            </HalfColumn>
          )}
        </ControlsRow>
      </div>
    );
  }
}

StepOne.propTypes = {
  filters: PropTypes.object.isRequired,
  updateFormStatus: PropTypes.func.isRequired,
  restoreFiltersState: PropTypes.func.isRequired,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueOfTitle: PropTypes.string,
  controls: PropTypes.array,
};

const mapStateToProps = ({ filters, form, preferences }, ownProps) => {
  const currentForm = form[ownProps.formId] || {};
  const validSteps = currentForm.validSteps || [];

  return {
    filters,
    valueOfTitle: get(currentForm, 'data.title') || '',
    isValid: validSteps.indexOf(ownProps.id) > -1,
    preferences,
  };
};

export default connect(mapStateToProps, { updateFormStatus, restoreFiltersState })(StepOne);
