import React from 'react';
import { Wrapper } from './common/StyledComponents';

const NotFound = () => (
  <Wrapper>
    <p>Diese Seite wurde nicht gefunden.</p>
  </Wrapper>
)

export default NotFound;
