import { debounce } from 'lodash';
import { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectFilterRecursively } from 'actions/filterActions';
import { setSearchTerm } from 'actions/searchActions';
import Scrollbars from 'components/common/Scrollbars';
import { fuzzySearch, SearchField } from 'components/Search';
import { getArrayOfUsedFiltersPerSection } from 'selectors';
import vars from 'variables';
import './theme.css';

const SuggestionsContainer = styled.div`
  display: none;

  &.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 28px;
    width: 100%;
    border: 1px solid ${vars.colors.lightGray};
    background-color: #fff;
    z-index: 2;
  }
`;

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

const renderSectionTitle = (section) => <span>in "{section.name}"</span>;

const getSectionSuggestions = (section) => section.children;

const renderInputComponent = (props) => {
  const { ref, ...inputProps } = props;

  return (
    <SearchField
      {...inputProps}
      innerRef={ref}
      className="is-gray is-bigger"
      wrapperClassName="sidebar-search"
    />
  );
};

class SearchDropdown extends Component {
  constructor(props) {
    super();

    this.state = {
      value: '',
      suggestions: [],
    };

    this.setSearchTerm = debounce(props.setSearchTerm, 250);
  }

  UNSAFE_componentWillReceiveProps({ searchTerm }) {
    // If global search term was updated externally, update local value as well
    if (this.state.value !== searchTerm) {
      this.setState({ value: searchTerm });
    }
  }

  onChange = (event, { newValue: value, method }) => {
    // Don't update input field on up/down events
    if (method === 'up' || method === 'down') return;

    this.setState({ value }, () => {
      this.setSearchTerm(value);
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestionValue = ({ name }) => {
    return name;
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    const { id, parent_id, grandparent_id } = suggestion;

    // Select the corresponding filter
    this.props.selectFilterRecursively({
      filterId: id,
      secondaryFilterId: parent_id,
      primaryFilterId: grandparent_id,
    });

    // Clear the search term
    this.onChange(event, { newValue: '' });
  };

  // Get suggestions for a given input value
  getSuggestions = (value) => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;

    if (inputLength === 0) return [];

    const suggestions = this.props.suggestions
      .map((section) => ({
        ...section,
        children: fuzzySearch(section.children, inputValue, ['name']),
      }))
      .filter((section) => section.children.length > 0);

    return suggestions;
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <SuggestionsContainer {...containerProps}>
        <Scrollbars
          borderSize={0}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="80vh"
          alignRight={true}
          hideTracksWhenNotNeeded={true}
        >
          {children}
        </Scrollbars>
      </SuggestionsContainer>
    );
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Suche',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        getSectionSuggestions={getSectionSuggestions}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        renderSectionTitle={renderSectionTitle}
        focusInputOnSuggestionClick={false}
        inputProps={inputProps}
        multiSection={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  suggestions: getArrayOfUsedFiltersPerSection(state),
  searchTerm: state.searchTerm,
});

export default connect(mapStateToProps, {
  selectFilterRecursively,
  setSearchTerm,
})(SearchDropdown);
