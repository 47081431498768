import React from 'react';
import { Scrollbars } from '../CustomScrollbars';
import vars from 'variables';

const { lightestGray, lightGray } = vars.colors;

const CustomScrollbars = ({
  borderSize,
  alignRight = false,
  hideTracksWhenNotNeeded = true,
  ...props
}) => {
  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      position: 'absolute',
      width: 10,
      left: alignRight ? 'auto' : 0,
      right: alignRight ? 0 : 'auto',
      bottom: 0,
      top: 0,
      cursor: 'pointer',
      backgroundColor: lightestGray,
      border: `${borderSize}px solid ${lightGray}`,
    };

    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: lightGray,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      renderTrackVertical={renderTrack}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      hideTracksWhenNotNeeded={hideTracksWhenNotNeeded}
      {...props}
    />
  );
};

CustomScrollbars.defaultProps = {
  borderSize: 1,
};

export default CustomScrollbars;
