import { get, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateFormData } from 'actions/formActions';
import { extractRelevantKeys } from 'utils/common';

const createForm =
  ({ initialValues, fields, formKey }) =>
  (WrappedComponent) => {
    class ConnectedForm extends Component {
      UNSAFE_componentWillMount() {
        const { fields, updateFormData } = this.props;
        if (!isEmpty(initialValues)) {
          // Check if all initial values are already set
          const initialValuesAreSet = Object.keys(initialValues).every(
            (key) => fields[key] != null
          );
          if (!initialValuesAreSet) {
            updateFormData(initialValues);
          }
        }
      }

      onChange = (data) => {
        const relevantData = extractRelevantKeys(data, fields);

        if (!isEmpty(relevantData)) {
          this.props.updateFormData(relevantData);
        }
      };

      onCheckboxChange = (e) => {
        const { name } = e.target;

        this.onChange({ [name]: !this.props.fields[name] });
        // Small hack to ensure that the state of the checkbox field
        // is updated before the onBlur call
        setTimeout(() => {
          this.props.onBlur({
            target: { name, value: this.props.fields[name] },
          });
        }, 500);
      };

      render() {
        const { formId, updateFormData, ...restProps } = this.props;

        return (
          <WrappedComponent
            onChange={this.onChange}
            onCheckboxChange={this.onCheckboxChange}
            {...restProps}
          />
        );
      }
    }

    const mapStateToProps = (state, ownProps) => {
      const formId = ownProps.formId || formKey;

      return { fields: get(state.form[formId], 'data', {}) };
    };

    const mapDispatchToProps = (dispatch, ownProps) => {
      const formId = ownProps.formId || formKey;
      return {
        updateFormData: (data) => {
          dispatch(updateFormData(formId, data));
        },
      };
    };

    return connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
  };

export default createForm;
