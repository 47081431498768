import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { AddFilter, AddProject, AddPlace, DeleteFilter } from './popups';
import SelectList from './SelectList';
import { ControlsRow, ControlCol } from 'components/common/StyledComponents';
import { toggleFilter } from 'actions/filterActions';

const SelectWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

class SelectView extends Component {
  
  renderSelectList() {
    const {
      id,
      searchable,
      selectable,
      editable,
      toggleFilter,
      selected,
      filters,
      showHalfSelected,
      halfSelected,
      reverseSelectors
    } = this.props;

    return (
      <SelectList
        onChange={e => toggleFilter(e)}
        editable={editable}
        selected={selected}
        halfSelected={showHalfSelected ? halfSelected : undefined}
        searchable={searchable}
        selectable={selectable}
        options={filters}
        parentId={id}
        reverseSelectors={reverseSelectors}
      />
    );
  }
  
  render() {
    const { name, id, editable, searchable, filters } = this.props;

    let AddForm = AddFilter;
    if (name === 'Projekte') {
      AddForm = AddProject
    } else if (name.match(/^Ort/)) {
      AddForm = AddPlace
    }

    const controls = (
      <ControlsRow>
        <ControlCol>
          <AddForm
            modalTitle={`${name} hinzufügen`}
            options={filters}
            parentId={id}
          />
        </ControlCol>
        { !isEmpty(filters) &&
        <ControlCol>
          <DeleteFilter
            modalTitle={`${name} löschen`}
            parentId={id}
            options={filters}
            searchable={searchable}
          />
        </ControlCol> }
      </ControlsRow>
    );
    
    return (
      <SelectWrapper>
        { !isEmpty(filters) && this.renderSelectList() }
        { editable && controls }
      </SelectWrapper>
    );
  }
}

SelectView.propTypes = {
  selectable: PropTypes.bool,
  searchable: PropTypes.bool,
  editable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  showHalfSelected: PropTypes.bool,
  reverseSelectors: PropTypes.bool,
  selected: PropTypes.array,
  halfSelected: PropTypes.array,
  filters: PropTypes.array
}

SelectView.defaultProps = {
  selectable: true,
  editable: true,
  showHalfSelected: false
}

const mapStateToProps = (state, { options }) => {
  const filters = state.filters.items;
  const selected = state.filters.selected.filter(i => options.indexOf(i) !== -1)
  const halfSelected = state.filters.halfSelected.filter(i => options.indexOf(i) !== -1)

  return {
    selected,
    halfSelected,
    filters: options.map(key => filters[key]).filter(i => i)
  }
}

export default connect(mapStateToProps, { toggleFilter })(SelectView);
