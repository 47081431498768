import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteFilters } from 'actions/filterActions';
import { updateItem } from 'actions/itemActions';
import Modal from 'components/Modal/Modal';
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import { getFavorites } from 'selectors';
import { deleteItems, toggle } from 'utils/common';
import SelectList from '../SelectList';

class AddToFavorites extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selected: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { item, optionIds } = this.props;

    // Get favorites already associated with current item
    const selected = item.filters.filter((i) => optionIds.indexOf(i) > -1);

    if (selected.length > 0) {
      this.setState({ selected });
    }
  }

  onChange = (id) => {
    this.setState({
      selected: toggle(this.state.selected, id),
    });
  };

  onClick(e) {
    e.preventDefault();
    const { selected } = this.state;
    const { item, optionIds, updateItem, onClose } = this.props;

    // Merge updated list of favorites with the list of item's favorites
    const updatedFilters = [...deleteItems(item.filters, optionIds), ...selected];

    this.setState({ isLoading: true });

    updateItem(item.id, { selected: updatedFilters }, 'filters')
      .then((res) => {
        this.setState({ isLoading: false });
        onClose();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { options, onClose } = this.props;

    return (
      <Modal title="Zu Favoriten hinzufügen" isOpen={true} onClose={onClose}>
        <ContentWrapper>
          <SelectList
            searchable={true}
            selected={this.state.selected}
            onChange={this.onChange}
            useLocalState={true}
            itemClassName="alt"
            options={options}
          />
        </ContentWrapper>
        <Button wide onClick={(e) => this.onClick(e)} disabled={this.state.isLoading}>
          Objekt zu Favoriten hinzufügen
        </Button>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { items, ids } = getFavorites(state);
  return {
    options: items,
    optionIds: ids,
    item: find(state.library.items, { id: ownProps.data.itemId }),
  };
};

export default connect(mapStateToProps, { deleteFilters, updateItem })(AddToFavorites);
