import { isEmpty } from 'lodash';
import { getVisibleErrors, validate } from 'modules/validations/validate';
import React, { Component } from 'react';
import { extractRelevantKeys } from 'utils/common';

const createLocalForm =
  ({ initialValues, fields, validationRules }) =>
  (WrappedComponent) => {
    class Form extends Component {
      constructor() {
        super();

        this.state = {
          errors: {},
          touched: {},
          isInvalid: false,
          ...this.getEmptyFields(),
        };
      }

      getEmptyFields = () => {
        return fields.reduce((obj, i) => {
          obj[i] = '';
          return obj;
        }, {});
      };

      resetForm = () => {
        this.setState({
          errors: {},
          touched: {},
          isInvalid: false,
          ...this.getEmptyFields(),
        });
      };

      UNSAFE_componentWillMount() {
        if (!isEmpty(initialValues)) {
          const relevantData = extractRelevantKeys(initialValues, fields);
          if (!isEmpty(relevantData)) {
            this.setState(relevantData);
          }
        }
      }

      uncheckedUpdate = (data) => {
        this.setState(data);
      };

      // Set errors imperatively
      setErrors = (errors) => {
        this.setState({ errors });
      };

      validate = (name, value) => {
        if (isEmpty(validationRules)) return true;

        const { errors } = validate(this.state, validationRules);

        const isInvalid = !isEmpty(errors);
        this.setState({ errors, isInvalid });

        return !isInvalid;
      };

      onBlur = (e) => {
        const { name, value } = e.target;

        // Mark the field as touched if needed
        if (!this.state.touched[name]) {
          this.setState({
            touched: { ...this.state.touched, [name]: true },
          });
        }

        this.validate(name, value);
      };

      touchAll = () => {
        const touched = fields.reduce((obj, i) => {
          obj[i] = true;
          return obj;
        }, {});

        this.setState({ touched });
      };

      onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };

      render() {
        const { isInvalid, errors, touched } = this.state;
        const err = getVisibleErrors(errors, touched);

        return (
          <WrappedComponent
            resetForm={this.resetForm}
            uncheckedUpdate={this.uncheckedUpdate}
            validate={this.validate}
            onBlur={this.onBlur}
            onChange={this.onChange}
            setErrors={this.setErrors}
            touchAll={this.touchAll}
            errors={err}
            fields={this.state}
            isInvalid={isInvalid}
            {...this.props}
          />
        );
      }
    }

    return Form;
  };

export default createLocalForm;
