import React from 'react'

import { SectionChildren, SearchableContainer, Button } from 'components/common/StyledComponents'
import { SimpleAccordion as Accordion, Panel } from 'components/common/Accordion'
import createSearchable from 'components/common/createSearchable'
import privacyData from './privacyData'
import DatenschutzPdf from 'documents/Datenschutz.pdf'

const PrivacyView = ({ activeKey, content, onAccordionChange }) =>  {  
  return (
    <div>
      <SearchableContainer className='searchableContainer'>
        <Accordion activeKey={activeKey} onChange={onAccordionChange}>
          {content.map(({ key, title, children }) => (
            <Panel key={key} header={`${key}.  ${title}`} className='section-header'>
              <SectionChildren className='with-border'>
                {children.map((item, i) => <li key={i}>{item}</li>)}
              </SectionChildren>
            </Panel>
          ))}
        </Accordion>
      </SearchableContainer>
      <a href={DatenschutzPdf} rel="noopener noreferrer" target='_blank'><Button wide>Datenschutz als PDF öffnen</Button></a>
    </div>
  );
}

export default createSearchable({
  title: 'Datenschutz',
  data: privacyData
})(PrivacyView);
