import React, { Component } from "react";
import { connect } from "react-redux";
import bytes from "bytes";

import { getSpaceUsage } from "actions/userActions";
import ProgressBar from "components/common/ProgressBar";

class SpaceUsageBar extends Component {
  constructor(props) {
    super();

    this.state = {
      used: 0,
      available: 1
    };
  }

  async componentDidMount() {
    const { used, available } = await this.props.getSpaceUsage();

    this.setState({ used, available });
  }

  render() {
    const { used, available } = this.state;
    const percent = used / available * 100;

    return (
      <div>
        <h4 className="form-title">
          {bytes(used, { unitSeparator: " " })} von {bytes(available, { unitSeparator: " " })} belegt
        </h4>
        <ProgressBar percent={percent} />
      </div>
    );
  }
}

export default connect(null, { getSpaceUsage })(SpaceUsageBar);
