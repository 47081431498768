import React from 'react'

import TickIcon from './TickIcon';
import CloseIcon from './CloseIcon';

export { default as GrundlagenIcon } from './GrundlagenIcon';
export { default as ReferenzenIcon } from './ReferenzenIcon';
export { default as LogoIcon } from './LogoIcon';
export { default as HamburgerIcon } from './HamburgerIcon';
export { default as FilterIcon } from './FilterIcon';
export { default as DetailsIcon } from './DetailsIcon';
export { default as LiteraturIcon } from './LiteraturIcon';
export { default as InfoIcon } from './InfoIcon';
export { default as ImportIcon } from './ImportIcon';
export { default as ImportIconHuge } from './ImportIconHuge';
export { default as LogoutIcon } from './LogoutIcon';
export { default as SettingsIcon } from './SettingsIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as PreviewIcon } from './PreviewIcon';
export { default as DeleteIcon } from './DeleteIcon';
export { default as DownloadIcon } from './DownloadIcon';
export { default as PropertiesIcon } from './PropertiesIcon';
export { default as ReplaceIcon } from './ReplaceIcon';
export { default as FavoritesIcon } from './FavoritesIcon';
export { default as ArrowIcon } from './ArrowIcon';
export { default as FacebookIcon } from './FacebookIcon';

export { default as GrundlagenIconBig } from './frontPage/GrundlagenIconBig';
export { default as ReferenzenIconBig } from './frontPage/ReferenzenIconBig';
export { default as LiteraturIconBig } from './frontPage/LiteraturIconBig';
export { default as DetailsIconBig } from './frontPage/DetailsIconBig';
export { default as DatenschutzIcon } from './frontPage/DatenschutzIcon';

export { default as Logo } from './frontPage/Logo';
export { default as BibliothekenImport } from './frontPage/BibliothekenImport';
export { default as Info } from './frontPage/Info';

// Material Icons by Google
export { default as Eye } from './material/Eye';
export { default as EyeOff } from './material/EyeOff';
export { default as Lock } from './material/Lock';
export { default as Unlock } from './material/Unlock';

// Product Icons
const NoIcon = <CloseIcon height={19} width={19} viewBox='0 0 16 16' />
const YesIcon = <TickIcon height={14} width={14} viewBox='0 0 16 16' />
const NoIconSmall = <CloseIcon height={12} width={12} viewBox='0 0 16 16' />
const YesIconSmall = <TickIcon height={9} width={9} viewBox='0 0 16 16' />

export {
  TickIcon, CloseIcon, YesIcon, YesIconSmall, NoIcon, NoIconSmall
}