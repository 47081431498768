import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';

import createModal from 'components/Modal/createModal'; 
import { Button, ContentWrapper } from 'components/common/StyledComponents';
import { createLocalForm } from 'components/Form';
import InputField from 'components/common/InputField';
import SelectField from 'components/common/SelectField';
import { accountTypeOptions } from 'config';
import { createTeamUser } from 'actions/teamActions';
import { addNotification } from 'actions/notificationActions';
import emailRules from 'modules/validations/emailRules';
import { getErrorMessage, getErrorObject } from 'modules/errorHandler';

class CreateUser extends Component {
  constructor() {
    super()
    
    this.state = {
      isLoading: false
    }
  }
  
  onSubmit = e => {
    e.preventDefault();
    const {
      fields,
      createTeamUser,
      addNotification,
      onSuccess,
      validate,
      setErrors,
      touchAll
    } = this.props;
    
    // Mark all fields as touched on submission
    touchAll();
    
    // Bail early if validation failed
    if (!validate()) return;
    
    this.setState({ isLoading: true })
    
    createTeamUser(pick(fields, ['email', 'accountType']))
    .then(res => {      
      addNotification({
        modal: true,
        type: 'success',
        title: 'Neuer Nutzer erstellt',
        text: 'Eine E-Mail wurde an die angegebene Adresse versandt. Beigefügter Link muss vom Nutzer bestätigt und ein Kennwort vergeben werden.',
        dismissButton: 'Ok'
      })
      
      // Close the modal on success
      onSuccess()
    })
    .catch(err => {
      this.setState({ isLoading: false })

      getErrorObject(err, errors => setErrors(errors))
      getErrorMessage(err, text => addNotification({ type: 'error', text }))
    });
  }
  
  render() {
    const { errors, fields, onBlur, onChange, isInvalid } = this.props
    const { email, accountType } = fields
        
    return (
      <form onSubmit={this.onSubmit}>
        <ContentWrapper>
          <InputField
            gray
            error={errors.email}
            label="E-Mail-Adresse"
            onBlur={onBlur}
            onChange={onChange}
            value={email}
            name="email"
            type="email"
            placeholder="E-Mail-Adresse eingeben"
          />

          <SelectField
            gray
            label='Kontotyp'
            moreInfo='/faq/7:a'
            name='accountType'
            onChange={onChange}
            options={accountTypeOptions}
            value={accountType}
          />

        </ContentWrapper>
        <Button disabled={isInvalid || this.state.isLoading} wide>Nutzer hinzufügen</Button>
      </form>
    );
  }
}

const CreateUserForm = createLocalForm({
  fields: [
    'email',
    'accountType'
  ],
  initialValues: {
    accountType: 'admin'
  },
  validationRules: emailRules
})(CreateUser)

const connectedCreateUserForm = connect(null, {createTeamUser, addNotification})(CreateUserForm)

export default createModal(connectedCreateUserForm);
