import cx from 'classnames';
import React, { Component } from 'react';
import SearchField from './SearchField';
import { createFilter } from './util';

class Search extends Component {
  static defaultProps = {
    onChange() {},
    caseSensitive: false,
    fuzzy: false,
    throttle: 200,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: this.props.value || '',
    };

    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (typeof value !== 'undefined' && value !== this.props.value) {
      const e = {
        target: {
          value,
        },
      };
      this.updateSearch(e);
    }
  }

  updateSearch = (e) => {
    const searchTerm = e.target.value;

    this.setState({ searchTerm }, () => {
      if (this._throttleTimeout) {
        clearTimeout(this._throttleTimeout);
      }

      this._throttleTimeout = setTimeout(
        () => this.props.onChange(searchTerm),
        this.props.throttle
      );
    });
  };

  filter(keys) {
    const { filterKeys, caseSensitive, fuzzy } = this.props;
    return createFilter(this.state.searchTerm, keys || filterKeys, caseSensitive, fuzzy);
  }

  render() {
    const { className, ...inputProps } = this.props;

    inputProps.onChange = this.updateSearch;
    inputProps.value = this.state.searchTerm;
    inputProps.className = cx(className, 'search-form');

    return <SearchField innerRef={this.input} {...inputProps} />;
  }
}

export default Search;
