const ADD_PREFERENCE = 'ADD_PREFERENCE/preferences';
const DELETE_PREFERENCE = 'DELETE_PREFERENCE/preferences';

const addPreference = (key, value) => ({
  type: ADD_PREFERENCE,
  key,
  value
})

const deletePreference = key => ({
  type: DELETE_PREFERENCE,
  key
})

export {
  ADD_PREFERENCE, DELETE_PREFERENCE, addPreference, deletePreference
}