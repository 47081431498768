import React from 'react';

import LoadingBar from 'components/LoadingBar'
import Sidebar from 'components/Sidebar/Sidebar';

const Header = ({ children }) => {      
  return (
    <header>
      <LoadingBar />
      {children}
      <Sidebar />
    </header>
  );
}

export default Header