import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './StyledComponents';

const ButtonLink = ({ to, disabled, children }) => {
  const button = <Button disabled={disabled} wide>{children}</Button>
  
  return disabled ? button : <Link to={to}>{button}</Link>
}

export default ButtonLink