import React from 'react'
import styled from 'styled-components'
import vars from 'variables'

const BarWrapper = styled.div`
  overflow: hidden;
  height: 22px;
  width: 100%;
  border: 1px solid ${vars.colors.lightGray};
  margin: 0 0 20px;
`

const Bar = styled.div`
  background: ${vars.colors.gray};
  height: 22px;
  width: ${({ percent }) => percent ? percent : 0}%;
  transition: linear .25s width;
`

const ProgressBar = ({ percent }) => {  
  return (
    <BarWrapper>
      <Bar percent={percent} />
    </BarWrapper>
  )
}

export default ProgressBar