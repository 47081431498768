export default data => {
  const errors = {}
  const { title, year } = data;
  
  if (title && title.length === 0) {
    errors.title = 'Der Titel darf nicht leer sein';
  }
  
  if (year && isNaN(year)) {
    errors.year = 'Das Erscheinungsjahr muss ausschließlich aus Ziffern bestehen';
  }
  
  return errors;
}