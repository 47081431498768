import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { addNotification } from 'actions/notificationActions';
import { activateUser } from 'actions/userActions';
import { Wrapper } from 'components/common/StyledComponents';
import { getErrorMessage } from 'modules/errorHandler';
import { ActionStatus } from 'translations/de';
import CreatePassword from './CreatePassword';

class UserActivation extends Component {
  UNSAFE_componentWillMount() {
    const { match, history, auth } = this.props;

    if (!match.params || !match.params.token || auth.isAuthenticated) {
      history.push('/');
    }
  }

  onSubmit = (data) => {
    const { addNotification, history, action, activateUser } = this.props;
    const { token } = this.props.match.params;
    const strings = ActionStatus[action];
    const { password, passwordConfirmation } = data;

    activateUser(token, { password, passwordConfirmation })
      .then(() => {
        addNotification({
          type: 'success',
          text: strings.success,
        });

        history.push('/');
      })
      .catch((err) => {
        addNotification({
          type: 'error',
          text: getErrorMessage(err) || strings.error,
        });

        if (!(err.response.data && err.response.data.noRedirect)) {
          history.push('/');
        }
      });
  };

  render() {
    const { action } = this.props;
    const strings = ActionStatus[action];

    return (
      <Wrapper>
        <Helmet>
          <title>{strings.title}</title>
        </Helmet>
        <CreatePassword
          modalTitle={strings.title}
          isModal={true}
          showTrigger={false}
          onSubmit={this.onSubmit}
          showTermsConditions={action === 'activation'}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps, { addNotification, activateUser })(UserActivation);
