import { toggle, deleteKeys, addItems, addItem, deleteItems } from 'utils/common';
import * as actions from 'actions/filterActions';

export const initialState = {
  items: {},
  shared: [],
  selected: [],
  halfSelected: [],
  primary: null,
  secondary: []
};

// Add child to an object
const addChild = (state, child) => ({
  ...state,
  children: addItems(state.children, child)
});

// Delete child from an object
const deleteChildren = (state, child) => ({
  ...state,
  children: deleteItems(state.children, child)
});

const updateItems = (state = {}, action) => {
  const { parentId } = action;

  switch (action.type) {
    case actions.ADD_FILTER:
      const { filter } = action;
      return {
        ...state,
        [filter.id]: filter,
        [parentId]: addChild(state[parentId], filter.id)
      };

    case actions.DELETE_FILTERS:
      // Delete the filters themselves
      let newState = deleteKeys(state, action.ids);

      // Delete childIds from the parent
      return {
        ...newState,
        [parentId]: deleteChildren(newState[parentId], action.ids)
      };

    default:
      return state;
  }
};

const checkExistence = (state = {}, keys) => keys.filter(key => state[key] && state[key].id)

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.SAVE_FILTERS:
      return {
        ...state,
        items: action.filters.data,
        shared: action.filters.shared
      };

    case actions.ADD_FILTER:
    case actions.DELETE_FILTERS:
      return {
        ...state,
        items: updateItems(state.items, action)
      };

    case actions.SET_PRIMARY_FILTER:
      if (state.primary === action.id) return state
      
      // Reset state of both secondary and selected filters on primary filter update
      return {
        ...state,
        primary: action.id,
        secondary: initialState.secondary,
        selected: initialState.selected
      }
    
    case actions.SET_SECONDARY_FILTERS:
      return {
        ...state,
        secondary: action.ids
      };

    case actions.SELECT_FILTERS:
      return {
        ...state,
        selected: [...state.selected, ...action.ids]
      };

    case actions.DESELECT_FILTERS:
      return {
        ...state,
        selected: state.selected.filter(i => action.ids.indexOf(i) === -1)
      };
      
    case actions.SELECT_FILTER_RECURSIVELY:
      let { filterId, secondaryFilterId, primaryFilterId } = action;
      
      // If the filter is already selected, no need to do anything
      if (state.selected.indexOf(filterId) > 0) return state;
      
      // Accordion accepts an array of strings
      secondaryFilterId = secondaryFilterId.toString()
      
      if (primaryFilterId !== state.primary) {
        return {
          ...state,
          primary: primaryFilterId,
          secondary: [...initialState.secondary, secondaryFilterId],
          selected: [...initialState.selected, filterId]
        };
      }
    
      return {
        ...state,
        secondary: addItem(state.secondary, secondaryFilterId),
        selected: [...state.selected, filterId]
      };

    case actions.TOGGLE_FILTER:
      return {
        ...state,
        selected: toggle(state.selected, action.id),
        halfSelected: state.halfSelected.filter(i => i !== action.id)
      };

    case actions.RESET_SELECTED_FILTERS:
      return {
        ...state,
        selected: initialState.selected
      }

    case actions.RESTORE_FILTERS_STATE:
      return {
        ...state,
        selected: checkExistence(state.items, action.selected),
        halfSelected: action.halfSelected ? checkExistence(state.items, action.halfSelected) : [],
        secondary: checkExistence(state.items, action.secondary),
        primary: action.primary
      };
    case actions.RESET_FILTERS_STATE:
      return {
        ...state,
        selected: initialState.selected,
        halfSelected: initialState.halfSelected,
        primary: initialState.primary,
        secondary: initialState.secondary
      };

    default:
      return state;
  }
};