import styled from 'styled-components';
import vars, { calcSidebarWidth } from 'variables';

const { columnGutter, headerHeight } = vars.main;
const { sm, md, lg } = vars.media;

export const StyledSidebar = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  /* 2 columns => 2+1 gutters; sidebar should cover exactly 2 tiles => add 2 gutters  */
  width: ${calcSidebarWidth(2, columnGutter.xs)}; 
  height: 100%;
  background: #fff;
  padding: ${headerHeight}px ${columnGutter.xs}px 0;
  overflow-y: auto;
  
  &.sidebar-guest {
    background-color: rgba(255,255,255,.95)
  }
  
  @media (min-width: ${sm}) {
    padding: ${headerHeight}px ${columnGutter.sm}px 0;
    width: ${calcSidebarWidth(3)}; 
  }
  
  @media (min-width: ${md}) {
    width: ${calcSidebarWidth(4)}; 
  }
  
  @media (min-width: ${lg}) {
    width: ${calcSidebarWidth(6)}; 
  }
  
  > .ListItem {
    cursor: pointer;
  }
`;

export const SidebarWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`