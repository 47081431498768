import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, ContentWrapper } from 'components/common/StyledComponents';
import InputField from 'components/common/InputField';
import createModal from 'components/Modal/createModal'; 
import { updateUserEmail } from 'actions/userActions';
import { addNotification } from 'actions/notificationActions';
import { getErrorMessage, getErrorObject } from 'modules/errorHandler';

class EditSingleUser extends Component {
  constructor(props) {
    super();
    
    this.state = {
      isLoading: false,
      errors: {},
      email: props.user.email || ''
    }
  }
  
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }
  
  updateEmail = e => {
    e.preventDefault()
    const { user, updateUserEmail, addNotification, onSuccess } = this.props
    const { email } = this.state
    
    this.setState({ isLoading: true, errors: {} })
    
    updateUserEmail(user.id, { email })
    .then(res => {
      addNotification({
        type: 'success',
        text: 'Deine Email-Adresse wurde erfolgreich aktualisiert'
      })
      
      onSuccess()
    })
    .catch(err => {
      this.setState({ isLoading: false })
      getErrorMessage(err, text => addNotification({ type: 'error', text }))
      getErrorObject(err, errors => this.setState({ errors }))
    })
  }

  render() {
    const { email, isLoading, errors } = this.state
    
    return (
      <form onSubmit={this.updateEmail}>
        <ContentWrapper>
          <InputField
            gray
            error={errors.email}
            onChange={this.onChange}
            value={email}
            label="Neue E-Mail-Adresse eingeben"
            name="email"
            placeholder="E-Mail-Adresse eintragen"
          />
        </ContentWrapper>
        <Button wide disabled={isLoading || email === this.props.user.email}>
          Änderungen speichern
        </Button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
})

const EditSingleUserForm = connect(mapStateToProps, {updateUserEmail, addNotification})(EditSingleUser);

export default createModal(EditSingleUserForm);
