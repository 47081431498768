export const SHOW = 'loading-bar/SHOW'
export const UPDATE = 'loading-bar/UPDATE'
export const HIDE = 'loading-bar/HIDE'

export const showLoading = ({ simulated } = { simulated: true }) => ({
  type: SHOW,
  simulated
})

export const updateLoading = percent => ({
  type: UPDATE,
  percent
})

export const hideLoading = () => ({
  type: HIDE
})

const initialState = {
  loading: false,
  percent: 0,
  simulated: true
}

export const loadingBarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW:
      const newState = {
        ...state,
        percent: 0,
        loading: true
      }
      
      if (typeof action.simulated === "boolean") {
        newState.simulated = action.simulated;
      }
      
      return newState;
    case HIDE:
      return {
        ...state,
        percent: 100,
        loading: false
      }
    case UPDATE:
      return {
        ...state,
        percent: action.percent
      }
    default:
      return state
  }
}
