import { isEmpty } from 'lodash'

export const check = (field, name, ...validations) => {
  return state => {
    for (let v of validations) {
      let getErrorMessage = v(state[field], state);
      if (getErrorMessage) {
        return {[field]: getErrorMessage(name)};
      }
    }
    return null;
  }
}

export const validate = (state, runners) => {
  const errors = runners.reduce((memo, runner) => {
    return Object.assign(memo, runner(state));
  }, {})
  
  return {
    errors,
    isValid: isEmpty(errors)
  }
}

export const getVisibleErrors = (errors = {}, touchedFields = {}) => {
  const visibleErrors = {}
  
  Object.keys(touchedFields).forEach(key => {
    if (errors[key]) {
      visibleErrors[key] = errors[key]
    }
  })
    
  return visibleErrors
}
