import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  display: inline-block;
  vertical-align: middle;
`;

const createSVG = Icon => {
  class SvgIcon extends Component {
    render() {      
      const { height, width, viewBox, className, onClick, fill, stroke, color } = this.props;
      const styles = {
        path: { fill, stroke }
      };
      
      return (
        <StyledSVG
          onClick={onClick}
          className={className}
          width={`${width}px`}
          height={`${height}px`}
          viewBox={viewBox}
        >
          <Icon color={color} style={styles.path} />
        </StyledSVG>
      );
    }
  }

  SvgIcon.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    viewBox: PropTypes.string.isRequired,
    onClick: PropTypes.func
  }

  SvgIcon.defaultProps = {
    width: 16,
    height: 16,
    viewBox: '0 0 26 26'
  }
  
  return SvgIcon;
}

export default createSVG;
