import { createSelector } from 'reselect'
import { uniq, isEmpty, find, values, flatten } from 'lodash';
import { arrayIncludes, arrayIncludesSome, sort } from 'utils/common';

// Getters

const getItems = state => state.library.items
const getPrimaryFilter = state => state.filters.primary
const getSelectedFilters = state => state.filters.selected
const getSecondaryFilters = state => state.filters.secondary
const getFilterItems = state => state.filters.items

// Helpers

const sortKeysByName = (array = [], data = {}) => {
  // Array contains keys
  // Data is an object with actual values
  return array.slice().sort((a, b) => {
    const A = data[a] ? data[a].name : '';
    const B = data[b] ? data[b].name : '';

    return A.localeCompare(B, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
}

const getActiveFilters = (state, filters = []) => (
  filters.length > 0 ? filters : state.filters.selected
)

// Selectors

export const getSortedFilters = createSelector(
  [ getFilterItems ], filters => {
    // Sort the children and usedChildren arrays of filters
    Object.keys(filters).forEach(i => {
      const f = filters[i];
      
      // Sort children alphabetically starting at level 1 filters only
      if (f.level > 0 && f.children) {
        f.children = sortKeysByName(f.children, filters);
      }
      // Sort individual used filters of each filter group
      if (f.usedChildren) {
        Object.keys(f.usedChildren).forEach(k => {
          f.usedChildren[k] = sortKeysByName(f.usedChildren[k], filters);
        })
      }
    })
    return filters;
  }
)

const getDataTypesGroup = createSelector(
  [ getFilterItems ], filters => {
    if (isEmpty(filters)) return undefined;
    
    const dataTypesGroup = find(filters, { 'name': 'Dateiformat' });
    return dataTypesGroup ? filters[dataTypesGroup.id] : undefined;
  }
)

export const getFavoritesGroup = createSelector(
  [ getFilterItems ], filters => {
    if (isEmpty(filters)) return undefined
    
    const favoritesGroup = find(filters, { 'name': 'Favoriten' })
    return favoritesGroup ? filters[favoritesGroup.id] : undefined
  }
)

export const getLiteratureFilterId = createSelector(
  [ getFilterItems ], filters => {
    if (isEmpty(filters)) return undefined;
    
    const filterObj = find(filters, { 'name': 'Literatur' });
    return filterObj ? filterObj.id : undefined;
  }
)

export const getPrimaryFilters = createSelector(
  [ getFilterItems ], filters => Object.keys(filters).filter(key =>
    filters[key].level === 0
  )
)

// Get favorites

export const getFavorites = createSelector(
  [ getFavoritesGroup, getFilterItems ], (favoritesGroup, filters) => {
    if (isEmpty(favoritesGroup)) return {
      items: [],
      ids: []
    }
    
    return {
      items: favoritesGroup.children.map(i => filters[i]),
      ids: favoritesGroup.children
    }
  }
)

// Get location-based literature filters

export const getPossibleLocations = createSelector(
  [ getActiveFilters, getFilterItems ], (active, filters) => {
    if (active.length === 0 || isEmpty(filters) ) return []
    
    const locations = active.map(id => {
      const { location_key, last_location } = filters[filters[id].parent_id]
      if (!location_key) return null
      
      return `${location_key}.${last_location ? Number(last_location) + 1 : 1}`
    })
    
    return sort(uniq(locations.filter(i => i)))
  }
)

// Sort items by last updated date

export const getSortedItems = createSelector(
  [ getItems ], items => {
    if (isEmpty(items)) return items;
    return [...items].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }
)

// Add data type as a filter to each item

const getItemsWithDataType = createSelector(
  [ getSortedItems, getDataTypesGroup ],
  (items, dataTypeGroup) => {
    if (isEmpty(items) || !dataTypeGroup) return items;

    return items.map(oldItem => {
      // Copy the original item
      const item = {...oldItem}

      // Items which belong to the same library where data formats group is defined
      // -> can be filtered by type
      if (item.primaryFilterId === dataTypeGroup.parent_id) {
        item.filters = [...item.filters, item.type]
        item.secondaryFilters = [...item.secondaryFilters, String(dataTypeGroup.id)]
      }
      
      return item
    });
  }
)

// Sort selected items by group

const getSelectedFiltersPerGroup = createSelector(
  [ getFilterItems, getSelectedFilters ],
  (filters, selected) => {
    if (selected.length === 0 || isEmpty(filters)) return {}
    
    // Sort selected filters by filter group
    const selectedPerGroup = {}
    selected.forEach(i => {
      const parent = filters[i].parent_id
      selectedPerGroup[parent] = selectedPerGroup[parent] || []
      selectedPerGroup[parent].push(i)
    })
    
    return selectedPerGroup
  }
)

// Get items with selected filters

export const getFilteredItems = createSelector(
  [ getItemsWithDataType, getPrimaryFilter, getSecondaryFilters, getSelectedFiltersPerGroup ],
  (items, primary, secondary, selectedPerGroup) => {
    // Proceed only if there're selected filters or an accordion is open
    if (isEmpty(selectedPerGroup) && !primary) return items;

    // Compares each item against current primary and secondary filters
    return items.filter(item => {
      if (item.primaryFilterId !== primary) return false;
      
      // Check if current item is attached to each of the currently selected filter groups 
      if (secondary && !arrayIncludes(secondary, item.secondaryFilters)) return false
          
      if (!isEmpty(selectedPerGroup)) {
        return Object.keys(selectedPerGroup).every(f =>
          // Item has to have at least one filter in each of currently selected groups
          arrayIncludesSome(selectedPerGroup[f], item.filters)
        )
      }
      
      return true;
    });
  }
)

// Get filters that are somehow connected to currently active items

export const getPossibleFilters = createSelector(
  [ getFilteredItems, getSortedFilters, getSelectedFilters, getSelectedFiltersPerGroup, getPrimaryFilters, getDataTypesGroup ],
  (libraryItems, filters, selected, selectedPerGroup, primaryFilters, dataTypesGroup) => {

    // Proceed only if at least one filter is selected
    if (selected.length === 0) return filters;
    
    // Get data types of current items
    const dataTypes = uniq(libraryItems.map(item => item.type).filter(i => i))
    
    // Initiate the array by including the data types
    let possibleFilterIds = [dataTypesGroup.id, ...dataTypes]
    
    // Add filters attached to the current item and their respective groups
    libraryItems.forEach(item => {
      possibleFilterIds.push(...[...item.filters, ...item.secondaryFilters])
    })
    
    // Add filter groups of selected filters and all filters they may have
    Object.keys(selectedPerGroup).forEach(id => {
      possibleFilterIds.push(...[id, ...filters[id].children])
    })
    
    possibleFilterIds = uniq([...primaryFilters, ...possibleFilterIds])
    let suggestedFilters = {}
        
    // Map ids to the actual filters
    possibleFilterIds.forEach(id => {
      suggestedFilters[id] = filters[id]
    })
    
    return suggestedFilters;
  }
)

// Get used individual filters as an array

export const getArrayOfUsedFiltersPerSection = createSelector(
  [ getPossibleFilters, getPrimaryFilters ],
  (possibleFilters, primaryFilters) => {
    if (isEmpty(possibleFilters)) return [];
    
    const usedFiltersArray = primaryFilters.map(id => {
      // Get the primary filter by id
      const f = possibleFilters[id]
      
      // Get the ids of used individual filters of this primary filter
      const childrenIds = isEmpty(f) ? [] : flatten(values(f.usedChildren))
      
      // Replace ids with actual filter data and add the primary filter id to the object
      const children = childrenIds.map(c => ({
        ...possibleFilters[c],
        grandparent_id: f.id
      }))

      return {
        id,
        name: f.name,
        children
      }
    })

    return usedFiltersArray;
  }  
)