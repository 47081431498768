import React from 'react';
import styled from "styled-components";
import cx from 'classnames'
import { Link } from 'react-router-dom';

import { InfoIcon } from "components/icons";
import vars from 'variables'

const InfoLinkWrapper = styled.div`
  display: inline-block;
  padding-left: 3px;
  
  svg {
    margin-top: -0.125em;
  }
  
  &.info-link-small {
    padding-left: 7px;
    svg {
      vertical-align: bottom;
    }
  }
`;

const InfoLink = ({ to, className }) => {
  return (
    <InfoLinkWrapper className={cx('info-link', className)}>
      <Link to={to}>
        <InfoIcon color={vars.colors.green} width={14} height={14} />
      </Link>
    </InfoLinkWrapper>
  );
}

export default InfoLink