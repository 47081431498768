import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'utils/media';
import vars, { calcSidebarWidth, calcTileWidth } from 'variables';
import { Column, Row } from '../../external/hedron/src/index';

const { sm, md, mdMax, lg, xl } = vars.media;
const { gutter, columnGutter, headerHeight } = vars.main;
const { green, gray, lightGray, danger } = vars.colors;

const Field = styled.div`
  border: 1px solid ${vars.colors.lightGray};
  background: ${(props) => (props.white ? '#fff' : 'none')};
  width: 100%;
  padding: 3px 20px;
  font-size: 0.85rem;
  line-height: 1.3;
  outline: none;
  word-break: break-word;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ fixed, fixedWidth }) => (fixed && fixedWidth ? fixedWidth : '100%')};
  padding: ${(props) => (props.fluid ? 0 : `0 ${gutter}px`)};
  margin-top: ${(props) => (props.fluid ? 0 : `1em`)};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) => (props.fluid ? 0 : `4em`)};

  @media (min-width: ${md}) {
    padding: ${(props) => (props.fluid ? 0 : `0 ${gutter * 2}px`)};
  }
`;

Wrapper.defaultProps = {
  fixedWidth: '600px',
};

const SectionTitle = styled.div`
  font-size: 1.125rem;
  text-align: center;
  text-transform: uppercase;
  margin: 3em 0;
`;

const ContentWrapper = styled.div`
  margin: ${({ isSubtitle }) => (isSubtitle ? '1.5em 0 0' : '3em 0 3.5em')};
  font-size: 0.9rem;
`;

const UnstyledButton = styled.button`
  background: none;
  border: 0;
  outline: 0;
  box-shadow: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

// Form Components

const Button = styled.button`
  font-weight: 400;
  font-family: inherit;
  font-size: 0.85rem;
  border: ${(props) => (props.white ? `1px solid ${lightGray}` : '0')};
  border-radius: 0;
  cursor: pointer;
  outline: none;
  color: ${(props) => (props.white ? gray : '#fff')};
  text-align: center;
  background: ${(props) => (props.white ? '#fff' : green)};
  padding: ${(props) => (props.white ? '3px 5em' : '4px .5em')};
  line-height: 1.3;
  width: ${(props) => (props.wide ? '100%' : 'auto')};
  margin: 0;
  user-select: none;

  & + & {
    margin-top: ${gutter}px;
  }

  &:hover {
    background-color: ${(props) => (props.white ? '#fafafa' : 'hsl(150, 52%, 67%)')};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${(props) => (props.white ? '#fff' : 'rgb(149,231,201)')};
  }
`;

const ButtonLink = styled(Link)`
  font-weight: 400;
  font-family: inherit;
  font-size: 0.85rem;
  cursor: pointer;
  outline: none;
  color: #fff;
  text-align: center;
  background: ${green};
  padding: 4px 0.5em;
  line-height: 1.3;
  width: 100%;
  margin-top: 1em;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: hsl(150, 52%, 67%);
  }
`;

const ControlsRow = styled(Row)`
  margin-top: 1rem;
  &.inline {
    margin-top: 0;
  }
  @media (min-width: ${sm}) {
    > div:first-child {
      padding-right: ${gutter}px;
    }
    > div:last-child {
      padding-left: ${gutter}px;
    }
  }
  > div:first-child:last-child {
    padding: 0;
    width: 100%;
  }
  button {
    margin-bottom: 0.75rem;
  }
`;

const ControlCol = styled(Column)``;

ControlCol.defaultProps = {
  sm: 6,
  fluid: true,
};

const baseMargin = 0.75;

const FormGroup = styled.div`
  margin-bottom: ${({ margin = 1 }) => margin * baseMargin}rem;

  label,
  .label {
    font-weight: 500;
    font-size: 0.85rem;
    display: block;
    margin-bottom: 0.5rem;
    margin-left: 21px;
  }
  &.form-group--textarea,
  &.form-group--select {
    margin-bottom: 2rem;
  }
  &.form-group--stack:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
  &.form-group--info label {
    font-weight: 400;
    &.semibold {
      font-weight: 500;
    }
  }
  &.form-group--mt {
    margin-top: 2.25rem;
  }
  &.has-danger {
    .form-feedback {
      color: ${danger};
    }
    input,
    label,
    label::after {
      border-color: ${danger};
    }
  }
  .form-feedback {
    font-size: 0.85rem;
    line-height: 1.25;
    margin-top: 5px;
    display: inline-block;
  }
`;

// Grid

const Grid = styled(Row)`
  padding: ${columnGutter.xs / 2}px;

  @media (min-width: ${sm}) {
    padding: ${columnGutter.sm / 2}px;
  }

  &.has-one-item {
    align-items: center;
    justify-content: center;
    height: calc(100% - 34px);
    width: 50%;
    position: fixed;

    .tile {
      width: 65%;
    }
  }
`;

const ViewWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  &.is-shifted {
    margin-right: ${calcSidebarWidth(2, columnGutter.xs)};

    @media (min-width: ${sm}) {
      margin-right: ${calcSidebarWidth(3)};
    }

    @media (min-width: ${md}) {
      margin-right: ${calcSidebarWidth(4)};
    }

    @media (min-width: ${lg}) {
      margin-right: ${calcSidebarWidth(6)};
    }
  }
`;

const Tile = styled(Column)`
  overflow: hidden;
  position: relative;
  align-items: center;
  background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20200%20200%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%3Cg%20stroke%3D%22%237D7D7D%22%20stroke-width%3D%222.5%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M128.5%20142.04c-38.4%200-69.53-31.13-69.53-69.52C58.97%2034.12%2090.1%203%20128.5%203%20166.87%203%20198%2034.12%20198%2072.52c0%2038.4-31.13%2069.52-69.5%2069.52zM79%20121.32L1%20198.85l78-77.53z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M173.54%2072.93H82.6m45.47-45.47v90.95%22%20stroke-linejoin%3D%22round%22/%3E%0A%20%20%3C/g%3E%0A%3C/svg%3E);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  cursor: pointer;
  width: 100%;
  z-index: 10;

  &:hover,
  &.is-selected {
    .tile-content {
      max-height: 100%;
    }
  }

  &.has-thumbnail {
    background-image: none;
  }

  &.tile {
    margin: ${columnGutter.xs / 2}px;

    ${(props) => calcTileWidth(props.xs, columnGutter.xs)}

    ${media.sm`
      margin: ${columnGutter.sm / 2}px;

      ${(props) => calcTileWidth(props.sm)}
    `}
    ${media.md`
      ${(props) => calcTileWidth(props.md)}
    `}
    ${media.lg`
      ${(props) => calcTileWidth(props.lg)}
    `}
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    flex-shrink: 0;
    min-width: 100%;
    max-height: 100%;
  }

  .tile-content {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    color: #fff;
    background-color: rgba(110, 210, 160, 0.7);
    transition: max-height 0.4s ease-in-out;
    max-height: 0;
    overflow: hidden;
    font-size: 0.7rem;

    @media (min-width: ${sm}) {
      font-size: 0.85rem;
    }

    .tile-title {
      line-height: 1.25;
      word-wrap: break-word;
      user-select: none;
      padding: ${columnGutter.xs}px;

      @media (min-width: ${sm}) {
        padding: ${columnGutter.sm}px;
      }
    }
  }

  &.is-selected {
    .tile-overlay {
      box-shadow: inset 0px 0px 0px 2px ${green};
    }
    .tile-content {
      background-color: rgba(110, 210, 160, 0.75);
    }
  }
`;

// SplitPanes

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: calc(100vh - ${headerHeight}px);
`;

const SplitPane = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;

  @media (min-width: ${md}) {
    width: 50%;
  }

  &.library-grid-wrapper {
    display: none;
    @media (min-width: ${md}) {
      display: block;
    }
  }

  &:last-child {
    background: #fff;
  }
`;

const SplitPaneWrapper = styled.div`
  margin: 0 auto 3em;
  width: 95%;
  overflow: auto;

  @media (min-width: ${sm}) {
    width: 85%;
  }

  @media (min-width: ${md}) {
    width: 80%;
  }

  @media (min-width: ${xl}) {
    width: 70%;
  }
`;

const StepNavBar = styled.nav`
  text-align: center;
  margin: 5em 0 4em;
  text-transform: uppercase;
`;

const StepNavItem = styled.div`
  font-weight: 400;
  display: block;
  color: ${lightGray};
  cursor: pointer;
  font-size: 1rem;
  span {
    position: relative;
  }
  @media (min-width: ${sm}) {
    display: inline-block;
  }
  @media (min-width: ${xl}) {
    font-size: 1.1rem;
  }
  & + &:before {
    @media (min-width: ${sm}) {
      content: '|';
      color: ${lightGray};
      margin: 0 5px;
    }
    @media (min-width: ${md}) {
      margin: 0 15px;
    }
  }
  &.step-nav-wide + &:before {
    @media (min-width: ${sm}) {
      margin: 0 5px;
    }
  }
  &.step-navitem--splitpane {
    @media (min-width: ${md}) and (max-width: ${mdMax}) {
      display: block;
      font-size: 1rem;
      & + &:before {
        display: none;
      }
    }
  }
  &:first-child:last-child,
  &.step-active {
    cursor: initial;
    color: ${gray};
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  &:not(.step-active) .info-link {
    visibility: hidden;
  }
`;

// Searchable View

const SearchableContainer = styled.div`
  margin: 30px 0;

  .section-header {
    font-weight: 500;
    font-size: 0.85rem;
    user-select: none;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .rc-collapse-item-active > .rc-collapse-header > div:after {
    right: 4px;
  }
`;

const SectionChildren = styled.ul`
  font-weight: 400;
  margin: 10px 0 0;
  padding: 0;
  user-select: initial;
  list-style-type: lower-alpha;
  &.with-border {
    border: 1px solid ${lightGray};
    padding: 5px;
  }
  > li {
    margin: 0 0 10px 15px;
    &:last-child {
      margin-bottom: 0;
    }
    &.with-border {
      margin-left: 0;
      list-style: none;
      border: 1px solid ${lightGray};
      padding: 3px 5px;
      position: relative;
      &:before {
        position: absolute;
        content: attr(data-key) '.';
      }
      > div {
        margin-left: 18px;
      }
    }
    .rc-collapse-content-active > .rc-collapse-content-box {
      padding-top: 10px;
    }
    .rc-collapse-item {
      padding-right: 15px;
    }
    .rc-collapse-header:after {
      content: '';
      position: absolute;
      height: 9px;
      width: 9px;
      top: 7px;
      right: 6px;
      background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20stroke%3D%22%237D7D7D%22%20stroke-width%3D%22.87%22%20d%3D%22M15%201L8%2015M1%201l7%2014%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%0A%3C/svg%3E);
      background-repeat: no-repeat;
      transform: rotate(-90deg);
    }
    .rc-collapse-item-active > .rc-collapse-header:after {
      transform: none;
    }
  }
  div {
    ul {
      counter-reset: custom;
      list-style-type: none;
      margin: 5px 0;
      padding: 0;
      > li {
        position: relative;
        margin: 0 0 0 20px;
        &:before {
          content: '(' counter(custom) ')';
          counter-increment: custom;
          position: absolute;
          left: -20px;
        }
      }
      &.bulletList {
        margin: 0 0 0 15px;
        > li {
          &:before {
            content: '-';
            left: -15px;
          }
        }
      }
    }
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    padding: 20px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const LibraryHint = styled.div`
  width: 100%;
  position: relative;

  p {
    margin: 4em auto 3em;
    text-transform: uppercase;
    text-align: center;
    @media (min-width: ${media.sm}) {
      font-size: 1.125rem;
    }
  }
`;

export const HalfColumn = styled.div`
  padding: 0;
  width: 100%;

  @media (min-width: ${sm}) {
    width: 50%;
  }
`;

export {
  Button,
  ButtonLink,
  ContentWrapper,
  ControlCol,
  ControlsRow,
  Field,
  FormGroup,
  Grid,
  SearchableContainer,
  SectionChildren,
  SectionTitle,
  SplitPane,
  SplitPaneWrapper,
  SplitView,
  StepNavBar,
  StepNavItem,
  Tile,
  UnstyledButton,
  ViewWrapper,
  Wrapper,
  calcSidebarWidth,
};
