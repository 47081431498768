export const ADD_NOTIFICATION = 'ADD_NOTIFICATION/notifications';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION/notifications';

let nextNotificationId = 0;

export const deleteNotification = id => ({
  type: DELETE_NOTIFICATION,
  id
})

export const addNotification = message => (
  dispatch => {
    const id = nextNotificationId++;
    
    dispatch({
      type: ADD_NOTIFICATION,
      message,
      id
    });
    
    if (message.modal) return;
    
    // Hide non-modal notifications automatically after 5 seconds
    setTimeout(() => {
      dispatch(deleteNotification(id))
    }, 5000)
  }
)