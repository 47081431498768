const getFirstPage = (pdf) => {
  return pdf.getPage(1).then(async (page) => {
    // Default scale
    const scale = 1.0;
    const viewport = page.getViewport({ scale });

    // Prepare canvas using PDF page dimensions
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const canvasContext = canvas.getContext('2d');

    // Render PDF page into canvas context
    await page.render({ canvasContext, viewport }).promise;

    return canvas.toDataURL('image/jpeg');
  });
};

const pdfToImage = (file, cb) => {
  // file is an File object
  const reader = new FileReader();
  reader.onload = async () => {
    try {
      const PDF = await import('pdfjs-dist');
      const workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
      PDF.GlobalWorkerOptions.workerSrc = workerSrc;
      const pdf = await PDF.getDocument(new Uint8Array(reader.result)).promise;
      const canvas = await getFirstPage(pdf);

      cb(canvas);
    } catch (err) {
      console.error(err);
    }
  };
  reader.readAsArrayBuffer(file);
};

export default async (file) => {
  return new Promise((resolve, reject) =>
    pdfToImage(file, (image) => {
      if (!image) return reject(new Error('The resulting image is empty'));
      return resolve(image);
    })
  );
};
